<template>
  <div>
    <div class="container box">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch("soep/index");
  },
};
</script>

<style scoped>
.centered-box {
  min-width: 800px;
}
</style>
