<template>
  <div v-if="closedModalActive">
    <div class="modal is-active">
      <div class="modal-background" @click="closeModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Gesloten</p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal()"
          ></button>
        </header>
        <section class="modal-card-body">
          <span class="content" style="color: black" v-html="closed_message">
          </span>
          <!-- Content ... -->
        </section>
        <footer class="modal-card-foot">
          <button class="button is-rounded is-primary" @click="closeModal()">
            Bericht sluiten
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      closedmessage:
        "Helaas is het op dit moment niet mogelijk om nieuwe bestellingen te plaatsen",
    };
  },
  computed: {
    closedModalActive() {
      const open = this.findSetting("website_open");
      if (open && open == 1) {
        return false;
      } else {
        return true;
      }
    },
    closed_message() {
      if (
        this.settings &&
        this.settings.variables &&
        this.settings.variables.length
      ) {
        const closedmessage = this.settings.variables.find(
          (a) => a.key === "website_closed_message"
        );
        if (closedmessage) {
          return closedmessage.value;
        } else {
          return this.closedmessage;
        }
      } else {
        return this.closedmessage;
      }
    },
  },
  methods: {
    closeModal() {
      const old_var = this.settings.variables.find(
        (a) => a.key === "website_open"
      );
      if (old_var) {
        old_var.value = 1;
      }
    },
  },
  mounted() {},
};
</script>
