<template>
  <div>
    <div class="title is-5">Foto uploaden</div>
    <section>
      <div class="columns">
        <div class="column is-8">
          <b-field>
            <b-upload
              v-model="droppedFiles"
              @input="filesChanged()"
              multiple
              drag-drop
              expanded
              native
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"> </b-icon>
                  </p>
                  <p>
                    Sleep bestanden hierin of klik om bestanden up te loaden
                  </p>
                </div>
              </section>
            </b-upload>
          </b-field>
        </div>
        <div class="column">
          <button class="button is-rounded" @click="uploadPictures()">
            Uploaden
          </button>
        </div>
      </div>

      <span v-for="(file, index) in previewImages" :key="index">
        <div class="box">
          <button
            class="delete is-small is-pulled-right"
            type="button"
            @click="deleteDroppedFile(index)"
          ></button>
          <b-field label="Name">
            <b-input v-model="file.name"></b-input>
          </b-field>

          <br />
          <span v-if="file.url">
            <!-- <div class="columns"> -->
            <!-- <div class="column is-6"> -->
            <img v-if="file.url" :src="file.url" />

            <br />
            <!-- </div> -->
            <!-- <div class="column"> -->

            <!-- </div> -->
            <!-- </div> -->
          </span>
        </div>
        <br />
      </span>
    </section>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: [],
  data() {
    return {
      droppedFiles: [],
      previewImages: []
    };
  },
  computed: {},
  methods: {
    uploadPictures() {
      this.droppedFiles.forEach(
        function(file, index) {
          let formdata = new FormData();
          formdata.append("image", file, file.name);
          this.$store.dispatch("uploadPicture", formdata).then(() => {
            this.droppedFiles.splice(index, 1);
            this.previewImages.splice(index, 1);
          });
        }.bind(this)
      );
    },
    filesChanged() {
      this.previewImages = [];
      this.droppedFiles.forEach(
        function(file, index) {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            () => {
              let filepreview = {
                name: file.name,
                url: reader.result
              };
              this.previewImages.push(filepreview);
            },
            false
          );
          reader.readAsDataURL(file);
        }.bind(this)
      );
    },
    deleteDroppedFile(index) {
      const array = this.droppedFiles;
      array.splice(index, 1);

      this.previewImages.splice(index, 1);
    }
  },

  mounted() {}
};
</script>
