<template>
  <b-button
    native-type="submit"
    :class="[
      'button is-rounded ' + size + ' ' + type,
      { 'is-loading': ajaxloading[loadingKey] },
    ]"
  >
    {{ title }}
  </b-button>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    loadingKey: {
      type: String,
      required: true,
    },
    size: {
      default: "is-normal",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    type: {
      default: "",
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
  },
  methods: {},
  mounted() {},
};
</script>
