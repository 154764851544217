<style scoped>
.customTitle {
  font-weight: bold;
}

.customBox {
  border-style: solid;
}
</style>

<template>
  <div>
    <quote quote="Een plek waar je mag worden wie je bent"></quote>
    <br />
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Dagprogramma</div>
          Jezelf zijn, ontdekken waar je blij van wordt, je talenten ontwikkelen
          en ontdekken wat jij nu écht wilt. Daar focussen wij ons op bij het
          dagprogramma. Zijn dat dingen die jij je ook vaak afvraagt? Daar ben
          je niet alleen in! Het zijn de vragen die we het meest horen van de
          jongeren die zich aanmelden bij ons dagprogramma. Doormiddel van
          zingeving, (zelf)onderzoek en het uitproberen van vele verschillende
          activiteiten leer je jezelf, je talenten en dat wat je blij maakt
          -opnieuw- kennen. Het dagprogramma bestaat hoofdzakelijk uit
          groepsactiviteiten. Wij geloven dat het contact met leeftijdsgenoten
          erg belangrijk is om jezelf te ontwikkelen. We begeleiden jongeren in
          het maken van een volgende stap, naar bijvoorbeeld school of werk. Op
          maandag tot en met vrijdag is de groepsruimte open tussen 08.15 en
          16.15. We starten de dag om 08.45 met elkaar en sluiten af om 15.45.
          We zijn buiten de feestdagen en de laatste week van het jaar, het hele
          jaar geopend.
          <br />
          <br />

          <div class="customTitle">Werkplaatsen</div>
          Wij geloven in de kracht van ervaringsleren, in de verbinding tussen
          je hoofd, hart en handen. Wekelijks zijn er verschillende
          <a @click="$router.push({ name: 'werkplaatsen' })">werkplaatsen</a> en
          onderdelen die terugkerend op het programma staan. Het aanbod
          verandert geregeld, zodat we zo goed mogelijk aansluiten bij de groep
          jongeren die er aanwezig is.

          <br />
          <br />
          <div class="customTitle">Voor wie is het?</div>
          Ben je tussen de 14 en 27 jaar? Wil je jezelf beter leren kennen? Of
          ontdekken welke studie of werk bij jouw talenten en interesses past?
          Dan is ons ontwikkelprogramma misschien wel wat voor jou! Bij ons
          krijg je de kans om jezelf beter te leren kennen, te ontdekken waar
          jij blij van wordt, waar je goed in bent, maar ook hoe je omgaat met
          sociale vraagstukken of gebrek aan motivatie. Door mee te doen in de
          verschillende werkplaatsen van Het Dagelijks Bestaan kun je direct
          oefenen in de praktijk.
        <br/>
        <br/>
         Veel jonge mensen hebben de wens om weer (terug) naar school te gaan. Door 
          het vaste programma dat wij aanbieden en de diverse werkplaatsen krijg je
          al veel schoolse vaardigheden mee. Je leert weer in een groep leeftijdsgenoten
          bewegen, op tijd hulp te vragen en we werken aan een gezond dag- en nachtritme.
          Verder kun je in ons leerhuis  aan schoolvakken werken en is het mogelijk een
          MBO entreebewijs te behalen. Samen kijken we welke manier van leren er bij jou past en hoe we de stap richting school  
        kunnen maken.
        </div>
      </div>
      <div class="column">
        <carrousel :page="'dagprogramma'" />
      </div>
    </div>
    <div class="box">
      <div class="customTitle">Hoe ziet het traject er uit?</div>
      Wanneer je net nieuw bent op Het Dagelijks Bestaan komt er veel op je af.
      Nieuwe mensen en een nieuwe plek, dat is best even wennen! Dit duurt vaak
      een paar weken. Die periode noemen wij de landing. In die tijd krijg je de
      ruimte om kennis te maken met de mensen en onze gewoontes. Het
      belangrijkste is dat jij er bent. In deze periode werk je aan het krijgen
      van een gezond ritme, het vinden van rust en het ontdekken van de vragen
      waar jij mee werken wilt. Zodra je je meer thuis voelt, zeggen wij vaak:
      je bent geland.
      <br />
      <br />
      Wanneer je geland bent, begint de volgende fase: de verbinding. Je gaat de
      verbinding aan met je groepsgenoten, de begeleiding en het belangrijkste,
      met jezelf. In deze periode staan de volgende vragen centraal: wat wil ik?
      Wat kan ik? Waar word ik blij van? In die periode wordt je liefdevol
      uitgedaagd om stappen te maken, dingen aan de rand van je comfortzone te
      doen en te ontdekken. Je werkt actief mee in de verschillende werkplaatsen
      van Het Dagelijks Bestaan.
      <br />
      <br />
      De laatste fase binnen Het Dagelijks Bestaan is het ontwikkelen van je
      toekomstperspectief. Je blik wordt meer op buiten gericht: wat voor werk
      wil ik doen? Wil ik (weer) naar school toe? Welke studierichting vind ik
      leuk? In deze periode maak je je langzaam los van Het Dagelijks Bestaan en
      bereid je je voor op de stap richting de maatschappij. Je neemt en krijgt
      meer eigen verantwoordelijkheden in de werkplaatsen of gaat misschien wel
      ergens stage lopen.
      <br />
      <br />
      Ben je nieuwsgierig geworden? Lees hier meer over onze
      <a @click="$router.push({ name: 'visie' })">visie</a> en
      <a @click="$router.push({ name: 'geschiedenis' })">geschiedenis</a>.
    </div>

    <br />

    <div class="box customBox">
      <div class="customTitle">Hoe kan ik mij aanmelden?</div>
      Je kunt ons bellen of mailen voor een kennismakingsgesprek. In het
      kennismakingsgesprek kijken we samen of Het Dagelijks Bestaan de passende
      begeleiding kan bieden voor jouw hulpvraag. Na het kennismakingsgesprek
      loop je een dag mee. Na de meeloopdag kan er een intakegesprek worden
      gepland. Tijdens het intakegesprek maken we afspraken over wanneer je
      begint en hoe je programma er uitziet. Een doorverwijzing kun je aanvragen bij
     je eigen gemeente.
      <br />
      <div class="has-text-centered">
        <button
          class="button is-rounded is-primary"
          @click="$router.push({ name: 'contact' })"
        >
          Contact opnemen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import quote from "@/views/Components/Quote.vue";

export default {
  components: {
    quote: quote
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
