export const getUsers = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get_users";
  data.method = "GET";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setUsers", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const gebruikerToevoegen = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create_user";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setNewUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const resendInvitation = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "resend_invitation/" + payload.id;
  data.method = "POST";
  data.loading = "send_invitation";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deactivateUser = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "deactivate_user/" + payload.id;
  data.method = "POST";
  data.loading = "deactivate_user";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const activateUser = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "activate_user/" + payload.id;
  data.method = "POST";
  data.loading = "activate_user";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const gebruikerVerwijderen = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "delete_user/" + payload.id;
  data.method = "POST";
  data.loading = "delete_user";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("deleteUser", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const refreshUser = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "refresh_user/" + payload.id;
  data.method = "POST";
  data.loading = "refresh_user";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const gebruikerAanpassen = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "edit_user/" + payload.id;
  data.method = "POST";
  data.loading = "edit_user";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
