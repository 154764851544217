<style scoped>

/* 
.demo-wrap {
  position: relative;
}

.demo-wrap:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.demo-content {
  position: relative;
} */
</style>

<template>
  <div class="demo-wrap">
    <div class="has-text-centered ">
      <img src="@/assets/logo/HDB-Logo.png" width="250" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
