<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Horeca</div>
          Bij Het Dagelijks Bestaan zijn we gek op eten. Iedere dag verzorgen we
          zelf een verse lunch. Een aantal keren per week gebeurt dit onder
          begeleiding van een professionele kok. Hij leert je over lokale
          gewassen en seizoensgebonden producten.
          <br />
          Het maken van allerlei zoets doen we ook graag; taarten, cakes en
          koekjes! Niet alléén voor onszelf, maar ook om anderen op te
          trakteren, als bedankje of bijvoorbeeld geld voor een goed doel op te
          halen.
        </div>
      </div>
      <div class="column">
        <carrousel :page="'werkplaatsen_horeca'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
