import Vue from "vue";
import { DateTime } from "luxon";

const formatDatabaseDate = Vue.mixin({
  methods: {
    formatDatabaseDate(payload) {
      if (!payload) {
        return;
      }
      const date = DateTime.fromISO(payload);

      return date
        .setLocale("nl")
        .setZone("Europe/Amsterdam")
        .toLocaleString(DateTime.DATE_FULL);
    },
    formatDatabaseDateShort(payload) {
      if (!payload) {
        return;
      }
      const date = DateTime.fromISO(payload);

      return date
        .setLocale("nl")
        .setZone("Europe/Amsterdam")
        .toLocaleString(DateTime.DATE_SHORT);
    },
    formatDatabaseDateHuman(payload) {
      if (!payload) {
        return;
      }

      const date = DateTime.fromISO(payload);
      const dateNow = DateTime.now();
      if (
        dateNow.c.day === date.c.day &&
        dateNow.c.month === date.c.month &&
        dateNow.c.year === date.c.year
      ) {
        return "Vandaag";
      }

      if (
        dateNow.c.day - 1 === date.c.day &&
        dateNow.c.month === date.c.month &&
        dateNow.c.year === date.c.year
      ) {
        return "Gisteren";
      }

      return date.setLocale("nl").setZone("Europe/Amsterdam").toFormat("DDDD");
    },

    formatDatabaseTime(payload) {
      if (!payload) {
        return;
      }

      const date = DateTime.fromISO(payload).setZone("UTC");

      return date
        .setLocale("nl")
        .setZone("Europe/Amsterdam")
        .toLocaleString(DateTime.TIME_24_SIMPLE);
    },
    formatDatePickerDate(payload) {
      if (!payload) {
        return;
      }
      const date = DateTime.fromISO(payload.replace(" ", "T"), {
        zone: "utc",
      });
      return date
        .setLocale("nl")
        .setZone("Europe/Amsterdam")
        .toLocaleString(DateTime.DATE_FULL);
    },
    formatDatePickerEditDateFromDataBase(payload) {
      if (!payload) {
        return;
      }
      const date = DateTime.fromISO(payload.replace(" ", "T"), {
        zone: "utc",
      });

      date.setLocale("nl");
      date.setZone("Europe/Amsterdam");

      return date
        .setLocale("nl")
        .setZone("Europe/Amsterdam")
        .toFormat("dd-MM-yyyy");

      // const yyyy = date.getFullYear();
      // const mm = date.getMonth() + 1; // Months start at 0!
      // const dd = date.getDate();

      // return dd + "-" + mm + "-" + yyyy;

      // return date
      //   .setLocale("nl")
      //   .setZone("Europe/Amsterdam")
      //   .toLocaleString(DateTime.DATE_FULL);
    },
    formatTimePickerEditDateFromDataBase(payload) {
      if (!payload) {
        return;
      }
      const date = DateTime.fromISO(payload.replace(" ", "T"), {
        zone: "utc",
      });

      date.setLocale("nl");
      date.setZone("Europe/Amsterdam");

      return date.setLocale("nl").setZone("Europe/Amsterdam").toFormat("HH:mm");
    },

    formatDatePickerDateAndTime(payload) {
      if (!payload) {
        return;
      }
      if (payload) {
        const date = DateTime.fromISO(payload.replace(" ", "T"), {
          zone: "utc",
        });
        return date
          .setLocale("nl")
          .setZone("Europe/Amsterdam")
          .toLocaleString(DateTime.DATETIME_FULL)
          .replace("CEST", "")
          .replace("CET", "");
      } else {
        return payload;
      }
    },
    formatDataBaseDateToJSDate(payload) {
      if (payload) {
        if (typeof payload === "string") {
          const date = DateTime.fromISO(payload.replace(" ", "T"), {
            zone: "utc",
          });
          return date.setLocale("nl").setZone("Europe/Amsterdam").toJSDate();
        }
      }
    },
  },
});

export default formatDatabaseDate;
