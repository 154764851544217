<style scoped>
.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="block table-icons">
      <b-tooltip
        @click.native="toevoegen()"
        label="Besteldag toevoegen"
        position="is-top"
      >
        <span class="pointer table-icon">
          <b-icon icon="plus"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip
        @click.native="aanpassen()"
        v-if="selected"
        label="Besteldag aanpassen"
        position="is-top"
      >
        <span class="pointer table-icon">
          <b-icon icon="edit"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip
        @click.native="openToggle('close')"
        v-if="selected && selected.inschrijving_geopend"
        label="Besteldag sluiten"
        position="is-top"
      >
        <span class="pointer table-icon">
          <b-icon icon="store-slash"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip
        @click.native="openToggle('open')"
        v-if="selected && !selected.inschrijving_geopend"
        label="Besteldag openen"
        position="is-top"
      >
        <span class="pointer table-icon">
          <b-icon icon="store"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip
        @click.native="zichtbaarToggle('visible')"
        v-if="selected && !selected.zichtbaar"
        label="Besteldag zichtbaar maken"
        position="is-top"
      >
        <span class="pointer table-icon">
          <b-icon icon="eye"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip
        @click.native="zichtbaarToggle('invisible')"
        v-if="selected && selected.zichtbaar"
        label="Besteldag onzichtbaar maken"
        position="is-top"
      >
        <span class="pointer table-icon">
          <b-icon icon="eye-slash"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip
        @click.native="verwijderen()"
        v-if="selected && selected.bestellingen < 1"
        label="Besteldag verwijderen"
        position="is-top"
      >
        <span class="pointer table-icon">
          <b-icon icon="trash"></b-icon>
        </span>
      </b-tooltip>
    </div>
    <br />
    <b-table
      :data="index"
      :bordered="true"
      :striped="true"
      :hoverable="true"
      :loading="ajaxloading.get_index"
      :focusable="false"
      :mobile-cards="false"
      :selected.sync="selected"
      @dblclick="aanpassen()"
      detailed
      detail-key="id"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column field="id" label="Aangemaakt door" v-slot="props">
        {{ props.row.user.name }}
      </b-table-column>

      <b-table-column field="name" label="Bestellingsdag" v-slot="props">
        {{ formatDatePickerDate(props.row.bestellingsdag) }}
      </b-table-column>

      <b-table-column field="name" label="Inschrijving geopend" v-slot="props">
        <span v-if="props.row.inschrijving_geopend">
          <b-icon style="color: green" icon="check" size="is-small"> </b-icon>
        </span>
        <span v-if="!props.row.inschrijving_geopend">
          <b-icon style="color: red" icon="times" size="is-small"> </b-icon>
        </span>
      </b-table-column>

      <b-table-column field="name" label="Zichtbaar" v-slot="props">
        <span v-if="props.row.zichtbaar">
          <b-icon style="color: green" icon="check" size="is-small"> </b-icon>
        </span>
        <span v-if="!props.row.zichtbaar">
          <b-icon style="color: red" icon="times" size="is-small"> </b-icon>
        </span>
      </b-table-column>

      <b-table-column field="name" label="Bestellingen" v-slot="props">
        {{ props.row.bestellingen }}
      </b-table-column>

      <b-table-column field="name" label="Totale producten" v-slot="props">
        {{ props.row.totale_producten }}
      </b-table-column>

      <b-table-column field="name" label="Bestelde producten" v-slot="props">
        {{ props.row.bestelde_producten }}
      </b-table-column>

      <b-table-column field="name" label="Beschikbare producten" v-slot="props">
        {{ props.row.totale_producten - props.row.bestelde_producten }}
      </b-table-column>

      <template #detail="props">
        <div class="columns">
          <div class="column is-3">Registratie open:</div>
          <div class="column">
            {{
              formatDatePickerDateAndTime(
                props.row.bestelling_openings_timestamp
              )
            }}
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">Registratie sluiting:</div>
          <div class="column">
            {{
              formatDatePickerDateAndTime(
                props.row.bestelling_sluitings_timestamp
              )
            }}
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">Bestelling zichtbaar:</div>
          <div class="column">
            {{
              formatDatePickerDateAndTime(
                props.row.bestelling_zichtbaar_timestamp
              )
            }}
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">Bestelling onzichtbaar:</div>
          <div class="column">
            {{
              formatDatePickerDateAndTime(
                props.row.bestelling_onzichtbaar_timestamp
              )
            }}
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    index: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {},
  methods: {
    toevoegen() {
      this.$router.push({ name: "bestellingsdag-toevoegen" });
    },
    aanpassen() {
      this.$router.push({
        name: "bestellingsdag-aanpassen",
        params: { orderday: this.selected.id },
      });
    },
    openToggle(action) {
      var verb1;
      var verb2;
      var bool;
      if (action === "close") {
        verb1 = "sluiten";
        verb2 = "gesloten";
        bool = false;
      } else {
        verb1 = "openen";
        verb1 = "geopend";
        bool = true;
      }

      this.$buefy.dialog.confirm({
        title: "Bestelling aanpassen",
        message: "Weet u zeker dat u deze bestelling wil " + verb1 + "?",
        confirmText: "Bevestigen",
        type: "is-info",
        hasIcon: true,
        onConfirm: function () {
          const data = {
            selected: this.selected,
            action: bool,
          };
          this.$store.dispatch("bestellingsdagen/openToggle", data).then(() => {
            this.selected = null;
            this.$buefy.toast.open("Bestelling is " + verb2);
          });
        }.bind(this),
      });
    },
    zichtbaarToggle(action) {
      var verb1;
      var bool;
      if (action === "invisible") {
        verb1 = "onzichtbaar";
        bool = false;
      } else {
        verb1 = "zichtbaar";
        bool = true;
      }

      this.$buefy.dialog.confirm({
        title: "Bestelling aanpassen",
        message: "Weet u zeker dat u deze bestelling " + verb1 + " wil maken ?",
        confirmText: "Bevestigen",
        type: "is-info",
        hasIcon: true,
        onConfirm: function () {
          const data = {
            selected: this.selected,
            action: bool,
          };
          this.$store
            .dispatch("bestellingsdagen/visibleToggle", data)
            .then(() => {
              this.selected = null;
              this.$buefy.toast.open("Bestelling is " + verb1 + " gemaakt");
            });
        }.bind(this),
      });
    },
    verwijderen() {
      this.$buefy.dialog.confirm({
        title: "Bestelling verwijderen",
        message: "Weet u zeker dat u deze bestelling wil verwijderen?",
        confirmText: "Verwijderen",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("bestellingsdagen/destroy", this.selected)
            .then(() => {
              this.selected = null;
              this.$buefy.toast.open("Bestelling verwijderd");
            }),
      });
    },
  },
  mounted() {},
};
</script>
