<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Kunst</div>
          Bij Het Dagelijks Bestaan vind je veel creatievelingen. Ieder is dat
          op diens eigen manier: tekenen, schilderen, werken met klei, hout of
          speksteen. En er is nog veel meer mogelijk! <br />
          <br />
          We hebben een kunstlokaal met veel materialen beschikbaar. Hier mag je
          naar hartenlust ontdekken welke materialen en kunstvorm er bij jou
          passen. De kunstwerken kun je overal op het terrein tegen komen. Ook
          hangen ze in hoofdgebouw de Ontharding waar ze bijdragen aan de
          gezellige sfeer. Wekelijks is er ook ruimte voor muziek maken. We
          hebben de beschikbaarheid over een heel scala aan instrumenten.

          <br />
          <br />
        </div>
      </div>
      <div class="column">
        <carrousel :page="'werkplaatsen_kunst'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
