import Vue from "vue";

const hoofdletter = Vue.mixin({
  methods: {
    hoofdletter(payload) {
      if (payload && payload.length) {
        return payload.charAt(0).toUpperCase() + payload.slice(1);
      }
      return payload;
    },
  },
});

export default hoofdletter;
