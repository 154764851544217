<style>
#footer {
  background-color: #fffafa !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>

<template>
  <div>
    <br />
    <div id="footer">
      <div class="container has-text-centered is-fluid">
        Het Dagelijks Bestaan <br />
        Weg naar Vierakker 47<br />
        7204LA Zutphen <br />
        <br />

        <a href="https://www.instagram.com/hetdagelijksbestaan"
          ><img
            src="@/assets/pics/instagram.png"
            width="16"
            height="16"
          />&nbsp;Instagram</a
        >
        &nbsp;
        <a href="https://www.facebook.com/hetdagelijksbestaan/"
          ><img
            src="@/assets/pics/iconFacebook.svg"
            width="16"
            height="16"
          />&nbsp;Facebook</a
        >
        <br />
        <div>
          <a href="mailto:info@hetdagelijksbestaan.nl"
            >info@hetdagelijksbestaan.nl</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
