<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Buiten</div>
          Op de klusochtend op vrijdag wordt er samen met vrijwilligers, buren en de jongeren gestart
          rondom een vuurtje met een gebakken ei. In de ochtend worden de klussen verdeeld. Het onderhoud van de
          tuinen, het aanleggen van een moestuin, het opknappen van meubels, het
          leggen van een straatje of het bouwen van een schuurtje, er is altijd
          genoeg te doen. Samen maken we gezamenlijk ons terrein iedere week een beetje mooier!
        </div>
      </div>
      <div class="column">
        <carrousel :page="'werkplaatsen_buiten'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
