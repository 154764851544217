export const setUsers = function (state, payload) {
  state.users = payload;
};

export const setNewUser = function (state, payload) {
  state.users.push(payload);
};

export const updateUser = function (state, payload) {
  const array = state.users;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};

export const deleteUser = function (state, payload) {
  const array = state.users;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};
