<style scoped>
.overflow {
  width: 300px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected {
  border-color: #4a9e83 !important;
  border-style: solid !important;
  border: 1px;
}

.not-selected {
  color: lightgrey;
}

.not-selected strong {
  color: lightgrey;
}

.customInput {
  margin-bottom: 10px;
}

.help {
  margin-top: 0px;
}

.name_columns {
  margin: 0px;
  padding: 0px;
}

.name_column {
  margin: 0px;
  padding: 0px;
}

.cartValidation {
  margin: 0px;
  padding: 0px;
}
</style>

<template>
  <div>
    <br />
    <div class="container is-fluid">
      <div
        class="button is-fullwidth"
        @click="$router.push({ name: 'dagelijkse-soep-landing' })"
      >
        Terugkeren
      </div>
      <br />
      <div class="columns">
        <div class="column is-6">
          <div class="title is-5">Klant gegevens</div>
          <span class="columns name_columns">
            <span class="column name_column">
              <formInput
                class="customInput"
                :model="form"
                modelKey="voornaam"
                label="on-border"
                placeholder="Julia"
            /></span>
            <span class="column name_column">
              <formInput
                class="customInput"
                :model="form"
                modelKey="achternaam"
                label="on-border"
                placeholder="de Jong"
            /></span>
          </span>

          <formInput
            class="customInput"
            :model="form"
            modelKey="email"
            label="on-border"
            placeholder="julia@gmail.com"
          />

          <formInput
            class="customInput"
            :model="form"
            modelKey="telefoonnummer"
            label="on-border"
            placeholder="0575529222"
          />

          <formInput
            class="customInput"
            :model="form"
            modelKey="postcode"
            label="on-border"
            placeholder="7204LA"
            @blur="findAddress()"
          />

          <formInput
            class="customInput"
            :model="form"
            modelKey="huisnummer"
            label="on-border"
            placeholder="47"
            @blur="findAddress()"
          />

          <formInput
            :loading="addressLoading"
            class="customInput"
            :model="form"
            :disabled="editStraat === false"
            modelKey="straat"
            label="on-border"
            placeholder="Weg naar Vierakker"
          />

          <formInput
            :loading="addressLoading"
            class="customInput"
            :model="form"
            modelKey="plaats"
            label="on-border"
            :disabled="editStraat === false"
            placeholder="Zutphen"
          />
          <br />

          <b-field class="checkoutTermsCheckbox">
            <b-checkbox v-model="adresbewaren">
              Bewaar mijn klantgegevens op deze computer</b-checkbox
            >
          </b-field>

          <div class="title is-5">Bezorgopties</div>
          <b-field v-if="findVariable('order_pickup_enabled')">
            <b-radio v-model="form.bezorg_optie" native-value="ophalen">
              Ik eet mijn soep samen met anderen bij Het Dagelijks Bestaan en
              ben welkom vanaf
              {{
                formatTimePickerEditDateFromDataBase(
                  findVariable("soep_pickup_begin")
                )
              }}
              aan de Weg naar Vierakker 47
            </b-radio>
          </b-field>
          <b-field
            v-if="findVariable('order_shipping_enabled')"
            :type="{ 'is-danger': validation['bezorg_optie'] }"
            :message="validation['bezorg_optie']"
          >
            <b-radio v-model="form.bezorg_optie" native-value="bezorgen">
              Thuisbezorgen
              <span v-if="form.straat"
                >aan de "{{ form.straat }} {{ form.huisnummer }}"</span
              >
            </b-radio>
          </b-field>
        </div>

        <div class="column is-6">
          <div class="title is-5">Producten</div>
          <span v-if="totale_producten > 0">
            <span v-for="product in producten" :key="product.id">
              <span v-if="getProductBesteldagen(product).length">
                <div class="columns is-mobile">
                  <div class="column is-2">
                    <b-image
                      ratio="1by1"
                      :src="getFirstPictureUrl(product)"
                      alt="Product foto"
                    />
                  </div>
                  <div class="column overflow">
                    <span> {{ getProductName(product) }} </span>
                    <div class="content">
                      <ul>
                        <li
                          v-for="dag of getProductBesteldagen(product)"
                          :key="dag.id"
                        >
                          <div class="columns is-mobile">
                            <div class="column is-8">
                              {{ formatDatabaseDateShort(dag.bestellingsdag) }}
                              ({{ getProductOrderedAmount(dag, product) }})
                            </div>
                            <div class="column has-text-right">
                              <b-tooltip
                                label="Product uit bestelling verwijderen"
                                position="is-left"
                              >
                                <b-icon
                                  @click.native="removeFromOrder(product, dag)"
                                  icon="trash"
                                  class="pointer"
                                  size="is-small"
                                >
                                </b-icon>
                              </b-tooltip>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </span>
            </span>
          </span>
          <span v-else>Er staan nog geen producten in uw bestelling.</span>
          <hr />
          <span>Subtotal</span
          ><span class="is-pulled-right"> {{ euro(subtotaal) }}</span>
          <br />
          <span>Korting</span
          ><span class="is-pulled-right"> {{ euro(korting) }}</span>
          <hr />
          <span>Totaal</span
          ><span class="is-pulled-right"> {{ euro(subtotaal - korting) }}</span>

          <span
            class="help is-danger content cartValidation"
            v-if="validation.cart"
          >
            <ul>
              <li v-for="valKey in validation.cart.length" :key="valKey">
                {{ validation.cart[valKey - 1] }}
              </li>
            </ul>
          </span>

          <br />
          <br />

          <b-field
            label="Opmerking"
            :type="{ 'is-danger': validation.opmerking }"
            :message="{
              [validation.opmerking]: validation.opmerking,
            }"
          >
            <b-input
              v-model="form.opmerking"
              type="textarea"
              maxlength="1000"
              placeholder="Eventuele vragen of opmerkingen voor de bezorgers kunt u hier kwijt"
              @input="clearValidationError('opmerking')"
            ></b-input>
          </b-field>

          <b-field
            class="checkoutTermsCheckbox"
            :type="{ 'is-danger': validation.voorwaarden }"
            :message="validation.voorwaarden"
          >
            <b-checkbox v-model="form.voorwaarden"
              >Ik heb de
              <a @click.prevent="showTermsAndConditions()"
                >algemene voorwaarden</a
              >
              gelezen en ga hiermee akkoord</b-checkbox
            >
          </b-field>

          <button
            :disabled="totale_producten === 0"
            @click="checkout()"
            :class="{ 'is-loading': ajaxloading.bestellen }"
            class="button is-fullwidth is-primary is-small"
          >
            Bestellen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: [],
  data() {
    return {
      env: process.env,
      editStraat: false,
      addressLoading: false,
      adresbewaren: false,
      form: {
        terms: false,
        straat: "",
        plaats: "",
      },
    };
  },
  computed: {
    producten() {
      return this.$store.getters["checkout/index"];
    },
    totale_producten() {
      let hoeveelheid = 0;
      for (let product of this.producten) {
        for (let dag of product.besteldagen) {
          for (let gekozen_product of dag.products)
            hoeveelheid = hoeveelheid + gekozen_product.gekozen_hoeveelheid;
        }
      }
      return hoeveelheid;
    },
    subtotaal() {
      var subtotal = 0;
      for (let product of this.producten) {
        for (let dag of product.besteldagen) {
          for (let gekozen_product of dag.products) {
            subtotal =
              subtotal +
              gekozen_product.gekozen_hoeveelheid *
                gekozen_product.product.prijs;
          }
        }
      }
      return subtotal;
    },
    korting() {
      var korting = 0;
      for (let product of this.producten) {
        for (let dag of product.besteldagen) {
          for (let gekozen_product of dag.products) {
            korting =
              korting +
              gekozen_product.gekozen_hoeveelheid *
                gekozen_product.product.korting;
          }
        }
      }
      return korting;
    },
  },
  methods: {
    getFirstPictureUrl(product) {
      if (!product || !product.id) {
        return;
      }

      var picture = null;

      if (product.product && product.product.id) {
        picture = product.product.pictures[0].uuid;
        return this.env.VUE_APP_API_URL + "/web/picture/" + picture;
      } else {
        picture = product.picture;
        return (
          this.env.VUE_APP_API_URL + "/web/order_product_images/" + picture
        );
      }
    },
    findAddress() {
      if (!this.form.postcode) {
        return;
      }

      if (!this.form.huisnummer) {
        return;
      }
      this.editStraat = false;
      this.addressLoading = true;
      return this.findAddressFromPostalCode(
        this.form.postcode,
        this.form.huisnummer,
        this.form
      ).then(() => {
        this.editStraat = true;
        this.addressLoading = false;
      });
    },
    removeFromOrder(product, dag) {
      this.$buefy.toast.open({
        message: "Product uit bestelling verwijderd",
        type: "is-primary",
      });
      const dag_product = dag.products.find((a) => a.id === product.id);

      if (dag_product) {
        dag_product.gekozen_hoeveelheid = 0;
      }
    },
    showTermsAndConditions() {
      window.open(
        this.env.VUE_APP_API_URL + "/web/bekijk-voorwaarden-dagelijkse-soep"
      );
    },

    checkout() {
      this.form.totale_producten = this.totale_producten;
      this.form.products = this.producten;

      this.$store.dispatch("checkout/checkout", this.form).then((response) => {
        if (response.data === "website_gesloten") {
          this.$store.dispatch("getSettings");
        } else {
          if (this.adresbewaren) {
            localStorage.setItem(
              "soepBestelKlantgegevens",
              JSON.stringify(this.form)
            );
          } else {
            localStorage.removeItem("soepBestelKlantgegevens");
          }

          this.$router.push({
            name: "dagelijkse-soep-besteld",
          });
          this.$store.dispatch("checkout/index");
        }
      });
    },
    getProductName(product) {
      if (product.product && product.product.id) {
        return product.product.naam;
      }

      return product.naam;
    },
    getProductBesteldagen(product) {
      const returnArray = [];
      const besteldagen = product.besteldagen;
      for (let besteldag of besteldagen) {
        for (let besteld_product of besteldag.products) {
          if (
            product.product_id === besteld_product.product_id &&
            besteld_product.gekozen_hoeveelheid > 0
          ) {
            returnArray.push(besteldag);
          }
        }
      }
      return returnArray;
    },
    getProductOrderedAmount(dag, product) {
      var hoeveelheid = 0;
      for (let day_product of dag.products) {
        if (day_product.product_id === product.product.id) {
          hoeveelheid = day_product.gekozen_hoeveelheid;
        }
      }

      return hoeveelheid;
    },
  },
  mounted() {
    const oudeKlantGegevens = localStorage.getItem("soepBestelKlantgegevens");
    if (oudeKlantGegevens) {
      this.editStraat = true;
      const adres = JSON.parse(oudeKlantGegevens);
      Vue.set(this.form, "voornaam", adres.voornaam);
      Vue.set(this.form, "achternaam", adres.achternaam);
      Vue.set(this.form, "email", adres.email);
      Vue.set(this.form, "telefoonnummer", adres.telefoonnummer);
      Vue.set(this.form, "postcode", adres.postcode);
      Vue.set(this.form, "huisnummer", adres.huisnummer);
      Vue.set(this.form, "straat", adres.straat);
      Vue.set(this.form, "plaats", adres.plaats);
      this.adresbewaren = true;
    }
  },
};
</script>
