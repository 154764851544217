export const setIndex = function (state, payload) {
  state.index = payload;
};

export const setBesteldagen = function (state, payload) {
  state.besteldagen = payload;
};

export const setKlanten = function (state, payload) {
  for (let klant of payload) {
    klant.searchstring =
      klant.achternaam +
      ", " +
      klant.voornaam +
      " - " +
      klant.straat +
      " " +
      klant.huisnummer +
      " - " +
      klant.postcode +
      ", " +
      klant.plaats;
  }

  state.klanten = payload;
};

export const setNewItem = function (state, payload) {
  state.index.data.push(payload);
};

export const updateItem = function (state, payload) {
  const array = state.index.data;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};

export const deleteItem = function (state, payload) {
  const array = state.index.data;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};
