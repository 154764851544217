export const setIndex = function (state, payload) {
  state.index = payload;
};

export const setLeverdag = function (state, payload) {
  state.orderday = payload.orderday;
  state.orders = Object.values(payload.orders).sort(function (a, b) {
    if (a.sort_key > b.sort_key) {
      return 1;
    } else {
      return -1;
    }
  });
};

// export const setBesteldagen = function (state, payload) {
//   state.besteldagen = payload;
// };

// export const setKlanten = function (state, payload) {
//   for (let klant of payload) {
//     klant.searchstring =
//       klant.achternaam +
//       ", " +
//       klant.voornaam +
//       " - " +
//       klant.straat +
//       " " +
//       klant.huisnummer +
//       " - " +
//       klant.postcode +
//       ", " +
//       klant.plaats;
//   }

//   state.klanten = payload;
// };

export const setNewDeliveryList = function (state, payload) {
  // state.index.data.push(payload);
};

// export const updateItem = function (state, payload) {
//   const array = state.index.data;
//   const id = payload.id;
//   const index = array.findIndex((a) => a.id === id);
//   array.splice(index, 1, payload);
// };

// export const deleteItem = function (state, payload) {
//   const array = state.index.data;
//   const id = payload.id;
//   const index = array.findIndex((a) => a.id === id);
//   array.splice(index, 1);
// };
