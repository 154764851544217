<style scoped>
.custommodal {
  position: fixed;
  height: 100%;
  width: 100%;
}

.customInput {
  margin-bottom: 10px;
}

.card {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.alt-image {
  filter: grayscale(100%);
  max-height: 150px;
}
</style>

<template>
  <div>
    <div>
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">Product aanpassen</p>
        </header>
        <div class="card-content">
          <form @submit.prevent="submit()">
            <section class="modal-card-body">
              <formInput
                class="customInput"
                modelKey="naam"
                :model="form"
                placeholder="Naam van product"
              />

              <formSelect
                class="customInput"
                modelKey="status"
                :model="form"
                placeholder="Select status"
                :selectOptions="['concept', 'beschikbaar', 'niet_beschikbaar']"
              />

              <div class="columns">
                <div class="column">
                  <formInput
                    class="customInput"
                    modelKey="prijs"
                    :model="form"
                    placeholder="Prijs van product"
                  />
                </div>
                <div class="column">
                  <formInput
                    class="customInput"
                    modelKey="korting"
                    :model="form"
                    placeholder="Korting op product"
                  />
                </div>
              </div>

              <hr />
              <div class="columns">
                <div class="column">Totaal prijs:</div>
                <div class="column">
                  {{
                    euro(
                      replaceCommaWithDot(form.prijs) -
                        replaceCommaWithDot(form.korting)
                    )
                  }}
                </div>
              </div>
              <hr />

              <!-- picture part -->

              <productImages
                v-on:UnsavedPictures="setPictureSaveStatus"
                :product="product"
              />

              <br />

              <formEditor
                :value="form.omschrijving"
                title="Omschrijving"
                :model="form"
                modelKey="omschrijving"
              />
            </section>

            <footer class="modal-card-foot">
              <form-button
                type="is-primary"
                title="Update"
                size="is-small"
                loadingKey="update_product"
              />

              <button
                class="button is-rounded is-small"
                @click.prevent="cancelUpdate()"
              >
                Annuleren
              </button>
            </footer>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import productImages from "./AdminProductEditPictures.vue";

export default {
  components: {
    productImages,
  },
  props: {
    updateForm: {
      type: Object,
    },
  },
  data() {
    return {
      form: {},
      unSavedPictures: false,
      droppedFiles: [],
      previewImages: [],
      oldDescription: "",
      tags: [],
      env: process.env,
    };
  },
  computed: {
    products() {
      return this.$store.getters["soep/index"];
    },
    product() {
      const product = this.products.find(
        (a) => a.id == this.$router.currentRoute.params.product
      );

      if (product && product.pictures) {
        product.pictures.sort((a, b) => a.position - b.position);
      }

      if (product) {
        return product;
      }

      return {};
    },
  },
  methods: {
    setPictureSaveStatus(status) {
      this.unSavedPictures = status;
    },
    setProduct() {
      this.form = this.product;

      if (this.form.price == null) {
        Vue.set(this.form, "price", 0);
      }
      this.form.category = this.product.category;
      if (this.product.description) {
        this.oldDescription = this.product.description;
      }
      this.setTags();
    },
    formatPriceFields(key) {
      this.form[key] = this.euro(this.form[key]).format();
    },
    setPrice() {
      const prijs = this.form.editprijs.replace(",", ".") * 100;
      const prijsArray = String(prijs).split(".");
      Vue.set(this.form, "price", Number(prijsArray[0]));
    },
    cancelUpdate() {
      this.$store.dispatch("soep/refresh", this.form);
      this.$router.go(-1);
    },
    filesChanged() {
      this.previewImages = [];
      this.droppedFiles.forEach(
        function (file, index) {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            () => {
              let filepreview = {
                name: file.name,
                url: reader.result,
              };
              this.previewImages.push(filepreview);
            },
            false
          );
          reader.readAsDataURL(file);
        }.bind(this)
      );
    },
    uploadPictures() {
      this.droppedFiles.forEach(
        function (file, index) {
          let formdata = new FormData();
          formdata.append("image", file, file.name);
          this.$store.dispatch("uploadPicture", formdata).then(() => {
            this.droppedFiles.splice(index, 1);
            this.previewImages.splice(index, 1);
          });
        }.bind(this)
      );
    },
    deleteDroppedFile(index) {
      const array = this.droppedFiles;
      array.splice(index, 1);

      this.previewImages.splice(index, 1);
    },
    submit() {
      // this.form.btwpercentage = this.getBTWPercentage();
      if (this.unSavedPictures) {
        this.$buefy.dialog.confirm({
          message:
            "Niet alle foto's zijn opgeslagen, weet u zeker dat u verder wilt gaan? De foto's worden niet opgeslagen",
          onConfirm: () =>
            this.$store.dispatch("soep/edit", this.form).then((response) => {
              // if (response.data.slug === this.$route.params.product) {
              //   this.$router.replace({name: })
              // } else {
              this.$router.replace({
                name: "admin-product",
                params: { product: response.data.id },
              });
            }),
        });
      } else {
        this.$store.dispatch("soep/edit", this.form).then((response) => {
          // if (response.data.slug === this.$route.params.product) {
          //   this.$router.replace({name: })
          // } else {
          this.$router.replace({
            name: "admin-product",
            params: { product: response.data.id },
          });
        });
      }
    },

    // replaceCommaWithDot(payload) {
    //   return payload.replace(",", ".");
    // },
  },

  mounted() {
    if (!this.product || !this.product.id) {
      this.$router.go(-1);
    }

    this.form = this.product;
  },
};
</script>
