<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="users-title">Bestellingen</div>
    <br />
    <br />
    <br />
    <b-field>
      <b-input
        placeholder="Bestellingen zoeken..."
        @input="searchOrders()"
        v-model="form.zoekwaarde"
      ></b-input>
    </b-field>

    <div class="block table-icons">
      <b-tooltip label="Vaste klanten bestelling toevoegen" position="is-top">
        <span
          class="pointer table-icon"
          @click="vasteKlantBestellingToevoegen()"
        >
          <b-icon icon="clipboard-list"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip label="Bestelling toevoegen" position="is-top">
        <span class="pointer table-icon" @click="toevoegen()">
          <b-icon icon="plus"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip
        v-if="selected"
        label="Bevestiging email opnieuw versturen"
        position="is-left"
      >
        <span class="pointer table-icon" @click="mailOpnieuwVersturen()">
          <b-icon
            v-if="ajaxloading.resend_order_confirm_mail"
            custom-class="fa-spin"
            class="pointer"
            icon="spinner"
          />
          <b-icon v-else class="pointer" icon="envelope" />
        </span>
      </b-tooltip>

      <b-tooltip v-if="selected" label="Bestelling aanpassen" position="is-top">
        <span class="pointer table-icon" @click="aanpassen()">
          <b-icon icon="edit"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip
        v-if="selected"
        label="Bestelling verwijderen"
        position="is-top"
      >
        <span class="pointer table-icon" @click="verwijderen()">
          <b-icon icon="trash"></b-icon>
        </span>
      </b-tooltip>
    </div>

    <br />
    <br />
    <div>
      <b-table
        :data="index.data"
        :bordered="true"
        :striped="true"
        :hoverable="true"
        :loading="ajaxloading.get_index"
        :focusable="false"
        :mobile-cards="false"
        :selected.sync="selected"
        paginated
        detailed
        detail-key="id"
        backend-pagination
        :total="index.total"
        :current-page="index.current_page"
        :per-page="index.per_page"
        @page-change="changePage"
        @dblclick="aanpassen()"
      >
        <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column label="Naam" v-slot="props">
          {{ props.row.voornaam }} {{ props.row.achternaam }}
        </b-table-column>

        <b-table-column label="Adres" v-slot="props">
          {{ props.row.straat }} {{ props.row.huisnummer }} <br />
          {{ props.row.postcode }} {{ props.row.plaats }}
        </b-table-column>

        <b-table-column label="Bezorgoptie" v-slot="props">
          {{ props.row.bezorg_optie }}
        </b-table-column>

        <b-table-column label="Bron" v-slot="props">
          {{ props.row.bron }}
        </b-table-column>

        <b-table-column label="Bezorgdagen" v-slot="props">
          {{ countUniqueOrderdays(props.row.products) }}
        </b-table-column>

        <b-table-column label="Producten" v-slot="props">
          {{ props.row.producten }}
        </b-table-column>

        <b-table-column label="Besteldatum" v-slot="props">
          {{
            formatDatabaseDateHuman(props.row.created_at) +
            " om " +
            formatDatabaseTime(props.row.created_at)
          }}
        </b-table-column>

        <b-table-column label="Email verzonden" v-slot="props">
          <span v-if="props.row.email_verzonden">
            {{
              formatDatabaseDateHuman(props.row.email_verzonden) +
              " om " +
              formatDatabaseTime(props.row.email_verzonden)
            }}
          </span>
        </b-table-column>

        <b-table-column label="Opmerking" v-slot="props">
          <span v-if="props.row.opmerking">ja</span>
        </b-table-column>

        <template #detail="props">
          <div class="content">
            Email:
            <a :href="'mailto:' + props.row.email">{{ props.row.email }}</a>
            <br />

            Telefoonnummer:
            <a :href="'tel:' + props.row.telefoonnummer">{{
              props.row.telefoonnummer
            }}</a>
            <br />
            <br />
            Bestellingen: <br />
            <ul>
              <li :key="product.id" v-for="product in props.row.products">
                {{ product.hoeveelheid }}x
                {{ product.orderday_product.naam }} op
                {{ formatDatabaseDateShort(product.orderday.bestellingsdag) }}
              </li>
            </ul>
            <span v-if="props.row.opmerking">
              Opmerking: <br />
              {{ props.row.opmerking }}
            </span>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected: null,
      form: {},
      found_objects: null,
    };
  },
  computed: {
    index() {
      if (
        this.form.zoekwaarde &&
        this.form.zoekwaarde.length &&
        this.found_objects
      ) {
        return this.found_objects;
      } else {
        return this.$store.getters["bestellingen/index"];
      }
    },
  },
  methods: {
    changePage(pageNumber) {
      this.getNewPage(this.index.path + "?page=" + pageNumber);
    },
    getNewPage(route) {
      this.$store.dispatch("bestellingen/index", route);
    },
    countUniqueOrderdays(products) {
      const ids = [];
      for (let product of products) {
        ids.push(product.orderday_id);
      }
      return new Set(ids).size;
    },
    searchOrders() {
      this.$store.dispatch("bestellingen/search", this.form);
    },
    vasteKlantBestellingToevoegen() {
      this.$router.push({ name: "vaste-klant-bestelling-toevoegen" });
    },
    toevoegen() {
      this.$router.push({ name: "bestelling-toevoegen" });
    },
    aanpassen() {
      this.$router.push({
        name: "bestelling-aanpassen",
        params: { bestelling: this.selected.id },
      });
    },
    mailOpnieuwVersturen() {
      this.$buefy.dialog.confirm({
        title: "Email versturen",
        message:
          "Weet u zeker dat u de bevestigingsemail van deze bestelling opnieuw wil versturen?",
        confirmText: "Versturen",
        type: "is-info",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("bestellingen/resendOrderConfirmedEmail", this.selected)
            .then(() => {
              this.selected = null;
              this.$buefy.toast.open("Email verstuurd.");
            }),
      });
    },
    verwijderen() {
      this.$buefy.dialog.confirm({
        title: "Bestelling verwijderen",
        message: "Weet u zeker dat u deze bestelling wil verwijderen?",
        confirmText: "Verwijderen",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("bestellingen/destroy", this.selected)
            .then(() => {
              this.selected = null;
              this.$buefy.toast.open("Bestelling verwijderd");
            }),
      });
    },
  },
  mounted() {},
};
</script>
