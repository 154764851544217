<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Gebruiker toevoegen</div>
    <form @submit.prevent="gebruikerToevoegen()">
      <br />
      <br />

      <formInput
        class="customInput"
        :model="form"
        title="naam"
        modelKey="name"
        label="on-border"
      />

      <formInput
        class="customInput"
        :model="form"
        modelKey="email"
        label="on-border"
      />

      <formInput
        class="customInput"
        :model="form"
        modelKey="telefoonnummer"
        label="on-border"
      />

      <formSelect
        v-if="adminSettings.rollen"
        label="on-border"
        class="customInput"
        :model="form"
        placeholder="Kies rol"
        modelKey="rol"
        :selectOptions="adminSettings.rollen"
      />

      <div class="field">
        <b-checkbox v-model="form.uitnodigen">
          Uitnodiging versturen naar gebruiker
        </b-checkbox>
      </div>

      <br />

      <b-button
        native-type="submit"
        :loading="ajaxloading.create_user"
        type="is-primary is-rounded"
        >Gebruiker aanmaken</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected: null,
      form: {
        id: 0,
        uitnodigen: true,
      },
    };
  },
  computed: {},
  methods: {
    gebruikerToevoegen() {
      this.$store
        .dispatch("adminUsers/gebruikerToevoegen", this.form)
        .then(() => {
          this.$buefy.toast.open("Gebruiker aangemaakt");
          this.$router.push({ name: "admin-gebruikers" });
        });
    },
  },
  mounted() {},
};
</script>
