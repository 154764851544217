<style scoped>
.table-icons {
  float: right;
}

.no-margin {
  margin: 0px;
  padding: 0px;
}
</style>

<template>
  <div>
    <div class="title">Leverdag</div>
    <span v-if="leverdag && leverdag.id">
      <div class="subtitle">
        Datum: {{ formatDatePickerDate(leverdag.bestellingsdag) }}
      </div>

      <div class="columns no-margin">
        <div class="column is-3 no-margin">Totale bestellingen</div>
        <div class="column no-margin">{{ orders.length }}</div>
      </div>

      <div class="columns no-margin">
        <div class="column is-3 no-margin">Totale producten</div>
        <div class="column no-margin">{{ producten }}</div>
      </div>

      <br />

      <div class="columns no-margin">
        <div class="column is-3 no-margin">Bezorgaddressen</div>
        <div class="column no-margin">{{ bezorgen_adressen.length }}</div>
      </div>
      <div class="columns no-margin">
        <div class="column is-3 no-margin">Producten te bezorgen</div>
        <div class="column no-margin">{{ bezorgen_producten }}</div>
      </div>

      <br />

      <div class="columns no-margin">
        <div class="column is-3 no-margin">Meeeters</div>
        <div class="column no-margin">{{ meeeters }}</div>
      </div>

      <br />

      <div class="columns no-margin">
        <div class="column is-3 no-margin">Excel overzicht</div>
        <div class="column no-margin">
          <button
            @click="downloadFile()"
            class="button is-small is-primary is-outlined"
          >
            Downloaden
          </button>
        </div>
      </div>

      <br />
      <br />

      <span class="title is-5">Bezorglijsten</span>

      <span class="block table-icons">
        <b-tooltip
          @click.native="
            $router.push({
              name: 'create_new_leverdag_bezorglijst',
              params: { leverdag: leverdag.id },
            })
          "
          label="Bezorglijst toevoegen"
          position="is-top"
        >
          <span class="pointer table-icon">
            <b-icon icon="plus"></b-icon>
          </span>
        </b-tooltip>
      </span>

      <bezorglijstTable :index="[]" />

      <br />

      <div class="title is-5">Klanten</div>

      <div>
        <b-tabs
          expanded
          position="is-centered"
          class="block"
          v-model="activeTab"
          @input="syncActiveTabToQuery()"
        >
          <b-tab-item label="Bezorgen" value="bezorgen">
            <leverdagTable :index="bezorgen_adressen" />
          </b-tab-item>
          <b-tab-item label="Ophalen" value="ophalen">
            <leverdagTable :index="ophalen_adressen" />
          </b-tab-item>
        </b-tabs>
      </div>
    </span>
  </div>
</template>

<script>
import leverdagTable from "./LeverdagTable.vue";
import bezorglijstTable from "./LeverdagBezorglijsten/BezorglijstTable.vue";

export default {
  components: {
    leverdagTable,
    bezorglijstTable,
  },
  props: [],
  data() {
    return {
      activeTab: "bezorgen",
      env: process.env,
    };
  },
  computed: {
    leverdag() {
      return this.$store.getters["bestellijsten/orderday"];
    },
    orders() {
      return this.$store.getters["bestellijsten/orders"];
    },
    producten() {
      var producten = 0;

      for (let order of this.orders) {
        for (let product of order.products) {
          producten += product.hoeveelheid;
        }
      }

      return producten;
    },
    bezorgen_adressen() {
      return this.orders.filter((a) => a.order.bezorg_optie === "bezorgen");
    },
    bezorgen_producten() {
      var producten = 0;

      for (let order of this.bezorgen_adressen) {
        for (let product of order.products) {
          producten += product.hoeveelheid;
        }
      }

      return producten;
    },
    ophalen_adressen() {
      return this.orders.filter((a) => a.order.bezorg_optie === "ophalen");
    },
    meeeters() {
      var producten = 0;

      for (let order of this.ophalen_adressen) {
        for (let product of order.products) {
          producten += product.hoeveelheid;
        }
      }

      return producten;
    },
  },
  methods: {
    downloadFile() {
      window.open(
        this.env.VUE_APP_API_URL +
          "/web/admin/download_client_list/" +
          this.leverdag.id
      );
    },
    updateSelected(value) {
      this.selected = value;
    },
    syncActiveTabToQuery() {
      if (this.$router.currentRoute.query.activeTab !== this.activeTab) {
        this.$router.replace({ query: { activeTab: this.activeTab } });
      }
    },
    getActiveTabFromQuery() {
      if (this.$router.currentRoute.query.activeTab) {
        this.activeTab = this.$router.currentRoute.query.activeTab;
      }
    },
  },
  mounted() {
    this.getActiveTabFromQuery();
  },
};
</script>
