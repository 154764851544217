import axios from "axios";

export const index = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get_products";
  data.method = "GET";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setIndex", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createItem = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create_product";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setNewItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const edit = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "update_product/" + payload.id;
  data.loading = "update_product";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const destroy = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "delete_product/" + payload.id;
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("deleteItem", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const refresh = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "refresh_product/" + payload.id;
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const uploadExtraPicture = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "product-upload-extra-picture/" + payload.get("product");
  data.method = "post";
  data.loading = "upload_picture";
  data.data = payload;

  dispatch("loadingOn", data.loading, { root: true });
  return axios({
    url: process.env.VUE_APP_API_URL + "/web/admin/" + data.url,
    method: data.method,
    data: data.data,
    withCredentials: true,
    onUploadProgress: (progressEvent) =>
      commit("setUploadPercentage", progressEvent),
  })
    .then(function (response) {
      dispatch("loadingOff", data.loading, { root: true });
      commit("setUploadPercentage", 0);
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch(function (error) {
      dispatch("loadingOff", data.loading, { root: true });
      commit("setUploadPercentage", 0);
      return Promise.reject(error);
    });
};

export const deleteProductPicture = function ({ dispatch, commit }, payload) {
  const request = {};
  request.uuid = payload;
  const data = {};
  data.url = "product-delete-picture";
  data.method = "post";
  data.loading = "delete_product_product";
  data.data = request;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const uploadResizedPicture = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "product-upload-resized-picture/" + payload.get("product");
  data.method = "post";
  data.loading = "upload_picture";
  data.data = payload;

  dispatch("loadingOn", data.loading, { root: true });
  return axios({
    url: process.env.VUE_APP_API_URL + "/web/admin/" + data.url,
    method: data.method,
    data: data.data,
    withCredentials: true,
    onUploadProgress: (progressEvent) =>
      commit("setUploadPercentage", progressEvent),
  })
    .then(function (response) {
      commit("setUploadPercentage", 0);
      dispatch("loadingOff", data.loading, { root: true });
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch(function (error) {
      commit("setUploadPercentage", 0);
      dispatch("loadingOff", data.loading, { root: true });
      return Promise.reject(error);
    });
};

export const changePosition = function ({ dispatch, commit }, payload) {
  const array = payload.pictures;
  var newIndex;

  if (payload.index === 0 && payload.direction === "up") {
    return;
  }

  if (
    payload.index === payload.pictures.length - 1 &&
    payload.direction === "down"
  ) {
    return;
  }

  if (payload.direction === "up") {
    newIndex = payload.index - 1;
  }

  if (payload.direction === "down") {
    newIndex = payload.index + 1;
  }

  const Oldposition = array[payload.index].position;
  const NewPosition = array[newIndex].position;

  const Other = array.find((a) => a.position === NewPosition);
  const Clicked = array.find((a) => a.position === Oldposition);

  Other.position = Oldposition;
  Clicked.position = NewPosition;

  const request = {
    other: Other,
    clicked: Clicked,
  };

  const data = {};
  data.url = "product-change-picture-position";
  data.method = "post";
  data.loading = "change_position";
  data.data = request;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      // commit("updateProduct", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
