<style scoped>
.no-margin {
  padding: 0px;
  margin: 0px;
}
</style>

<template>
  <div>
    <div class="title is-4">Website</div>

    <span v-if="settings && settings.admin && settings.admin.variables.length">
      <span v-for="variable in settings.admin.variables.length" :key="variable">
        <span
          v-if="
            settings.admin.variables[variable - 1].category === 'website_open'
          "
        >
          <br v-if="settings.admin.variables[variable - 1].type === 'switch'" />
          <b-switch
            @input="saveVariables('website_open')"
            class="customInput"
            v-if="settings.admin.variables[variable - 1].type === 'switch'"
            v-model="form[settings.admin.variables[variable - 1].key]"
          >
            {{ settings.admin.variables[variable - 1].name }}
          </b-switch>
        </span>
      </span>
    </span>

    <br />
    <br />

    <div class="title is-5">Statistieken</div>

    <div class="columns no-margin">
      <div class="column no-margin is-4">Besteldagen</div>
      <div class="column no-margin">{{ stats.orderdays }}</div>
    </div>

    <div class="columns no-margin">
      <div class="column no-margin is-4">Unieke Klanten</div>
      <div class="column no-margin">{{ stats.customers }}</div>
    </div>

    <br />

    <div class="columns no-margin">
      <div class="column no-margin is-4">Bestellingen</div>
      <div class="column no-margin">{{ stats.orders }}</div>
    </div>
    <div
      class="columns no-margin"
      v-for="(value, key) in stats.orders_bronnen"
      :key="key"
    >
      <div class="column no-margin is-4">
        &nbsp;&nbsp;&nbsp;&nbsp;{{ hoofdletter(key) }}
      </div>
      <div class="column no-margin">&nbsp;&nbsp;&nbsp;&nbsp;{{ value }}</div>
    </div>
    <br />

    <div class="columns no-margin">
      <div class="column no-margin is-4">Bestelde soepen</div>
      <div class="column no-margin">{{ stats.orders_producten }}</div>
    </div>
    <div
      class="columns no-margin"
      v-for="(value, key) in stats.orders_bezorgopties"
      :key="key"
    >
      <div class="column no-margin is-4">
        &nbsp;&nbsp;&nbsp;&nbsp;{{ hoofdletter(key) }}
      </div>
      <div class="column no-margin">&nbsp;&nbsp;&nbsp;&nbsp;{{ value }}</div>
    </div>

    <br />
    <br />

    <div class="title is-5">E-mail voorbeelden</div>

    <div class="field is-grouped">
      <p class="control">
        <button
          @click="showEmail('orderConfirmedShipping')"
          class="button is-primary"
        >
          Bestellings bevestiging met bezorging
        </button>
      </p>
      <p class="control">
        <button
          class="button is-primary"
          @click="showEmail('orderConfirmedPickup')"
        >
          Bestellings bevestiging met ophalen
        </button>
      </p>
      <p class="control">
        <button
          class="button is-primary"
          @click="showEmail('donationConfirmed')"
        >
          Donatie email
        </button>
      </p>

      <p class="control">
        <button
          class="button is-primary"
          @click="showEmail('donationConfirmedBusiness')"
        >
          Donatie email zakelijk
        </button>
      </p>
    </div>

    <br />

    <div class="title is-5">Pagina snelkoppelingen</div>

    <div class="field is-grouped">
      <p class="control">
        <button
          @click="$router.push({ name: 'dagelijkse-soep-checkout' })"
          class="button is-primary"
        >
          Bestelpagina
        </button>
      </p>
      <p class="control">
        <button
          class="button is-primary"
          @click="$router.push({ name: 'dagelijkse-soep-besteld' })"
        >
          Pagina na geplaatste bestelling
        </button>
      </p>
      <p class="control">
        <button
          class="button is-primary"
          @click="$router.push({ name: 'donatie-bedankt' })"
        >
          Pagina na donatie
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {},
  data() {
    return {
      stats: {
        orders_bronnen: [],
        orders_bezorgopties: [],
      },
      form: {},
    };
  },
  computed: {
    settings() {
      return this.$store.getters["all_settings/index"];
    },
  },
  methods: {
    showEmail(email) {
      location.assign(
        process.env.VUE_APP_API_URL +
          "/web/admin/show-user-email-example/" +
          email
      );
    },
    setVariables() {
      const array = this.settings.variables;

      array.forEach((a) => {
        if (a.type === "boolean") {
          if (a.value == 1) {
            Vue.set(this.form, a.key, true);
          }

          if (a.value == 0) {
            Vue.set(this.form, a.key, false);
          }
        } else {
          Vue.set(this.form, a.key, a.value);
        }
      });
    },
    saveVariables(category) {
      const closedMessage = this.form.website_closed_message;
      if (closedMessage && closedMessage.length && closedMessage.length < 8) {
        this.form.website_closed_message = "";
      }

      if (category) {
        this.form.category = category;
      }

      this.$store
        .dispatch("all_settings/saveVariables", this.form)
        .then(() => {
          this.form.category = null;
          this.open = "";
          this.$store.dispatch("getSettings");
          this.$buefy.toast.open({
            message: "Variabelen opgeslagen",
            type: "is-success",
          });
        })
        .catch((error) => {
          this.form.category = null;
          if (error.response.status === 422) {
            this.$buefy.snackbar.open({
              duration: 10000,
              message: error.response.data,
              type: "is-danger",
              queue: false,
              position: "is-top",
            });
          }
        });
    },
  },
  mounted() {
    this.$store.dispatch("statistics/index").then((response) => {
      this.stats = response.data;
    });
    if (this.all_settings && this.all_settings.length) {
      this.setVariables();
    } else {
      this.$store.dispatch("all_settings/getIndex").then(() => {
        this.setVariables();
      });
    }
  },
};
</script>
