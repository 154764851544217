<template>
  <b-field
    :label="hoofdletter(computedTitle)"
    :label-position="label"
    :type="{ 'is-danger': validation[computedValidation] }"
    :message="validation[computedValidation]"
  >
    <b-autocomplete
      @focus="$event.target.select()"
      :ref="modelKey"
      @blur="blur()"
      @input="clearValidationError()"
      v-model="model[modelKey]"
      :size="size"
      :placeholder="placeholder"
      :password-reveal="type === 'password'"
      :disabled="disabled"
      :data="options"
      :field="optionKey"
      clearable
      :loading="loading"
      :keep-first="true"
      :open-on-focus="openOnFocus"
      @select="optionSelected"
    >
      <template #empty>{{ notFoundText }}</template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    notFoundText: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    optionKey: {
      type: String,
    },
    model: {
      required: true,
      type: Object,
    },
    modelKey: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
    },
    validationKey: {
      type: String,
    },
    size: {
      default: "is-normal",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    type: {
      default: "input",
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    openOnFocus: {
      default: true,
    },
    loading: {
      default: false,
    },
  },
  data() {
    return {
      syncValue: true,
    };
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedValidation() {
      if (this.validationKey) {
        return this.validationKey;
      } else {
        return this.modelKey;
      }
    },
  },
  methods: {
    blur() {
      this.$emit("blur");
    },
    clearValidationError() {
      this.$emit("input");
      this.syncValue = false;
      this.$store.dispatch("clearValidationError", this.modelKey);
    },
    setValue() {
      //   if (this.value !== undefined && this.syncValue) {
      //     Vue.set(this.model, this.modelKey, this.value);
      //   }
    },
    optionSelected(option) {
      this.$emit("selected", option);
    },
  },
  watch: {
    // value: {
    //   deep: true,
    //   handler() {
    //     this.setValue();
    //   },
    // },
    // model: {
    //   deep: true,
    //   handler() {
    //     this.setValue();
    //   },
    // },
  },
  mounted() {
    // this.setValue();
  },
};
</script>
