export const index = function (state) {
  if (state.index.data) {
    state.index.data.sort((a, b) => b.id - a.id);
  }
  return state.index;
};

export const besteldagen = function (state) {
  return state.besteldagen.sort(
    (a, b) => new Date(b.bestellingsdag) - new Date(a.bestellingsdag)
  );
};

export const klanten = function (state) {
  return state.klanten.sort((a, b) => {
    if (a.achternaam.toLowerCase() > b.achternaam.toLowerCase()) {
      return 1;
    } else {
      return -1;
    }
  });
};
