import validation from "./validation.js";
import loading from "./loading.js";
import customMounted from "./customMounted.js";
import hoofdletter from "./hoofdletter.js";
import auth from "./auth.js";
import formatDatabaseDate from "./formatDatabaseDate.js";
import address from "./address.js";
import euro from "./euro.js";

export default {
  validation: validation,
  loading: loading,
  customMounted: customMounted,
  auth: auth,
  hoofdletter: hoofdletter,
  euro,
  address,
  formatDatabaseDate,
};
