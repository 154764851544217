export const index = function ({ dispatch, commit }, payload) {
  const data = {};
  if (payload) {
    data.url = payload;
    data.fullUrl = true;
  } else {
    data.url = "get_orders";
  }
  data.loading = "get_index";
  data.method = "GET";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setIndex", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getBesteldagen = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "bestellingen_get_besteldagen";
  data.loading = "get_index";
  data.method = "get";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setBesteldagen", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getKlanten = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "bestellingen_get_klanten";
  data.loading = "get_klanten";
  data.method = "get";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setKlanten", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const zoekKlanten = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "bestellingen_search_klanten/" + payload;
  data.loading = "search_customers";
  data.method = "get";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setKlanten", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const search = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "search_orders/" + payload.zoekwaarde;
  data.loading = "get_index";
  data.method = "get";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setIndex", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const create = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create_bestelling";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setNewItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createMassaBestelling = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create_massa_bestelling";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const find = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "find_bestelling/" + payload;
  data.method = "GET";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const resendOrderConfirmedEmail = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "resend_order_email/" + payload.id;
  data.loading = "resend_order_confirm_mail";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const edit = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "edit_order/" + payload.id;
  data.loading = "edit";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const destroy = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "destroy_order/" + payload.id;
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("deleteItem", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const refresh = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "refresh_order/" + payload.id;
  data.method = "get";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
