<style scoped>
.customTitle {
  font-weight: bold;
}

.customBox {
  border-style: solid;
}
</style>

<template>
  <div>
    <quote
      quote="Op Het Dagelijks Bestaan springen de levensvragen zo op je schouder"
    ></quote>
    <br />
    <div class="columns">
      <div class="column is-6">
        <div class="box content">
           <strong>Gezocht:</strong><i> Mensen die zin hebben om op duurzame wijze zichzelf te ontwikkelen middels gemeenschappelijk wonen en bereid zijn daarin jongeren met diepe levensvragen mee te nemen.</i>
           <br>
           <br>
           <strong>Aangeboden:</strong><i> Circulaire een-slaapkamerwoning midden op het hof van een woongemeenschap waar zorg, wonen en werken wordt gecombineerd door een groep eigenzinnige, onderzoekende mensen.</i>
          <br>
          <br>
          Lieve nieuwsgierige,
          <br>
          <br>
          Het Dagelijks Bestaan is een woonvorm waar zorg en gemeenschappelijk wonen wordt gecombineerd, gelegen in het Emerpark te Zutpen. Er zijn zesentwintig woningen, waarvan de helft bewoond wordt door de burengemeenschap. De andere helft bestaat uit studio’s voor jongeren. Deze plekken zijn via Beschermd Wonen beschikbaar en de jonge mensen wonen hier met elk hun eigen ontwikkelingsvragen. Daarnaast is er doordeweeks een dynamisch ontwikkelprogramma met diverse werkplaatsen. Als buurman/buurvrouw leer je deze jonge mensen goed kennen. Als bewoner, jong of oud, krijg je hier de kans om je eigen levensrealiteit te ontdekken met al de bij jou horende mogelijkheden en gevoeligheden. 
          <br>
          <br>
          Samen delen wij geregeld (biologische) maaltijden, werken we in de tuin of drinken een kopje thee of koffie. Er is altijd wel een klusje te doen en we hebben oog voor onze omgeving. We hechten belang aan het ontwikkelen van rituelen en gewoontes en verzorgen onze relaties. Iedere woensdagavond om 18 uur eten wij gezamenlijk. Op zaterdag om 11 uur drinken we koffie en thee. Soms steken we daarna de handen uit de mouwen om een klus aan te pakken. Zo groeit en bloeit ons terrein ieder jaar een beetje meer! 
          <br>
          <br>
          ‘Op Het Dagelijks Bestaan springen de levensvragen zo op je schouder’, daar moet je maar net zin in hebben! Wij hebben dat als groep, we zijn met elkaar op weg om uit te zoeken hoe gemeenschappelijk wonen eigenlijk werkt. We zijn niet op zoek naar mensen die het antwoord weten, wel naar mensen die zin hebben om het samen met ons verder uit te pluizen. Affiniteit met jongeren is erg belangrijk, de jonge mensen die hier wonen landen voor een periode van een paar jaar bij ons in de gemeenschap. Je krijgt de kans om een stukje mee op te lopen in hun zoektocht naar een kloppende plek in de maatschappij. Daarnaast worden zij begeleidt door een team van professionals. Buren zijn ook ondersteunend in het proces van de jongeren; ieder doet dat op zijn of haar eigen manier. Dit kan bijvoorbeeld door deel te nemen aan de telefonische nachtwacht, het meedraaien met de weekenddiensten of koken samen met de jongeren
          <br>
          <br>
          De woning die beschikbaar is 32m2, beschikt over eigen keukentje, douche en toilet. De huurprijs is rond de 590 euro per maand, er kan huursubsidie worden aangevraagd. De woning wordt per 1 september verhuurd. Het huisje staat op de tweede verdieping, je kunt gebruik maken van de dakterrassen en de gezamenlijke tuin. Er komt veel zonlicht en warmte de woning binnen.
          <br>
          <br>
          Sta jij stevig op je eigen voeten? Wil je samen met ons de buitenruimte en gezamenlijke ruimtes verzorgen? Kun je het uithouden met het niet altijd weten van het antwoord? Vind je het fijn om wekelijks samen te eten en tussen de mensen te zijn? Ben je bereid om te ondersteunen middels een weekenddienst of telefonische nachtwacht? Oftewel: heb je zin om deel te worden van deze eigenzinnige gemeenschap? Stuur dan voor 16 augustus een e-mail naar <a href="mailto:meewonen@hetdagelijksbestaan.nl">meewonen@hetdagelijksbestaan.nl</a> dan nemen we contact met je op. Je kunt ook mailen voor het plannen van een belafspraak of het stellen van vragen. We zien graag een mail tegemoet waarin we iets meer over je kunnen lezen! 
        </div>
      </div>
      <div class="column">
        <carrousel :page="'meedoen_woongemeenschap'" />
      </div>
    </div>
  </div>
</template>

<script>
import quote from "@/views/Components/Quote.vue";
export default {
  components: {
    quote
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
