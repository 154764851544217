import Vue from "vue";

const findAddress = Vue.mixin({
  methods: {
    findAddressFromPostalCode(
      postalcode,
      housenumber,
      model,
      streetKey,
      cityKey
    ) {
      if (!postalcode) {
        return Promise.resolve();
      }

      if (!housenumber) {
        return Promise.resolve();
      }

      if (!streetKey) {
        streetKey = "straat";
      }

      if (!cityKey) {
        cityKey = "plaats";
      }

      return this.$store
        .dispatch("axiosWeb", {
          url: "resolve-postal-code",
          method: "post",
          data: {
            postcode: postalcode,
            huisnummer: housenumber,
          },
        })
        .then((response) => {
          Vue.set(model, streetKey, response.data.street);
          Vue.set(model, cityKey, response.data.city);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
  },
});

export default findAddress;
