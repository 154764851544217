<style scoped>
.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <span class="title is-5">Klant:</span>
    <div class="block table-icons">
      <b-tooltip label="Klant aanpassen" position="is-top">
        <span
          class="pointer table-icon"
          @click="
            $router.push({
              name: 'klant-aanpassen',
              params: { klant: klant.id },
            })
          "
        >
          <b-icon icon="edit"></b-icon>
        </span>
      </b-tooltip>
    </div>
    <br />
    <br />
    {{ klant.voornaam }} {{ klant.achternaam }} <br />
    {{ klant.straat }} {{ klant.huisnummer }}
    <br />
    {{ klant.postcode }} te {{ klant.plaats }}
    <br />
    <br />
    <a :href="'mailto:' + klant.email">{{ klant.email }}</a>
    <br />
    <a :href="'tel:' + klant.telefoonnummer">{{ klant.telefoonnummer }}</a>
    <br />
    <br />
    <span v-if="klant.vaste_klant">
      <div class="bold">Vaste klant gegevens</div>
      Standaard producten: {{ klant.vaste_klant_producten }} <br />
      Standaard bezorgkeuze: {{ klant.vaste_klant_bezorgkeuze }} <br />
      Standaard orderbevestiging mailen:
      <span v-if="klant.vaste_klant_mailen"> ja </span>
      <span v-else> nee </span>
      <br />
      <br />
    </span>

    <div class="title is-5">Bestellingen:</div>

    <b-table
      :data="klant.orders"
      :bordered="true"
      :striped="true"
      :hoverable="true"
      :focusable="false"
      :mobile-cards="false"
      :selected.sync="selectedOrder"
      detailed
      detail-key="id"
      @dblclick="showOrder()"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column label="Naam" v-slot="props">
        {{ props.row.voornaam }} {{ props.row.achternaam }}
      </b-table-column>

      <b-table-column label="Adres" v-slot="props">
        {{ props.row.straat }} {{ props.row.huisnummer }}<br />
        {{ props.row.postcode }} {{ props.row.plaats }}
      </b-table-column>

      <b-table-column label="Contactgegevens" v-slot="props">
        <a :href="'mailto:' + props.row.email">{{ props.row.email }}</a>
        <br />

        <a :href="'tel:' + props.row.telefoonnummer">{{
          props.row.telefoonnummer
        }}</a>
      </b-table-column>

      <b-table-column label="Bezorgoptie" v-slot="props">
        {{ props.row.bezorg_optie }}
      </b-table-column>

      <b-table-column label="Bron" v-slot="props">
        {{ props.row.bron }}
      </b-table-column>

      <b-table-column label="Bezorgdagen" v-slot="props">
        {{ countUniqueOrderdays(props.row.products) }}
      </b-table-column>

      <b-table-column label="Producten" v-slot="props">
        {{ props.row.producten }}
      </b-table-column>

      <b-table-column label="Besteldatum" v-slot="props">
        {{
          formatDatabaseDateHuman(props.row.created_at) +
          " om " +
          formatDatabaseTime(props.row.created_at)
        }}
      </b-table-column>

      <b-table-column label="Opmerking" v-slot="props">
        <span v-if="props.row.opmerking">ja</span>
      </b-table-column>

      <template #detail="props">
        <div class="content">
          <ul>
            <li :key="product.id" v-for="product in props.row.products">
              {{ product.hoeveelheid }}x {{ product.orderday_product.naam }} op
              {{ formatDatabaseDateShort(product.orderday.bestellingsdag) }}
            </li>
          </ul>
          <span v-if="props.row.opmerking">
            Opmerking: <br />
            {{ props.row.opmerking }}
          </span>
        </div>
      </template>
    </b-table>

    <span v-if="klant.donations.length">
      <br />
      <div class="title is-5">Donaties:</div>

      <b-table
        :data="klant.donations"
        :bordered="true"
        :striped="true"
        :focusable="false"
        :mobile-cards="false"
        @dblclick="showOrder()"
      >
        <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column label="Naam" v-slot="props">
          {{ props.row.voornaam }} {{ props.row.achternaam }}
        </b-table-column>

        <b-table-column label="Adres" v-slot="props">
          {{ props.row.straat }} {{ props.row.huisnummer }} <br />
          {{ props.row.postcode }} {{ props.row.plaats }}
        </b-table-column>

        <b-table-column label="Contactgegevens" v-slot="props">
          <a :href="'mailto:' + props.row.email">{{ props.row.email }}</a>
          <br />

          <a :href="'tel:' + props.row.telefoonnummer">{{
            props.row.telefoonnummer
          }}</a>
        </b-table-column>

        <b-table-column label="Donatiedatum" v-slot="props">
          {{
            formatDatabaseDateHuman(props.row.created_at) +
            " om " +
            formatDatabaseTime(props.row.created_at)
          }}
        </b-table-column>

        <b-table-column label="Bedrag" v-slot="props">
          {{ euroFromCents(props.row.bedrag) }}
        </b-table-column>

        <b-table-column label="Methode" v-slot="props">
          {{ props.row.betaal_methode }}
        </b-table-column>

        <b-table-column label="Status" v-slot="props">
          {{ props.row.status }}
        </b-table-column>
      </b-table>
    </span>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      selectedOrder: null,
    };
  },
  computed: {
    index() {
      return this.$store.getters["klanten/index"];
    },
    klant() {
      if (this.index.data) {
        return this.index.data.find((a) => a.id == this.$route.params.klant);
      } else {
        return {};
      }
    },
  },
  methods: {
    countUniqueOrderdays(products) {
      const ids = [];
      for (let product of products) {
        ids.push(product.orderday_id);
      }
      return new Set(ids).size;
    },
    showOrder() {
      this.$router.push({
        name: "bestelling-aanpassen",
        params: { bestelling: this.selectedOrder.id },
      });
    },
  },
  mounted() {
    if (!this.index || !this.index.data || !this.index.data.length) {
      this.$router.go(-1);
    }
  },
};
</script>
