<template>
  <div>
    <div class="columns">
      <!-- images uploaded -->
      <div class="column">
        <div class="content" v-if="product.pictures && product.pictures.length">
          <div>
            <strong><small>Saved Pictures</small></strong>
          </div>
          <ul>
            <li v-for="(pic, i) in product.pictures" :key="i">
              <span v-if="pic.uuid"> {{ pic.id + ".jpg" }} </span>
              <span v-else>
                {{ pic.name }}
              </span>

              <button
                class="is-pulled-right pointer"
                @click.prevent="deleteImage(i)"
              >
                <b-icon icon="trash" size="is-small"> </b-icon>
              </button>
              <button
                class="is-pulled-right pointer"
                @click.prevent="changePosition(i, 'down')"
              >
                <b-icon icon="arrow-down" size="is-small"> </b-icon>
              </button>
              <button
                class="is-pulled-right pointer"
                @click.prevent="changePosition(i, 'up')"
              >
                <b-icon icon="arrow-up" size="is-small"> </b-icon>
              </button>
            </li>
          </ul>
        </div>

        <span v-if="previewImages.length">
          <div>
            <strong><small>Unsaved Pictures</small></strong>
          </div>

          <ul>
            <li v-for="(pic, i) in previewImages" :key="i">
              <span> {{ i + 1 }} </span>

              <button
                class="is-pulled-right pointer"
                @click.prevent="deleteDroppedFile(i)"
              >
                <b-icon icon="trash" size="is-small"> </b-icon>
              </button>
              <button
                class="is-pulled-right pointer"
                @click.prevent="openEditPictureModal(i)"
              >
                <b-icon icon="edit" size="is-small"> </b-icon>
              </button>
            </li>
          </ul>
        </span>

        <br />

        <!-- picture upload -->

        <form @submit.prevent="submit()" class="">
          <b-field
            :type="{ 'is-danger': validation['images'] }"
            :message="validation['images']"
          >
            <b-upload
              v-model="droppedFiles"
              @input="filesChanged()"
              drag-drop
              expanded
              native
              multiple
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-small"> </b-icon>
                  </p>
                  <p>Sleep bestand hierin of klik om bestand up te loaden</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <progress
            v-if="$store.getters['soep/uploadPercentage'] > 0"
            class="progress is-success"
            :value="$store.getters['soep/uploadPercentage']"
            max="100"
          ></progress>

          <b-field class="file" v-if="droppedFiles && droppedFiles.length">
            <a
              :class="[
                'button is-primary is-fullwidth',
                { 'is-loading': ajaxloading['upload_picture'] },
              ]"
              @click="uploadPictures"
            >
              <span>Fotos opslaan</span>
            </a>
          </b-field>
          <span v-for="index in previewImages.length" :key="index">
            <p class="help is-danger" v-if="validation['image_' + index]">
              {{ validation["image_" + index][0] }}
            </p>
          </span>
        </form>
      </div>
      <!-- picture preupload carrousel -->
      <div class="column is-6 is-clipped" v-if="previewImages.length">
        <b-carousel
          indicator-custom
          :indicator-inside="false"
          :autoplay="false"
        >
          <b-carousel-item v-for="(item, i) in previewImages" :key="i">
            <b-image
              ratio="1by1"
              class="image"
              :src="previewImages[i].url"
            ></b-image>
          </b-carousel-item>
          <template #indicators="props">
            <b-image
              ratio="1by1"
              v-if="previewImages[props.i]"
              class="al image"
              :src="previewImages[props.i].url"
              :title="props.i"
            ></b-image>
          </template>
        </b-carousel>
      </div>

      <div class="column is-6 is-clipped" v-else>
        <b-carousel
          indicator-custom
          :indicator-inside="false"
          :autoplay="false"
        >
          <b-carousel-item v-for="(item, i) in product.pictures" :key="i">
            <b-image
              ratio="1by1"
              class="image"
              :src="
                env.VUE_APP_API_URL + '/web/picture/' + product.pictures[i].uuid
              "
            ></b-image>
          </b-carousel-item>
          <template #indicators="props">
            <b-image
              ratio="1by1"
              v-if="product.pictures[props.i]"
              class="al image"
              :src="
                env.VUE_APP_API_URL +
                '/web/picture/' +
                product.pictures[props.i].uuid
              "
              :title="props.i"
            ></b-image>
          </template>
        </b-carousel>
      </div>
    </div>
    <formEditPictureModal
      v-on:closeModal="closeCropModal()"
      v-on:pictureUploaded="croppedPictureUploaded()"
      v-if="editPicture"
      :picture="editPicture"
      :product="product"
    />
  </div>
</template>

<script>
import formEditPictureModal from "./AdminEditPictureModal.vue";

export default {
  components: { formEditPictureModal },
  props: {
    product: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      droppedFiles: [],
      previewImages: [],
      savedFiles: [],
      editPicture: null,
      env: process.env,
      editPictureIndex: null,
    };
  },
  computed: {},
  methods: {
    filesChanged() {
      this.previewImages = [];
      this.droppedFiles.forEach((element) => {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            let filepreview = {
              name: element.name,
              url: reader.result,
            };
            this.previewImages.push(filepreview);
          },
          false
        );
        reader.readAsDataURL(element);
      });
    },
    uploadPictures() {
      if (!this.droppedFiles.length) {
        return;
      }

      const amountOfPictures = this.droppedFiles.length;

      var pictureId = 1;
      const formData = new FormData();
      this.droppedFiles.forEach((picture) => {
        formData.append("image_" + pictureId, picture, picture.name);
        pictureId++;
      });

      formData.append("amountOfPictures", amountOfPictures);
      formData.append("product", this.product.id);

      this.$store
        .dispatch("soep/uploadExtraPicture", formData)
        .then((response) => {
          this.droppedFiles = [];
          this.previewImages = [];
        });
    },
    deleteImage(index) {
      this.$buefy.dialog.confirm({
        title: "Foto verwijderen",
        message: "Weet je zeker dat je deze foto wil verwijderen?",
        confirmText: "Foto verwijderen",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch(
              "soep/deleteProductPicture",
              this.product.pictures[index].uuid
            )
            .then(() => {
              this.$buefy.toast.open("Foto verwijderd.");
            }),
      });
    },
    deleteDroppedFile(index) {
      this.previewImages.splice(index, 1);
      this.droppedFiles.splice(index, 1);
    },
    changePosition(index, direction) {
      const payload = {
        index: index,
        direction: direction,
        pictures: this.product.pictures,
      };
      this.$store.dispatch("soep/changePosition", payload);
    },
    openEditPictureModal(i) {
      this.editPicture = this.droppedFiles[i];
      this.editPictureIndex = i;
    },
    closeCropModal() {
      this.editPicture = null;
      this.editPictureIndex = null;
    },
    croppedPictureUploaded() {
      this.droppedFiles.splice(this.editPictureIndex, 1);
      this.previewImages.splice(this.editPictureIndex, 1);
      this.editPicture = null;
      this.editPictureIndex = null;
    },
    update() {
      this.$store.dispatch("soep/updateProduct", this.form).then((response) => {
        if (response.data.slug === this.$route.params.product) {
          this.$emit("closeModal");
        } else {
          this.$router.replace({
            name: "admin-product",
            params: { product: response.data.slug },
          });
          this.$emit("closeModal");
        }
      });
    },
  },
  watch: {
    droppedFiles: {
      deep: false,
      handler() {
        const length = this.droppedFiles.length;

        if (length === 0) {
          this.$emit("UnsavedPictures", false);
        } else {
          this.$emit("UnsavedPictures", true);
        }
      },
    },
  },
  mounted() {},
};
</script>
