import state from "../state";

export const index = function (state) {
  return state.index;
};

export const gekozen_bestellings_dagen = function (state) {
  const products = state.index;
  const besteldagen = [];

  for (let product of products) {
    for (let besteldag of product.besteldagen) {
      for (let gekozen_product of besteldag.products) {
        if (gekozen_product.gekozen_hoeveelheid > 0) {
          let dag = besteldagen.find((a) => a.id === besteldag.id);
          if (dag) {
            dag.gekozen_producten.push(gekozen_product);
          } else {
            let dag = besteldag;
            dag.gekozen_producten = [];
            dag.gekozen_producten.push(gekozen_product);
            besteldagen.push(dag);
          }
        }
      }
    }
  }
  return besteldagen;
};
