<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Huis en Haard</div>
          Tijdens de werkplaats Huis en Haard worden er diverse alledaagse vaardigheden geleerd. Van leren schoonmaken tot het ophangen 
          van een plankje of het verwisselen van een lampje. Daarnaast wordt er in deze werkplaats geregeld op uitgetrokken 
          om de maatschappij te bezoeken: van het leren werken op een spinnenwiel door de buurman uit de straat 
          tot het leren herkennen van eetbare paddestoelen in het bos. 

          <br> 
          <br>

        </div>
      </div>
      <div class="column">
        <carrousel :page="'werkplaatsen_gastaantafel'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
