<template>
  <div>
    <div class="box has-text-centered">
      <span
        class="content"
        v-html="findVariable('redirect_after_donation')"
      ></span>
      <br />
      <div class="button is-rounded is-primary" @click="returnToHomePagina()">
        Naar Homepagina
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      interval: "",
      timeTillReturn: 10,
    };
  },
  computed: {
    calculateSeconden() {
      if (this.timeTillReturn === 1) {
        return "seconde";
      } else {
        return "seconden";
      }
    },
  },
  methods: {
    returnToHomePagina() {
      clearInterval(this.interval);
      this.$router.replace({ name: "home" });
    },
    returnToHome() {
      clearInterval(this.interval);
      if (this.$router.currentRoute.name === "contact-formulier-verzonden") {
        this.$router.replace({ name: "home" });
      }
    },
  },
  mounted() {},
};
</script>
