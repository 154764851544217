<template>
  <b-field
    :id="modelKey"
    :label="hoofdletter(computedTitle)"
    :label-position="label"
    :type="{ 'is-danger': validation[computedValidation] }"
    :message="validation[computedValidation]"
  >
    <b-timepicker
      v-on:input="dateChanged()"
      :size="size"
      v-model="date"
      locale="nl-NL"
      editable
      hour-format="24"
      :mobile-native="false"
    >
      <template v-slot:trigger>
        <b-button tabindex="-1" icon-left="clock" type="is-primary" />
      </template>
    </b-timepicker>
    <b-input
      @keyup.native.enter="editDateChanged()"
      @blur="editDateChanged()"
      expanded
      v-model="editDate"
      :placeholder="placeholder"
    />
  </b-field>
</template>

<script>
import { DateTime } from "luxon";

const dateParser = function (date) {
  if (typeof date === "string" && date.length) {
    date = date.replaceAll("-", ":");
    date = date.replaceAll("/", ":");
    date = date.replaceAll(":", "");
  }

  if (!date) {
    return null;
  }

  if (date.length > 4 || date.length < 3) {
    return null;
  }

  if (date.length === 3) {
    date = "0" + date;
  }

  // if (dateArray[2].length === 2) {
  //   const short_year = new Date().getFullYear().toString().substr(-2);
  //   const short_year_current_year = new Date().getFullYear();

  //   if (dateArray[2] >= 0 && dateArray[2] <= parseInt(short_year) + 5) {
  //     dateArray[2] = "20" + dateArray[2];
  //   } else {
  //     dateArray[2] = "19" + dateArray[2];
  //   }
  // }

  const newdate = new Date();
  const hours = date.substring(0, 2);

  if (hours < 0 || hours > 23) {
    return null;
  }

  const minutes = date.substring(2, 4);

  if (minutes < 0 || minutes > 59) {
    return null;
  }

  newdate.setHours(parseInt(date.substring(0, 2)));
  newdate.setMinutes(parseInt(date.substring(2, 4)));
  newdate.setSeconds(0);

  return newdate.toISOString();
};

export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    model: {
      required: true,
      type: Object,
    },
    modelKey: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
    },
    validationKey: {
      type: String,
    },
    size: {
      default: "is-normal",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    type: {
      default: "input",
      type: String,
    },
  },
  data() {
    return {
      date: null,
      editDate: null,
    };
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedValidation() {
      if (this.validationKey) {
        return this.validationKey;
      } else {
        return this.modelKey;
      }
    },
    computedDate() {
      return this.model[this.modelKey];
    },
  },
  methods: {
    blur() {
      this.$emit("blur");
    },
    clearValidationError() {
      this.$emit("input");
      this.$store.dispatch("clearValidationError", this.modelKey);
    },
    dateParser(date) {
      return dateParser;
    },
    dateCreator(date) {
      new Date();
    },
    editDateChanged() {
      if (!this.editDate) {
        this.model[this.modelKey] = null;
        this.date = null;
        return;
      }

      const newDate = dateParser(this.editDate);

      if (!newDate) {
        this.editDate = null;
        return;
      }

      const newDateObject = new Date(newDate);
      newDateObject.setSeconds(0);
      const newDateBaseString = newDateObject
        .toISOString()
        .replace("T", " ")
        .replace("Z", "")
        .slice(0, -4);

      this.model[this.modelKey] = newDateBaseString;

      this.date = newDateObject;
      this.editDate = this.formatTimePickerEditDateFromDataBase(
        newDateObject.toISOString()
      );
    },
    dateChanged() {
      this.clearValidationError();
      if (this.date instanceof Date) {
        this.date.setSeconds(0);
        const date = this.date
          .toISOString()
          .replace("T", " ")
          .replace("Z", "")
          .slice(0, -4);

        this.model[this.modelKey] = date;
      } else {
        this.model[this.modelKey] = null;
      }

      this.editDate = this.formatTimePickerEditDateFromDataBase(
        this.model[this.modelKey]
      );
    },
  },
  watch: {
    computedDate() {
      if (this.date == null && this.model[this.modelKey]) {
        this.date = this.formatDataBaseDateToJSDate(this.model[this.modelKey]);
      }

      if (this.model[this.modelKey] && this.editDate == null) {
        this.editDate = this.formatTimePickerEditDateFromDataBase(
          this.model[this.modelKey]
        );
      }
    },
  },
  mounted() {
    this.editDate = this.formatTimePickerEditDateFromDataBase(
      this.model[this.modelKey]
    );
    this.date = this.formatDataBaseDateToJSDate(this.model[this.modelKey]);
  },
};
</script>
