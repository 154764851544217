<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Blokkade toevoegen</div>
    <form @submit.prevent="toevoegen()">
      <br />
      <br />
      <formInput
        class="customInput"
        :model="form"
        modelKey="postcode"
        label="on-border"
        placeholder="1234AA"
        @blur="findAddressFromPostalCode(form.postcode, form.huisnummer, form)"
      />

      <formInput
        class="customInput"
        :model="form"
        modelKey="huisnummer"
        label="on-border"
        placeholder="4"
        @blur="findAddressFromPostalCode(form.postcode, form.huisnummer, form)"
      />

      <div v-if="form.straat">
        <p class="help">{{ form.straat }}, {{ form.plaats }}</p>
        <br />
      </div>

      <formInput
        class="customInput"
        :model="form"
        modelKey="email"
        label="on-border"
        placeholder="xxxx@yyyyy.nl"
      />

      <formInput
        class="customInput"
        :model="form"
        modelKey="omschrijving"
        label="on-border"
        placeholder="Waarom blokkade?"
      />

      <b-button
        native-type="submit"
        :loading="ajaxloading.create_blacklist"
        type="is-primary is-rounded"
        >Toevoegen</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      all_house_numbers: true,
      form: {
        id: 0,
        status: true,
      },
    };
  },
  computed: {},
  methods: {
    toevoegen() {
      if (this.all_house_numbers) {
        this.form.huisnummer_begin = null;
        this.form.huisnummer_eind = null;
      }
      this.$store.dispatch("blacklist/create", this.form).then(() => {
        this.$buefy.toast.open("Blokkade aangemaakt");
        this.$router.push({ name: "blacklist-index" });
      });
    },
  },
  mounted() {},
};
</script>
