<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="users-title">Blacklist</div>
    <div class="block table-icons">
      <b-tooltip label="Blokkade toevoegen" position="is-top">
        <span class="pointer table-icon" @click="toevoegen()">
          <b-icon icon="plus"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip v-if="selected" label="Blokkade aanpassen" position="is-top">
        <span class="pointer table-icon" @click="aanpassen()">
          <b-icon icon="edit"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip v-if="selected" label="Blokkade verwijderen" position="is-top">
        <span class="pointer table-icon" @click="verwijderen()">
          <b-icon icon="trash"></b-icon>
        </span>
      </b-tooltip>
    </div>

    <br />
    <br />
    <div>
      <b-table
        :data="blacklist"
        :bordered="true"
        :striped="true"
        :hoverable="true"
        :loading="ajaxloading.get_blacklist"
        :focusable="false"
        :mobile-cards="false"
        :selected.sync="selected"
        @dblclick="aanpassen()"
      >
        <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column label="Geblokkeerd door" v-slot="props">
          {{ props.row.user.name }}
        </b-table-column>

        <b-table-column label="Postcode" v-slot="props">
          {{ props.row.postcode }}
        </b-table-column>

        <b-table-column label="Huisnummer" v-slot="props">
          {{ props.row.huisnummer }}
        </b-table-column>

        <b-table-column label="Email" v-slot="props">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column field="name" label="Omschrijving" v-slot="props">
          {{ props.row.omschrijving }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    blacklist() {
      return this.$store.getters["blacklist/blacklist"];
    },
  },
  methods: {
    toevoegen() {
      this.$router.push({ name: "blacklist-toevoegen" });
    },
    aanpassen() {
      this.$router.push({
        name: "blacklist-aanpassen",
        params: { blacklist: this.selected.id },
      });
    },
    verwijderen() {
      this.$buefy.dialog.confirm({
        title: "Blacklist item verwijderen",
        message: "Weet u zeker dat u deze blokkade wil verwijderen?",
        confirmText: "Verwijderen",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("blacklist/destroy", this.selected).then(() => {
            this.selected = null;
            this.$buefy.toast.open("Blokkade verwijderd");
          }),
      });
    },
  },
  mounted() {},
};
</script>
