var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-field',{attrs:{"label":_vm.hoofdletter(_vm.computedTitle),"label-position":_vm.label,"type":{ 'is-danger': _vm.validation[_vm.modelKey] },"message":_vm.validation[_vm.modelKey]}}),_c('div',{},[_c('div',{staticClass:"editor-menubar has-text-centered"},[_c('button',{staticClass:"editor-menu-button",class:{ 'selected-menu-button-option': _vm.editor.isActive('bold') },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleBold().run()}}},[_c('b-icon',{attrs:{"icon":"bold","size":"is-normal"}})],1),_c('button',{staticClass:"editor-menu-button",class:{ 'selected-menu-button-option': _vm.editor.isActive('italic') },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleItalic().run()}}},[_c('b-icon',{attrs:{"icon":"italic","size":"is-normal"}})],1),_c('button',{staticClass:"editor-menu-button",class:{ 'selected-menu-button-option': _vm.editor.isActive('strike') },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleStrike().run()}}},[_c('b-icon',{attrs:{"icon":"strikethrough","size":"is-normal"}})],1),_c('button',{staticClass:"editor-menu-button",class:{
          'selected-menu-button-option': _vm.editor.isActive('underline'),
        },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleUnderline().run()}}},[_c('b-icon',{attrs:{"icon":"underline","size":"is-normal"}})],1),_vm._v("   "),_c('button',{staticClass:"editor-menu-button",class:{
          'selected-menu-button-option': _vm.editor.isActive({
            textAlign: 'left',
          }),
        },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().setTextAlign('left').run()}}},[_c('b-icon',{attrs:{"icon":"align-left","size":"is-normal"}})],1),_c('button',{staticClass:"editor-menu-button",class:{
          'selected-menu-button-option': _vm.editor.isActive({
            textAlign: 'center',
          }),
        },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().setTextAlign('center').run()}}},[_c('b-icon',{attrs:{"icon":"align-center","size":"is-normal"}})],1),_c('button',{staticClass:"editor-menu-button",class:{
          'selected-menu-button-option': _vm.editor.isActive({
            textAlign: 'right',
          }),
        },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().setTextAlign('right').run()}}},[_c('b-icon',{attrs:{"icon":"align-right","size":"is-normal"}})],1),_vm._v("   "),_c('button',{staticClass:"editor-menu-button",class:{
          'selected-menu-button-option': _vm.editor.isActive('heading', {
            level: 1,
          }),
        },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleHeading({ level: 1 }).run()}}},[_c('b-icon',{attrs:{"icon":"heading","size":"is-normal"}}),_vm._v("1 ")],1),_c('button',{staticClass:"editor-menu-button",class:{
          'selected-menu-button-option': _vm.editor.isActive('heading', {
            level: 2,
          }),
        },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleHeading({ level: 2 }).run()}}},[_c('b-icon',{attrs:{"icon":"heading","size":"is-normal"}}),_vm._v("2 ")],1),_c('button',{staticClass:"editor-menu-button",class:{
          'selected-menu-button-option': _vm.editor.isActive('heading', {
            level: 3,
          }),
        },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleHeading({ level: 3 }).run()}}},[_c('b-icon',{attrs:{"icon":"heading","size":"is-normal"}}),_vm._v("3 ")],1),_vm._v("   "),_c('button',{staticClass:"editor-menu-button",class:{
          'selected-menu-button-option': _vm.editor.isActive('bulletList'),
        },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleBulletList().run()}}},[_c('b-icon',{attrs:{"icon":"list-ul","size":"is-normal"}})],1),_c('button',{staticClass:"editor-menu-button",class:{
          'selected-menu-button-option': _vm.editor.isActive('orderedList'),
        },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleOrderedList().run()}}},[_c('b-icon',{attrs:{"icon":"list-ol","size":"is-normal"}})],1),_vm._v("   "),_c('button',{staticClass:"editor-menu-button",class:{
          'selected-menu-button-option': _vm.editor.isActive('blockquote'),
        },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleBlockquote().run()}}},[_c('b-icon',{attrs:{"icon":"quote-left","size":"is-normal"}})],1),_vm._v("   "),_c('b-tooltip',{attrs:{"label":"Add link to selected text"}},[_c('button',{staticClass:"editor-menu-button",class:{
            'selected-menu-button-option': _vm.editor.isActive('bulletList'),
          },on:{"click":function($event){$event.preventDefault();return _vm.askForHyperLink()}}},[_c('b-icon',{attrs:{"icon":"link","size":"is-normal"}})],1)]),_c('b-tooltip',{attrs:{"label":"Remove link from selected text"}},[_c('button',{staticClass:"editor-menu-button",class:{
            'selected-menu-button-option': _vm.editor.isActive('orderedList'),
          },on:{"click":function($event){$event.preventDefault();return _vm.editor.commands.unsetLink()}}},[_c('b-icon',{attrs:{"icon":"unlink","size":"is-normal"}})],1)]),_vm._v("   "),_c('button',{staticClass:"editor-menu-button",on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().setHorizontalRule().run()}}},[_c('b-icon',{attrs:{"icon":"minus","size":"is-normal"}})],1),_vm._v("   "),_c('button',{staticClass:"editor-menu-button",on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().undo().run()}}},[_c('b-icon',{attrs:{"icon":"undo","size":"is-normal"}})],1),_c('button',{staticClass:"editor-menu-button",on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().redo().run()}}},[_c('b-icon',{attrs:{"icon":"redo","size":"is-normal"}})],1)],1),_c('editor-content',{staticClass:"content",attrs:{"editor":_vm.editor}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }