<style scoped></style>

<template>
  <div>
    <quote quote="Samen wonen, samen werken, samen leren,  samen doen"></quote>
    <br />
    <div class="columns">
      <div class="column">
        <div class="box">
          Het Dagelijks Bestaan is voor iedereen en vooral voor jongeren tussen
          de 14 en 27 jaar. Bij ons leer je werken met je eigen levensvragen.
          “Wie ben ik? Wat zijn mijn talenten? Wat wil ik?” Met deze, en nog
          veel meer vragen, wordt er gewerkt binnen ons.
          <a @click="$router.push({ name: 'dagbesteding' })"
            >ontwikkelprogramma (dagprogramma)</a
          >
          en het
          <a @click="$router.push({ name: 'beschermd-wonen' })"
            >beschermd wonen</a
          >.
          <br />
          <br />
          Wil jij ook aansluiten bij Het Dagelijks Bestaan? Naast onze
          dagprogramma en beschermd wonen hebben wij ook geregeld plek voor
          <a @click="$router.push({ name: 'vrijwilligers' })">vrijwilligers</a>,
          <a @click="$router.push({ name: 'stage-lopen' })">stagiaires</a> en
          <a @click="$router.push({ name: 'woongemeenschap' })">bewoners</a>
          voor in de woongemeenschap.
        </div>
        <div class="tile is-ancestor">
          <div class="tile is-vertical is-6">
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <!-- <article class="tile is-child notification box">
                  <p class="title is-5">Actualiteiten</p>
                  Binnenkort komt sinterklaas bij Het Dagelijks Bestaan, wij
                  spelen ieder jaar het dobbel weggeef spel
                </article> -->
                <article class="tile is-child notification box">
                  <p class="title is-5">Dagelijkse soep</p>
                  <span
                    class="content"
                    v-html="findVariable('home_dagelijksesoep_tekst')"
                  >
                  </span>
                  <a
                    @click="$router.push({ name: 'dagelijkse-soep-landing' })"
                    class="button is-small is-primary is-fullwidth is-rounded"
                    >Klik hier voor meer informatie</a
                  >
                </article>
              </div>
            </div>
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <article class="tile is-child notification box">
                  <p class="title is-5">Stageplekken</p>
                  <span
                    class="content"
                    v-html="findVariable('home_stageplekken_tekst')"
                  >
                  </span>
                  <a
                    @click="$router.push({ name: 'stage-lopen' })"
                    class="button is-small is-primary is-fullwidth is-rounded"
                    >Klik hier voor meer informatie</a
                  >
                </article>
              </div>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child notification box">
                <p class="title is-5">Doneren</p>
                <span
                  class="content"
                  v-html="findVariable('home_donatie_tekst')"
                >
                </span>
                <a
                  @click="$router.push({ name: 'donaties' })"
                  class="button is-small is-primary is-fullwidth is-rounded"
                  >Klik hier om te doneren</a
                >
              </article>
            </div>
          </div>
          <div class="tile is-vertical is-6">
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <!-- <article class="tile is-child notification box">
                  <p class="title is-5">Actualiteiten</p>
                  Binnenkort komt sinterklaas bij Het Dagelijks Bestaan, wij
                  spelen ieder jaar het dobbel weggeef spel
                </article> -->
                <article class="tile is-child notification box">
                  <div class="content">
                    <p class="title is-5">Instagram/Facebook</p>
                    Volg ons op
                    <a href="https://www.instagram.com/hetdagelijksbestaan"
                      ><img
                        src="@/assets/pics/instagram.png"
                        width="16"
                        height="16"
                      />&nbsp;Instagram</a
                    ><br />
                    of &nbsp;
                    <a href="https://www.facebook.com/hetdagelijksbestaan/"
                      ><img
                        src="@/assets/pics/iconFacebook.svg"
                        width="16"
                        height="16"
                      />&nbsp;Facebook</a
                    >
                  </div>
                </article>
              </div>
            </div>
            <div class="tile is-parent is-vertical">
              <article class="tile is-child notification box">
                <p class="title is-5">Wachtlijst</p>
                <span
                  class="content"
                  v-html="findVariable('home_wachtlijst_tekst')"
                >
                </span>
              </article>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div class="column">
        <div style="width: 100%">
          <carrousel :page="'home'" />
        </div>
      </div>
    </div>

    <br />
    <br />
  </div>
</template>

<script>
import carrousel from "@/views/Components/Carrousel.vue";
import quote from "@/views/Components/Quote.vue";

export default {
  components: {
    carrousel,
    quote,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
