var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"navbar is-primary",attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"navbar-brand"},[_c('a'),_c('a',{staticClass:"navbar-burger",attrs:{"role":"button","aria-label":"menu","aria-expanded":"true"},on:{"click":function($event){return _vm.toggleMobile()}}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])]),_c('div',{class:['navbar-menu', { 'is-active': _vm.showMobile }]},[_c('div',{staticClass:"navbar-start"},[_c('singleLink',{attrs:{"link":{ naam: 'Home', routerLink: 'home', exact: true }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('dropDownLink',{attrs:{"showMobileParent":_vm.showMobile,"link":{
              naam: 'Over ons',
              routerLink: 'over-ons',
              exact: true,
              children: [
                {
                  naam: 'Algemeen',
                  routerLink: 'over-ons',
                  exact: true,
                },
                {
                  naam: 'Visie',
                  routerLink: 'visie',
                },
                {
                  naam: 'Geschiedenis',
                  routerLink: 'geschiedenis',
                },
                {
                  naam: 'Grondsteenspreuk',
                  routerLink: 'grondsteenspreuk',
                },

                {
                  naam: 'Organisatie',
                  routerLink: 'zakelijke-informatie',
                } ],
            }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('singleLink',{attrs:{"link":{ naam: 'Dagprogramma', routerLink: 'dagbesteding' }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('singleLink',{attrs:{"link":{ naam: 'Beschermd Wonen', routerLink: 'beschermd-wonen' }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('dropDownLink',{attrs:{"showMobileParent":_vm.showMobile,"link":{
              naam: 'Werkplaatsen',
              routerLink: 'werkplaatsen',
              exact: true,
              children: [
                {
                  naam: 'Alle werkplaatsen',
                  routerLink: 'werkplaatsen',
                  exact: true,
                },
                {
                  naam: 'Sport & spel',
                  routerLink: 'sport-en-spel',
                },
                {
                  naam: 'Het leerhuis',
                  routerLink: 'het-leerhuis',
                },
                {
                  naam: 'Horeca',
                  routerLink: 'horeca',
                },
                {
                  naam: 'Buiten',
                  routerLink: 'buiten',
                },
                {
                  naam: 'Kunst',
                  routerLink: 'kunst',
                },
                {
                  naam: 'Huis en haard',
                  routerLink: 'gast-aan-tafel',
                } ],
            }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('singleLink',{attrs:{"link":{
              naam: 'Dagelijkse soep',
              routerLink: 'dagelijkse-soep-landing',
            }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('dropDownLink',{attrs:{"showMobileParent":_vm.showMobile,"link":{
              naam: 'Meedoen',
              routerLink: 'meedoen',
              exact: true,
              children: [
                {
                  naam: 'Doe mee',
                  routerLink: 'meedoen',
                  exact: true,
                },
                {
                  naam: 'Werken bij',
                  routerLink: 'werken-bij',
                },
                {
                  naam: 'Stage lopen',
                  routerLink: 'stage-lopen',
                },
                {
                  naam: 'Vrijwilligers',
                  routerLink: 'vrijwilligers',
                },
                {
                  naam: 'Woongemeenschap',
                  routerLink: 'woongemeenschap',
                },
                {
                  naam: 'Donaties',
                  routerLink: 'donaties',
                },
                {
                  naam: 'Vrienden van',
                  routerLink: 'vrienden-van',
                } ],
            }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('singleLink',{attrs:{"link":{ naam: 'Contact', routerLink: 'contact' }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),(_vm.user)?_c('singleLink',{attrs:{"link":{ naam: 'Admin', routerLink: 'admin' }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }