import Vue from "vue";
import store from "@/store";

const auth = Vue.mixin({
  computed: {
    user() {
      return store.getters["auth/user"];
    },
    settings() {
      return store.getters["settings"];
    },
    adminSettings() {
      return store.getters["auth/adminSettings"];
    },
  },
  methods: {
    findRawSetting(key) {
      if (this.settings && this.settings.variables) {
        const variable = this.settings.variables.find((a) => a.key === key);
        var returnValue = "";
        if (variable) {
          returnValue = variable;
        }
        return returnValue;
      }
    },
    findSetting(key) {
      const variable = this.findRawSetting(key);
      if (variable) {
        return variable.value;
      } else {
        return "";
      }
    },

    findVariable(key) {
      return this.findSetting(key);
    },
  },
});

export default auth;
