<style scoped>
.column {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pointer {
  margin-left: 5px;
}
</style>

<template>
  <div>
    <button
      class="button is-small is-rounded"
      @click="$router.push({ name: 'soep-index' })"
    >
      Terugkeren
    </button>
    <br />
    <br />
    <div class=""><span class="title is-5">Product: </span> &nbsp;</div>

    <br />

    <span v-if="product">
      <div class="columns is-mobile">
        <div class="column is-3">Naam:</div>
        <div class="column">
          {{ product.naam }}
        </div>
        <div class="column">
          <b-tooltip
            class="pointer is-pulled-right"
            label="Product verwijderen"
            @click.native="deleteProduct()"
          >
            <b-icon
              class="pointer is-pulled-right"
              icon="trash"
              size="is-small"
            />
          </b-tooltip>
          <b-tooltip
            class="pointer is-pulled-right"
            label="Product aanpassen"
            @click.native="
              $router.push({
                name: 'product-aanpassen',
                params: { product: product.id },
              })
            "
          >
            <b-icon icon="edit" size="is-small" />
          </b-tooltip>
        </div>
      </div>

      <div class="columns is-mobile">
        <div class="column is-3">Status:</div>
        <div class="column">
          {{ product.status }}
        </div>
      </div>

      <hr />

      <div class="columns is-mobile">
        <div class="column is-3">Prijs:</div>
        <div class="column">
          {{ euro(product.prijs) }}
        </div>
      </div>

      <div class="columns is-mobile">
        <div class="column is-3">Korting:</div>
        <div class="column">
          {{ euro(product.korting) }}
        </div>
      </div>

      <div class="columns is-mobile" v-if="product.prijs && product.korting">
        <div class="column is-3">Totaal:</div>
        <div class="column">
          {{
            euro(
              replaceCommaWithDot(product.prijs) -
                replaceCommaWithDot(product.korting)
            )
          }}
        </div>
      </div>

      <hr />

      <div class="columns">
        <div class="column is-6">
          <b-carousel
            indicator-custom
            :indicator-inside="false"
            :autoplay="false"
          >
            <b-carousel-item v-for="(item, i) in product.pictures" :key="i">
              <b-image
                ratio="1by1"
                class="image"
                :src="
                  env.VUE_APP_API_URL +
                  '/web/picture/' +
                  product.pictures[i].uuid
                "
              ></b-image>
            </b-carousel-item>
            <template #indicators="props">
              <b-image
                v-if="product.pictures"
                class="al image"
                :src="
                  env.VUE_APP_API_URL +
                  '/web/picture/' +
                  product.pictures[props.i].uuid
                "
                :title="props.i"
              ></b-image>
            </template>
          </b-carousel>
        </div>

        <div class="column is-6">
          <div class="content" v-html="product.omschrijving"></div>
        </div>
      </div>
    </span>

    <editPictureModal
      v-on:closeModal="showEditPictureModal = false"
      :updateForm="product"
      v-if="showEditPictureModal"
    />
  </div>
</template>

<script>
import editPictureModal from "./AdminProductEditPictures.vue";

export default {
  components: {
    editPictureModal,
  },
  props: [],
  data() {
    return {
      env: process.env,
      showEditModal: false,
      showEditPictureModal: false,
    };
  },
  computed: {
    products() {
      return this.$store.getters["soep/index"];
    },
    product() {
      const product = this.products.find(
        (a) => a.id == this.$router.currentRoute.params.product
      );

      if (product && product.pictures) {
        product.pictures.sort((a, b) => a.position - b.position);
      }

      if (product) {
        return product;
      }

      return {};
    },
  },
  methods: {
    deleteProduct() {
      this.$buefy.dialog.confirm({
        title: "Product verwijderen",
        message: "Weet je zeker dat je dit product wil verwijderen?.",
        confirmText: "Product verwijderen",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("soep/destroy", this.product).then(() => {
            this.$buefy.toast.open("Product verwijderd.");
            this.$router.go(-1);
          }),
      });
    },
  },
  mounted() {
    if (!this.product) {
      this.$router.go(-1);
    }
  },
};
</script>
