<style scoped>
.customTitle {
  font-weight: bold;
}

.customBox {
  border-style: solid;
}
</style>

<template>
  <div>
    <quote quote="Altijd blijven dromen"></quote>
    <br />
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Donaties</div>
          De mooie ideeën die we hebben voor onze stichting en ons prachtige
          terrein kosten -helaas- altijd geld. Zo dromen we van nog een dak vol
          zonnepanelen om ons terrein verder te verduurzamen. We dromen van de
          wederopbouw van onze Kas. We dromen van een kunstatelier met een mooie
          keramiekoven. We dromen van een prachtige houtwerkplaats. We dromen
          van nog meer mooie, biologische planten en bomen in de tuin. <br />
          <br />
          Daarnaast zijn wij dit jaar begonnen met ons programma:
          <router-link tag="a" :to="{ name: 'dagelijkse-soep-landing' }"
            >"De dagelijkse soep"</router-link
          >. We worden hiervoor gesteund vanuit de gemeente Zutphen met het
          project:
          <a href="https://zutphen.nl/warmdewinterdoor"
            >"Samen warm de winter door"</a
          >. Elke euro die wij extra ophalen kan worden aangewend om voor nog
          meer mensen in deze spannende en moeilijke tijd een warme maaltijd te
          kunnen verzorgen. <br />
          <br />
          Daarom zijn wij blij met iedere donatie! <br />
          <br />
          Wil jij of jouw bedrijf financieel bijdragen aan het verwezenlijken
          van deze dromen en projecten? <br />
          Klik dan op onderstaande knop om de donatie te starten.<br />
          <br />
          <br />

          <div class="has-text-centered">
            <button
              @click="$router.push({ name: 'doneren' })"
              class="button is-rounded is-primary"
            >
              Donatie starten
            </button>
          </div>
          <br />
          <br />
          Let op: Het Dagelijks Bestaan is een ANBI-instelling. Hierdoor kan
          jouw gift fiscaal aftrekbaar zijn. <br />
          <a
            href="https://www.belastingdienst.nl/wps/wcm/connect/nl/aftrek-en-kortingen/content/gift-aftrekken#:~:text=Ik%20geef%20aan%20een%20goed,sinds%202021%20niet%20meer%20aftrekbaar."
            >Klik hier om hier meer over te lezen.</a
          >
        </div>
      </div>

      <div class="column">
        <carrousel :page="'meedoen_donaties'" />
      </div>
    </div>
  </div>
</template>

<script>
import quote from "@/views/Components/Quote.vue";
export default {
  components: {
    quote,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
