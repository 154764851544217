import Vue from "vue";

export const loadingOn = function (state, payload) {
  Vue.set(state.loading, payload, true);
};

export const loadingOff = function (state, payload) {
  Vue.delete(state.loading, payload);
};

export const setValidationErrors = function (state, payload) {
  state.valerrors = payload;
};

export const setFlashBanner = function (state, payload) {
  state.flashBanner = payload;
};

export const setPictures = function (state, payload) {
  state.pictures = payload;
};

export const setSettings = function (state, payload) {
  state.settings = payload;
};

export const deletePicture = function (state, payload) {
  const array = state.pictures;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};

export const clearValidationError = function (state, payload) {
  if (state.valerrors[payload]) {
    state.valerrors[payload] = null;
  }
};

export const setUser = function (state, payload) {
  state.user = payload;
};
