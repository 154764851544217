<template>
  <div>
    <div class="columns">
      <div class="column is-6 content" v-html="vacatureText"></div>
      <div class="column">
        <carrousel :page="'meedoen_vacature'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      vacature_index: null,
    };
  },
  computed: {
    vacatureText() {
      if (this.vacature_index === null) {
        return "Geen vacature gevonden";
      }

      const text = this.findRawSetting("vacature_tekst_" + this.vacature_index);
      const zichtbaar = this.findRawSetting(
        "vacature_zichtbaar_" + this.vacature_index
      );

      if (zichtbaar && zichtbaar.value == 1 && text && text.value) {
        return text.value;
      }

      return "Geen vacature gevonden";
    },
  },
  methods: {
    findVacatureIndex() {
      if (this.settings && this.settings.variables) {
        const route_key = this.$route.params.vacature;
        const variable_link = this.settings.variables.find(
          (a) => a.value == route_key
        );
        if (variable_link) {
          var vacature_id = variable_link.key.substr(
            variable_link.key.length - 1
          );
        }

        this.vacature_index = vacature_id;
      }
    },
  },
  watch: {
    settings: {
      deep: true,
      handler() {
        if (this.settings && this.settings.variables) {
          this.findVacatureIndex();
        }
      },
    },
  },
  mounted() {
    if (!this.$route.params.vacature) {
      this.$router.go(-1);
    }

    this.findVacatureIndex();
  },
};
</script>
