export const setIndex = function (state, payload) {
  state.index = payload;
};

export const setNewItem = function (state, payload) {
  state.index.push(payload);
};

export const updateItem = function (state, payload) {
  const array = state.index;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};

export const deleteItem = function (state, payload) {
  const array = state.index;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};
