<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.name_columns {
  margin: 0px;
  padding: 0px;
}

.name_column {
  margin: 0px;
  padding: 0px;
}

.customInput {
  margin-bottom: 10px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Bestelling toevoegen</div>
    <br />
    <form @submit.prevent="aanmaken()">
      <hr />

      <b-field>
        <b-radio v-model="form.klant_soort" native-value="bestaand">
          Bestaande klant
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="form.klant_soort" native-value="nieuw">
          Nieuwe klant
        </b-radio>
      </b-field>

      <span v-if="form.klant_soort === 'bestaand'">
        <b-field
          :type="{ 'is-danger': validation.customer_id }"
          :message="validation.customer_id"
        >
          <formAutocomplete
            title=" "
            :model="klant"
            placeholder="Klant zoeken..."
            modelKey="zoekwaarde"
            :options="klanten"
            optionKey="searchstring"
            notFoundText="Geen klanten gevonden"
            :loading="klant.loading"
            v-on:input="searchCustomers"
            v-on:selected="setCustomer"
          />
        </b-field>
        <br />
        <br />
      </span>

      <div class="title is-5">Klant gegevens</div>

      <span v-if="form.klant_soort === 'nieuw' || klant_gevonden === true">
        <span class="columns name_columns">
          <span class="column name_column">
            <formInput
              class="customInput"
              :model="form"
              modelKey="voornaam"
              label="on-border"
              placeholder="Julia"
          /></span>
          <span class="column name_column">
            <formInput
              class="customInput"
              :model="form"
              modelKey="achternaam"
              label="on-border"
              placeholder="de Jong"
          /></span>
        </span>

        <formInput
          class="customInput"
          :model="form"
          modelKey="email"
          label="on-border"
          placeholder="julia@gmail.com"
        />

        <formInput
          class="customInput"
          :model="form"
          modelKey="telefoonnummer"
          label="on-border"
          placeholder="0575529222"
        />

        <formInput
          class="customInput"
          :model="form"
          modelKey="postcode"
          label="on-border"
          placeholder="7204LA"
          @blur="findAddress()"
        />

        <formInput
          class="customInput"
          :model="form"
          modelKey="huisnummer"
          label="on-border"
          placeholder="47"
          @blur="findAddress()"
        />

        <formInput
          :loading="addressLoading"
          class="customInput"
          :model="form"
          :disabled="editStraat === false"
          modelKey="straat"
          label="on-border"
          placeholder="Weg naar Vierakker"
        />

        <formInput
          :loading="addressLoading"
          class="customInput"
          :model="form"
          modelKey="plaats"
          label="on-border"
          :disabled="editStraat === false"
          placeholder="Zutphen"
        />
      </span>

      <b-field
        label="Opmerking"
        :type="{ 'is-danger': validation.opmerking }"
        :message="{
          [validation.opmerking]: validation.opmerking,
        }"
      >
        <b-input
          v-model="form.opmerking"
          type="textarea"
          maxlength="1000"
          placeholder="Eventuele vragen of opmerkingen voor de bezorgers kunt u hier kwijt"
          @input="clearValidationError('opmerking')"
        ></b-input>
      </b-field>
      <br />

      <div class="title is-5">Bezorgopties</div>
      <b-field v-if="findVariable('order_pickup_enabled')">
        <b-radio v-model="form.bezorg_optie" native-value="ophalen">
          Ophalen
        </b-radio>
      </b-field>
      <b-field
        v-if="findVariable('order_shipping_enabled')"
        :type="{ 'is-danger': validation['bezorg_optie'] }"
        :message="validation['bezorg_optie']"
      >
        <b-radio v-model="form.bezorg_optie" native-value="bezorgen">
          Thuisbezorgen
        </b-radio>
      </b-field>

      <br />

      <div class="title is-5">Bezorgdagen / producten</div>

      <span v-if="form.products">
        <span v-for="product in form.products.length" :key="product">
          <!-- new product -->
          <span v-if="form.products[product - 1].localId">
            <div class="columns">
              <div class="column">
                <formSelect
                  class="customInput"
                  modelKey="orderday"
                  label="on-border"
                  :model="form.products[product - 1]"
                  placeholder="Selecteer dag"
                  :selectOptions="bestellingsdagen"
                  v-on:change="
                    form.products[product - 1].orderday_product = null
                  "
                  :formatDate="true"
                  selectKey="bestellingsdag"
                  :validationKey="'products.' + (product - 1) + '.orderday'"
                  :extraValidationKey="'products.' + (product - 1) + '.naam.id'"
                />
              </div>
              <div class="column">
                <formSelect
                  v-if="form.products[product - 1].orderday"
                  class="customInput"
                  modelKey="orderday_product"
                  label="on-border"
                  v-on:change="form.products[product - 1].hoeveelheid = 0"
                  :model="form.products[product - 1]"
                  placeholder="Selecteer product"
                  :selectOptions="form.products[product - 1].orderday.products"
                  selectKey="naam"
                  :validationKey="
                    'products.' + (product - 1) + '.orderday_product.id'
                  "
                />
                <span v-else> Kies een bestellingsdag </span>
              </div>
              <div class="column">
                <b-field
                  v-if="form.products[product - 1].orderday_product"
                  :type="{
                    'is-danger':
                      validation['products.' + (product - 1) + '.hoeveelheid'],
                  }"
                  :message="numberInputMessage(product)"
                >
                  <b-numberinput
                    :editable="false"
                    controls-rounded
                    :min="0"
                    size="is-small"
                    v-model="form.products[product - 1].hoeveelheid"
                    controls-position="compact"
                    :max="
                      calculateMaxToOrder(
                        form.products[product - 1].orderday,
                        form.products[product - 1].orderday_product
                      )
                    "
                  >
                  </b-numberinput>
                </b-field>
              </div>

              <div class="column">
                <div
                  @click="removeProduct(form.products[product - 1])"
                  class="button is-danger is-outlined is-fullwidth"
                >
                  Verwijderen
                </div>
              </div>
            </div>
          </span>
          <!-- database products -->
          <span v-else>
            <div class="columns">
              <div class="column">
                {{
                  formatDatabaseDateShort(
                    form.products[product - 1].orderday.bestellingsdag
                  )
                }}
              </div>
              <div class="column">
                {{ form.products[product - 1].orderday_product.naam }}
              </div>
              <div class="column">
                <b-field
                  :type="{
                    'is-danger':
                      validation['products.' + (product - 1) + '.hoeveelheid'],
                  }"
                  :message="numberInputMessage(product)"
                >
                  <b-numberinput
                    :editable="false"
                    controls-rounded
                    :min="0"
                    size="is-small"
                    v-model="form.products[product - 1].hoeveelheid"
                    controls-position="compact"
                    :max="
                      calculateMaxToOrder(
                        form.products[product - 1].orderday,
                        form.products[product - 1].orderday_product
                      )
                    "
                  >
                  </b-numberinput>
                </b-field>
              </div>

              <div class="column">
                <div
                  @click="removeProduct(form.products[product - 1])"
                  class="button is-danger is-outlined is-fullwidth"
                >
                  Verwijderen
                </div>
              </div>
            </div>
          </span>
        </span>
      </span>

      <hr />

      <div
        @click="
          form.products.push({
            localId: form.products.length + 1,
            orderday: null,
            orderday_product: null,
            hoeveelheid: 0,
          })
        "
        class="button is-primary is-fullwidth"
      >
        Product toevoegen
      </div>

      <br />

      <b-button
        :disabled="!form.products.length"
        native-type="submit"
        :loading="ajaxloading.create_bestelling"
        type="is-primary is-rounded"
        >Aanmaken</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="cancelUpdate()">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: [],
  data() {
    return {
      editStraat: true,
      addressLoading: false,
      klant_gevonden: false,
      form: {
        klant_soort: "bestaand",

        products: [],
      },
      klant: {
        zoekwaarde: null,
        loading: false,
      },
    };
  },
  computed: {
    bestellingsdagen() {
      return this.$store.getters["bestellingen/besteldagen"];
    },
    klanten() {
      return this.$store.getters["bestellingen/klanten"];
    },
  },
  methods: {
    calculateAvailable(besteldag, product, reeds_besteld, oude_hoeveelheid) {
      const max = this.calculateMaxToOrder(besteldag, product);

      if (oude_hoeveelheid) {
        return max - reeds_besteld + oude_hoeveelheid;
      } else {
        return max - reeds_besteld;
      }
    },
    calculateMaxToOrder(besteldag, product) {
      const totaal_product_beschikbaar = product.hoeveelheid - product.besteld;

      return totaal_product_beschikbaar;
    },
    calcMaxMessage(dag) {
      const available = this.calculateAvailable(dag);

      if (available < 0) {
        return 0;
      } else {
        return available;
      }
    },
    aanmaken() {
      this.form.producten = this.form.products.length;
      this.$store.dispatch("bestellingen/create", this.form).then(() => {
        this.$buefy.toast.open("Bestelling aangemaakt");
        this.$router.push({ name: "bestellingen-index" });
      });
    },
    cancelUpdate() {
      this.$router.go(-1);
    },
    findAddress() {
      if (!this.form.postcode) {
        return;
      }

      if (!this.form.huisnummer) {
        return;
      }
      this.editStraat = false;
      this.addressLoading = true;
      return this.findAddressFromPostalCode(
        this.form.postcode,
        this.form.huisnummer,
        this.form
      ).then(() => {
        this.editStraat = true;
        this.addressLoading = false;
      });
    },
    removeProduct(product) {
      this.removeUnsavedProduct(product);
    },
    numberInputMessage(product) {
      if (this.validation["products." + (product - 1) + ".hoeveelheid"]) {
        return this.validation["products." + (product - 1) + ".hoeveelheid"];
      }
      return (
        this.calculateAvailable(
          this.form.products[product - 1].orderday,
          this.form.products[product - 1].orderday_product,
          this.form.products[product - 1].hoeveelheid,
          this.form.products[product - 1].old_hoeveelheid
        ) + " beschikbaar."
      );
    },
    removeUnsavedProduct(product) {
      const array = this.form.products;
      const id = product.localId;
      const index = array.findIndex((a) => a.localId === id);
      array.splice(index, 1);
    },
    removeSavedProduct(product) {
      const array = this.form.products;
      const id = product.id;
      const index = array.findIndex((a) => a.id === id);
      array.splice(index, 1);
    },
    searchCustomers(zoekwaarde) {
      this.klant.loading = true;
      this.$store
        .dispatch("bestellingen/zoekKlanten", this.klant.zoekwaarde)
        .then(() => {
          this.klant.loading = false;
        })
        .catch(() => {
          this.klant.loading = false;
        });
    },
    setCustomer(customer) {
      this.klant_gevonden = true;

      Vue.set(this.form, "customer_id", customer.id);
      Vue.set(this.form, "voornaam", customer.voornaam);
      Vue.set(this.form, "achternaam", customer.achternaam);
      Vue.set(this.form, "email", customer.email);
      Vue.set(this.form, "telefoonnummer", customer.telefoonnummer);
      Vue.set(this.form, "postcode", customer.postcode);
      Vue.set(this.form, "huisnummer", customer.huisnummer);
      Vue.set(this.form, "straat", customer.straat);
      Vue.set(this.form, "plaats", customer.plaats);
    },
  },
  mounted() {
    this.$store.dispatch("bestellingen/getBesteldagen");
    this.$store.dispatch("bestellingen/getKlanten");
  },
};
</script>
