<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Stageplaatsen</div>
          Vanuit de maatschappelijke betrokkenheid bieden wij stagiaires graag
          de mogelijkheid geven om ervaring op te doen. Daarnaast zijn wij blij
          met hun verfrissende inbreng. Ben jij niet bang om je eigen
          levensvragen tegen te komen? Heb je affiniteit met de
          <a @click="$router.push({ name: 'werkplaatsen' })">werkplaatsen</a>
          van Het Dagelijks Bestaan? Vind je het interessant om niet alleen mee
          te lopen met de ontwikkeling van de jongeren, maar daar ook zelf
          stappen in te zetten? Dan zit je bij ons goed!
          <br />
          <br />
          Zowel instromen in de septemberroute als februariroute is mogelijk.
          Voor de continuïteit en verbinding gaat onze voorkeur uit naar
          studenten die een jaarstage kunnen bieden van 4 dagen per week. Als je
          interesse hebt in een stageplek bij Het Dagelijks Bestaan, kun je een
          motivatiebrief en cv sturen naar
          <a href="mailto:stage@hetdagelijksbestaan.nl"
            >stage@hetdagelijksbestaan.nl</a
          >.
        </div>
      </div>
      <div class="column">
        <carrousel :page="'meedoen_stagiaires'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
