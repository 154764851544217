var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"button is-small is-rounded",on:{"click":function($event){return _vm.$router.push({ name: 'soep-index' })}}},[_vm._v(" Terugkeren ")]),_c('br'),_c('br'),_vm._m(0),_c('br'),(_vm.product)?_c('span',[_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-3"},[_vm._v("Naam:")]),_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.product.naam)+" ")]),_c('div',{staticClass:"column"},[_c('b-tooltip',{staticClass:"pointer is-pulled-right",attrs:{"label":"Product verwijderen"},nativeOn:{"click":function($event){return _vm.deleteProduct()}}},[_c('b-icon',{staticClass:"pointer is-pulled-right",attrs:{"icon":"trash","size":"is-small"}})],1),_c('b-tooltip',{staticClass:"pointer is-pulled-right",attrs:{"label":"Product aanpassen"},nativeOn:{"click":function($event){return _vm.$router.push({
              name: 'product-aanpassen',
              params: { product: _vm.product.id },
            })}}},[_c('b-icon',{attrs:{"icon":"edit","size":"is-small"}})],1)],1)]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-3"},[_vm._v("Status:")]),_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.product.status)+" ")])]),_c('hr'),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-3"},[_vm._v("Prijs:")]),_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.euro(_vm.product.prijs))+" ")])]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-3"},[_vm._v("Korting:")]),_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.euro(_vm.product.korting))+" ")])]),(_vm.product.prijs && _vm.product.korting)?_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-3"},[_vm._v("Totaal:")]),_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.euro( _vm.replaceCommaWithDot(_vm.product.prijs) - _vm.replaceCommaWithDot(_vm.product.korting) ))+" ")])]):_vm._e(),_c('hr'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('b-carousel',{attrs:{"indicator-custom":"","indicator-inside":false,"autoplay":false},scopedSlots:_vm._u([{key:"indicators",fn:function(props){return [(_vm.product.pictures)?_c('b-image',{staticClass:"al image",attrs:{"src":_vm.env.VUE_APP_API_URL +
                '/web/picture/' +
                _vm.product.pictures[props.i].uuid,"title":props.i}}):_vm._e()]}}],null,false,3541745523)},_vm._l((_vm.product.pictures),function(item,i){return _c('b-carousel-item',{key:i},[_c('b-image',{staticClass:"image",attrs:{"ratio":"1by1","src":_vm.env.VUE_APP_API_URL +
                '/web/picture/' +
                _vm.product.pictures[i].uuid}})],1)}),1)],1),_c('div',{staticClass:"column is-6"},[_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.product.omschrijving)}})])])]):_vm._e(),(_vm.showEditPictureModal)?_c('editPictureModal',{attrs:{"updateForm":_vm.product},on:{"closeModal":function($event){_vm.showEditPictureModal = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('span',{staticClass:"title is-5"},[_vm._v("Product: ")]),_vm._v("  ")])}]

export { render, staticRenderFns }