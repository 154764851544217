<template>
  <div>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
