<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="users-title">Klanten</div>
    <br />
    <br />
    <br />
    <b-field>
      <b-input
        placeholder="Klant zoeken..."
        @input="search()"
        v-model="form.zoekwaarde"
      ></b-input>
    </b-field>

    <div class="block table-icons">
      <b-tooltip v-if="selected" label="Klant bekijken" position="is-top">
        <span class="pointer table-icon" @click="show()">
          <b-icon icon="search"></b-icon>
        </span>
      </b-tooltip>
    </div>

    <br />
    <br />
    <div>
      <b-table
        :data="index.data"
        :bordered="true"
        :striped="true"
        :hoverable="true"
        :loading="ajaxloading.get_index"
        :focusable="false"
        :mobile-cards="false"
        :selected.sync="selected"
        paginated
        backend-pagination
        :total="index.total"
        :current-page="index.current_page"
        :per-page="index.per_page"
        @page-change="changePage"
        @dblclick="show()"
      >
        <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column label="Naam" v-slot="props">
          {{ props.row.voornaam }} {{ props.row.achternaam }}
        </b-table-column>

        <b-table-column label="Adres" v-slot="props">
          {{ props.row.straat }} {{ props.row.huisnummer }} <br />
          {{ props.row.postcode }} {{ props.row.plaats }}
        </b-table-column>

        <b-table-column label="Contactgegevens" v-slot="props">
          <a :href="'mailto:' + props.row.email">{{ props.row.email }}</a>
          <br />

          <a :href="'tel:' + props.row.telefoonnummer">{{
            props.row.telefoonnummer
          }}</a>
        </b-table-column>

        <b-table-column label="Vaste klant" v-slot="props">
          <span v-if="props.row.vaste_klant"> ja </span>
        </b-table-column>

        <b-table-column label="Bestellingen" v-slot="props">
          {{ props.row.orders.length }}
        </b-table-column>

        <b-table-column label="Donaties" v-slot="props">
          {{ props.row.donations.length }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected: null,
      form: {},
      found_objects: null,
    };
  },
  computed: {
    index() {
      if (
        this.form.zoekwaarde &&
        this.form.zoekwaarde.length &&
        this.found_objects
      ) {
        return this.found_objects;
      } else {
        return this.$store.getters["klanten/index"];
      }
    },
  },
  methods: {
    changePage(pageNumber) {
      this.getNewPage(this.index.path + "?page=" + pageNumber);
    },
    getNewPage(route) {
      this.$store.dispatch("klanten/index", route);
    },
    search() {
      this.$store.dispatch("klanten/search", this.form);
    },
    show() {
      this.$router.push({
        name: "klant",
        params: { klant: this.selected.id },
      });
    },
  },
  mounted() {},
};
</script>
