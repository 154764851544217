<style scoped>
.formInput {
  padding-bottom: 10px;
}
</style>

<template>
  <div>
    <div class="title is-5">
      Emailadressen per onderwerp voor contactformulier
    </div>

    <formInput
      v-for="onderwerp of settings.onderwerpen"
      class="formInput"
      :key="onderwerp"
      :model="form"
      label="inside"
      :modelKey="onderwerp"
    />

    <button
      @click="saveSubjects()"
      :class="{ 'is-loading': ajaxloading.savingContactForm }"
      class="button is-primary is-rounded"
    >
      Opslaan
    </button>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: [],
  data() {
    return {
      form: {},
      emails: null
    };
  },
  computed: {
    settings() {
      return this.$store.getters["settings"];
    }
  },
  methods: {
    saveSubjects() {
      return this.$store.dispatch("saveSubjects", this.form).then(response => {
        this.emails = response;
      });
    },
    matchEmailsWithSettings() {
      if (this.emails && this.settings.onderwerpen) {
        this.settings.onderwerpen.forEach(onderwerp => {
          Vue.set(
            this.form,
            onderwerp,
            this.emails.find(a => a.naam === onderwerp).email
          );
        });
      }
    }
  },
  watch: {
    settings: {
      deep: true,
      handler() {
        this.matchEmailsWithSettings();
      }
    },
    emails: {
      deep: true,
      handler() {
        this.matchEmailsWithSettings();
      }
    }
  },
  mounted() {
    this.$store.dispatch("getSubjects").then(response => {
      this.emails = response;
      this.matchEmailsWithSettings;
    });
  }
};
</script>
