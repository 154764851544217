import { render, staticRenderFns } from "./EditPostalCode.vue?vue&type=template&id=204dd522&scoped=true&"
import script from "./EditPostalCode.vue?vue&type=script&lang=js&"
export * from "./EditPostalCode.vue?vue&type=script&lang=js&"
import style0 from "./EditPostalCode.vue?vue&type=style&index=0&id=204dd522&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204dd522",
  null
  
)

export default component.exports