<template>
  <div>
    <div class="container box">
      <transition name="fade" mode="out-in">
        <router-view v-if="show"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      show: false,
    };
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store
      .dispatch(
        "bestellijsten/getLeverdag",
        this.$router.currentRoute.params.leverdag
      )
      .then(() => (this.show = true));
  },
};
</script>
