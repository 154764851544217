<style scoped>
.customTitle {
  font-weight: bold;
}

.customBox {
  border-style: solid;
}

.no-margin-bottom {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.profile-image {
  width: 100%;
  max-height: 256px;
}

.ref_column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.border {
  border: lightgrey;
  border-style: solid;
}
</style>

<template>
  <div>
    <quote quote="Eet smakelijk, allemaal!"></quote>

    <div class="columns is-desktop">
      <div class="column">
        <div
          class="box"
          v-if="settings.variables"
          v-html="findSetting('soep_startpagina')"
        >
          <!-- <div class="bold">Dagelijkse soep</div>
          Welkom op de bestelpagina van De Dagelijkse Soep! <br />
          <br />
          <i
            >Ben jij inwoner van Zutphen en kun je wel wat extra steun gebruiken
            deze winter?</i
          >
          <br />
          <i
            >Zou je het fijn vinden als iemand een warme kop soep komt
            brengen?</i
          >
          <br />
          <br />
          Wij maken heerlijke soep, gemaakt van verse biologische groenten.
          <br />
          Elke maand starten we bij Het Dagelijks Bestaan met een gast aan
          tafel.
          <br />
          Die vertelt over zijn of haar soeprecept en waarom dit zo speciaal en
          lekker is. <br />
          <br />
          Dan gaan we aan de slag om de soep te maken en die komen we bij jou
          brengen! <br />
          De beker nemen we weer mee, wassen we af en wordt gereed gemaakt voor
          de volgende keer.<br />
          Zo zijn we ook bewust met het milieu bezig.
          <br />
          <br />
          Wil je dat wij bij jou langskomen, klik dan op het onderstaande
          soep-plaatje en bestel jouw soep! <br />
          Je krijgt dan de data en tijdstip van bezorging te zien. Je kunt één
          beker per gezinslid bestellen. <br />
          De bestelling bestaat maximaal uit vier bekers soep.
          <br />
          <br />
          De komende maanden kunnen wij dit, in samenwerking met gemeente
          Zutphen, geheel gratis aanbieden.
          <br />
          <br />
          <strong> EET SMAKELIJK!!</strong> -->
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div
            class="columns"
            v-for="outerLoop in Math.ceil(producten.length / maxItemsPerRow)"
            :key="outerLoop"
          >
            <div
              class="column is-4"
              v-for="item in calculatedMax(outerLoop)"
              :key="item"
            >
              <div
                class="card pointer"
                style="height: 100%"
                @click="
                  navigateToProduct(
                    producten[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ]
                  )
                "
              >
                <div class="card-image">
                  <figure class="image is-1by1">
                    <div
                      @mouseover="
                        mouseoverImage(
                          producten[
                            item +
                              outerLoop * maxItemsPerRow -
                              maxItemsPerRow -
                              1
                          ]
                        )
                      "
                      @mouseleave="mouseleaveImage"
                    >
                      <img
                        v-if="
                          hoveredPoduct ===
                          producten[
                            item +
                              outerLoop * maxItemsPerRow -
                              maxItemsPerRow -
                              1
                          ]
                        "
                        :src="
                          getHoveredPictureUrl(
                            producten[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ]
                          )
                        "
                        alt="Product foto"
                      />

                      <img
                        v-else
                        :src="
                          getFirstPictureUrl(
                            producten[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ]
                          )
                        "
                        alt="Product foto"
                      />
                    </div>
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media"></div>
                    <div class="media-content">
                      <p class="title is-4 has-text-centered">
                        {{
                          hoofdletter(
                            producten[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].naam
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import quote from "@/views/Components/Quote.vue";

export default {
  components: {
    quote: quote,
  },
  props: [],
  data() {
    return {
      maxItemsPerRow: 3,
      hoveredPoduct: {},
      env: process.env,
    };
  },
  computed: {
    producten() {
      return this.$store.getters["checkout/index"];
    },
  },
  methods: {
    getFirstPictureUrl(product) {
      if (!product || !product.id) {
        return;
      }

      var picture = null;

      if (product.product && product.product.id) {
        picture = product.product.pictures[0].uuid;
        return this.env.VUE_APP_API_URL + "/web/picture/" + picture;
      } else {
        picture = product.picture;
        return (
          this.env.VUE_APP_API_URL + "/web/order_product_images/" + picture
        );
      }
    },
    getHoveredPictureUrl(product) {
      if (!product || !product.id) {
        return;
      }

      var picture = null;

      if (product.product && product.product.id) {
        if (product.product.pictures.length > 1) {
          picture = product.product.pictures[1].uuid;
        } else {
          picture = product.product.pictures[0].uuid;
        }
        return this.env.VUE_APP_API_URL + "/web/picture/" + picture;
      } else {
        picture = product.picture;
        return (
          this.env.VUE_APP_API_URL + "/web/order_product_images/" + picture
        );
      }
    },
    calculatedMax(loop) {
      const max = this.producten.length;
      const rows = this.maxItemsPerRow * loop;
      if (max < rows) {
        return max - this.maxItemsPerRow * (loop - 1);
      } else {
        return this.maxItemsPerRow;
      }
    },
    mouseoverImage(product) {
      this.hoveredPoduct = product;
    },
    mouseleaveImage() {
      this.hoveredPoduct = {};
    },
    navigateToProduct(product) {
      this.$router.push({
        name: "dagelijkse-soep-product",
        params: { product: product.slug },
      });
    },
  },
  mounted() {},
};
</script>
