<style scoped>
.greyImage {
  filter: grayscale(100%);
}

.hiddenImage {
  filter: grayscale(100%);
}
</style>

<template>
  <div>
    <div class="columns is-mobile">
      <div class="column">
        <h1 class="title is-5">Soepen</h1>
      </div>
      <div class="column">
        <button
          class="button is-pulled-right is-rounded is-small"
          :class="{ 'is-loading': ajaxloading.create_product }"
          @click="createProduct()"
        >
          Toevoegen
        </button>
      </div>
    </div>
    <div class="">
      <b-tabs
        expanded
        position="is-centered"
        class="block"
        v-model="activeTab"
        @input="syncActiveTabToQuery()"
      >
        <b-tab-item label="Soepen" value="soep">
          <adminSoepIndexCard :products="availableProducts" />
        </b-tab-item>

        <b-tab-item label="Concept" value="concept">
          <adminSoepIndexCard :products="conceptProducts" />
        </b-tab-item>
      </b-tabs>
    </div>
    <!-- <crudModal v-on:closeModal="showCrudModal = false" v-if="showCrudModal" /> -->
  </div>
</template>

<script>
import adminSoepIndexCard from "./AdminSoepIndexCard.vue";

export default {
  components: {
    adminSoepIndexCard,
  },
  props: [],
  data() {
    return {
      activeTab: "soep",
      showCrudModal: false,
      hoveredProduct: null,
      maxItemsPerRow: 4,
      env: process.env,
      showAll: false,
      // filteredCategories: []
    };
  },
  computed: {
    products() {
      return this.$store.getters["soep/index"];
    },
    conceptProducts() {
      return this.products.filter((a) => a.status === "concept");
    },
    availableProducts() {
      return this.products.filter((a) => a.status !== "concept");
    },
  },
  methods: {
    createProduct() {
      this.$store.dispatch("soep/createItem").then((response) => {
        this.$router.push({
          name: "admin-product",
          params: { product: response.data.id },
        });
      });
    },

    filterCategories() {
      // this.filteredCategories = this.products;
    },
    syncActiveTabToQuery() {
      if (this.$router.currentRoute.query.activeTab !== this.activeTab) {
        this.$router.replace({ query: { activeTab: this.activeTab } });
      }
    },
    getActiveTabFromQuery() {
      if (this.$router.currentRoute.query.activeTab) {
        this.activeTab = this.$router.currentRoute.query.activeTab;
      }
    },
  },
  watch: {
    // activeTab: {
    //   deep: true,
    //   handler() {
    //     this.syncActiveTabToQuery();
    //   },
    // },
  },
  mounted() {
    this.getActiveTabFromQuery();
  },
};
</script>
