<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="users-title">Geauthorizeerde postcodes</div>
    <div class="block table-icons">
      <b-tooltip label="Postcode toevoegen" position="is-top">
        <span class="pointer table-icon" @click="toevoegen()">
          <b-icon icon="plus"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip v-if="selected" label="Postcode aanpassen" position="is-top">
        <span class="pointer table-icon" @click="aanpassen()">
          <b-icon icon="edit"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip v-if="selected" label="Postcode verwijderen" position="is-top">
        <span class="pointer table-icon" @click="verwijderen()">
          <b-icon icon="trash"></b-icon>
        </span>
      </b-tooltip>
    </div>

    <br />
    <br />
    <div>
      <b-table
        :data="postal_codes"
        :bordered="true"
        :striped="true"
        :hoverable="true"
        :loading="ajaxloading.get_postal_codes"
        :focusable="false"
        :mobile-cards="false"
        :selected.sync="selected"
        @dblclick="aanpassen()"
      >
        <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="name" label="Postcode begin" v-slot="props">
          {{ props.row.postcode_begin }}
        </b-table-column>

        <b-table-column field="name" label="Postcode eind" v-slot="props">
          {{ props.row.postcode_eind }}
        </b-table-column>

        <b-table-column field="name" label="huisnummer begin" v-slot="props">
          {{ props.row.huisnummer_begin }}
        </b-table-column>

        <b-table-column field="name" label="Huisnummer eind" v-slot="props">
          {{ props.row.huisnummer_eind }}
        </b-table-column>

        <b-table-column field="name" label="Omschrijving" v-slot="props">
          {{ props.row.omschrijving }}
        </b-table-column>

        <b-table-column field="status" label="Status" v-slot="props">
          {{ props.row.status }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    postal_codes() {
      return this.$store.getters["postalCodes/postal_codes"];
    },
  },
  methods: {
    toevoegen() {
      this.$router.push({ name: "postcode-toevoegen" });
    },
    aanpassen() {
      this.$router.push({
        name: "postcode-aanpassen",
        params: { postcode: this.selected.id },
      });
    },
    verwijderen() {
      this.$buefy.dialog.confirm({
        title: "Postcode verwijderen",
        message: "Weet u zeker dat u deze postcode range wil verwijderen?",
        confirmText: "Verwijderen",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("postalCodes/destroy", this.selected)
            .then(() => {
              this.selected = null;
              this.$buefy.toast.open("Postcode verwijderd");
            }),
      });
    },
  },
  mounted() {},
};
</script>
