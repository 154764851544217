export const getPostalCodes = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get_postal_codes";
  data.method = "GET";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setPostalCodes", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const create = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create_postal_code_range";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setNewPostalCode", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const edit = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "edit_postal_code_range/" + payload.id;
  data.loading = "edit_postal_code_range";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updatePostalCode", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const destroy = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "destroy_postal_code_range/" + payload.id;
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("deletePostalCode", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const refresh = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "refresh_postalcode/" + payload.id;
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updatePostalCode", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
