<style>
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}
</style>

<template>
  <div>
    <div class="container is-fluid" v-if="product && product.id">
      <br />

      <div
        class="button is-outlined is-primary is-fullwidth"
        @click="$router.push({ name: 'dagelijkse-soep-landing' })"
      >
        <b-icon icon="arrow-left"></b-icon>&nbsp; Terugkeren
      </div>

      <br />

      <div class="columns">
        <div class="column is-5">
          <b-carousel
            indicator-custom
            v-model="shownPicture"
            :autoplay="false"
            :indicator-inside="false"
            v-if="product.product && product.product.pictures.length > 1"
          >
            <b-carousel-item
              v-for="(item, i) in product.product.pictures"
              :key="i"
              class="has-text-centered"
            >
              <b-image
                ratio="1by1"
                class="image"
                alt="Product Image"
                :src="
                  env.VUE_APP_API_URL +
                  '/web/picture/' +
                  product.product.pictures[i].uuid
                "
              ></b-image>
            </b-carousel-item>
            <template #indicators="props">
              <b-image
                ratio="1by1"
                class="al image"
                :src="
                  env.VUE_APP_API_URL +
                  '/web/picture/' +
                  product.product.pictures[props.i].uuid
                "
                :title="props.i"
              ></b-image>
            </template>
          </b-carousel>

          <b-image
            v-else
            ratio="1by1"
            class="image"
            alt="Product Image"
            :src="
              env.VUE_APP_API_URL +
              '/web/picture/' +
              product.product.pictures[0].uuid
            "
          ></b-image>
        </div>
        <div class="column">
          <span class="title">{{ naam }}</span>
          <hr />
          <div class="content" v-html="omschrijving"></div>
          <hr />

          <span v-if="product.korting > 0">
            <div class="strikethrough">{{ euro(product.prijs) }}</div>
            <div>{{ euro(product.prijs - product.korting) }}</div>
          </span>
          <span v-else>
            {{ euro(product.prijs) }}
          </span>

          <hr />

          <div class="title is-5">Beschikbare besteldagen:</div>
          <span class="content">
            <ul>
              <li v-for="dag in product.besteldagen" :key="dag.id">
                <div class="columns">
                  <div class="column">
                    {{ formatDatabaseDateHuman(dag.bestellingsdag) }} tussen
                    {{
                      formatTimePickerEditDateFromDataBase(
                        findVariable("soep_bezorgtijden_begin")
                      )
                    }}
                    en
                    {{
                      formatTimePickerEditDateFromDataBase(
                        findVariable("soep_bezorgtijden_eind")
                      )
                    }}
                  </div>
                  <div class="column">
                    <span v-if="!dag.inschrijving_geopend">
                      {{ getClosedOrderDayMessage(dag) }}
                    </span>
                    <span
                      v-else-if="
                        dag.totale_producten - dag.bestelde_producten < 1
                      "
                    >
                      Uitverkocht
                    </span>

                    <b-field
                      v-else
                      :message="calculateAvailable(dag) + ' beschikbaar.'"
                    >
                      <b-numberinput
                        :editable="false"
                        controls-rounded
                        :min="0"
                        size="is-small"
                        v-model="
                          dag.products[getProductDayKey(dag)]
                            .gekozen_hoeveelheid
                        "
                        controls-position="compact"
                        :max="calculateMaxToOrder(dag)"
                      >
                      </b-numberinput>
                    </b-field>
                  </div>
                </div>
              </li>
            </ul>
          </span>
          <hr />

          <p>
            <b-button
              :disabled="checkButtonDisabled()"
              type="is-fullwidth is-primary is-outlined"
              icon-left="shopping-cart"
              @click="$router.push({ name: 'dagelijkse-soep-checkout' })"
            >
              Bestellen
            </b-button>
            <br />
            <b-button
              type="is-fullwidth is-outlined"
              icon-left="arrow-left"
              @click="$router.push({ name: 'dagelijkse-soep-landing' })"
            >
              Andere soepen bekijken
            </b-button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      env: process.env,
      amount: 1,
      shownPicture: 0,
    };
  },
  computed: {
    totale_producten() {
      let hoeveelheid = 0;
      for (let product of this.producten) {
        for (let dag of product.besteldagen) {
          for (let gekozen_product of dag.products)
            hoeveelheid = hoeveelheid + gekozen_product.gekozen_hoeveelheid;
        }
      }
      return hoeveelheid;
    },
    producten() {
      return this.$store.getters["checkout/index"];
    },
    gekozen_bestellings_dagen() {
      return this.$store.getters["checkout/gekozen_bestellings_dagen"];
    },

    product() {
      return this.producten.find((a) => a.slug == this.$route.params.product);
    },
    cart() {
      return this.$store.getters["checkout/cart"];
    },
    naam() {
      const product = this.product;
      if (product.product && product.product.id) {
        return product.product.naam;
      }

      return product.naam;
    },
    omschrijving() {
      const product = this.product;
      if (product.product && product.product.id) {
        return product.product.omschrijving;
      }

      return product.omschrijving;
    },
  },
  methods: {
    // addProductToCart() {
    //   if (this.product.type === "options" && !this.option.id) {
    //     return this.$buefy.toast.open({
    //       message: "Choose an option before adding to cart.",
    //       type: "is-warning",
    //     });
    //   }

    //   this.setCartTimer();
    //   const payload = {
    //     product: this.product,
    //     amount: this.amount,
    //     option: this.option,
    //   };
    //   this.$store.dispatch("cart/AddProductToCart", payload);

    //   this.$buefy.toast.open({
    //     message: "Product added to cart.",
    //     type: "is-success",
    //   });

    //   if (this.calculateAvailable(payload.product, payload.option) === 0) {
    //     this.amount = 0;
    //   } else {
    //     this.amount = 1;
    //   }
    // },
    setCartTimer() {
      this.addingToCart = true;
      setTimeout(
        function () {
          this.addingToCart = false;
        }.bind(this),
        1000
      );
    },
    getProductDayKey(besteldag) {
      const products = besteldag.products;
      const id = this.product.product_id;
      const index = products.findIndex((a) => a.product_id === id);
      return index;
    },
    calculateAvailable(besteldag) {
      const max = this.calculateMaxToOrder(besteldag);
      var reeds_besteld = 0;
      for (let product of besteldag.products) {
        reeds_besteld = reeds_besteld + product.gekozen_hoeveelheid;
      }

      return max - reeds_besteld;
    },
    calculateMaxToOrder(besteldag) {
      const totaal_product_beschikbaar =
        this.product.hoeveelheid - this.product.besteld;
      const max_per_bestelling = besteldag.maximale_producten_per_klant;

      if (totaal_product_beschikbaar > max_per_bestelling) {
        return max_per_bestelling;
      }

      return totaal_product_beschikbaar;
    },
    calcMaxMessage(dag) {
      const available = this.calculateAvailable(dag);

      if (available < 0) {
        return 0;
      } else {
        return available;
      }
    },
    getClosedOrderDayMessage(dag) {
      if (dag) {
        console.log(dag);
        const now = new Date();
        const openingsdag = this.formatDataBaseDateToJSDate(
          dag.bestelling_openings_timestamp
        );
        const sluitingsdag = this.formatDataBaseDateToJSDate(
          dag.bestelling_sluitings_timestamp
        );
        if (now < openingsdag) {
          return (
            "De inschrijvingen voor deze besteldag gaan open op " +
            this.formatDatePickerEditDateFromDataBase(
              dag.bestelling_openings_timestamp
            ) +
            " rond " +
            this.formatTimePickerEditDateFromDataBase(
              dag.bestelling_openings_timestamp
            ) +
            "."
          );
        }

        if (now > sluitingsdag) {
          return "De inschrijvingen voor deze bestellingsdag zijn reeds gesloten.";
        } else {
          return "De inschrijvingen voor deze bestellingsdag zijn gesloten.";
        }
      }
    },
    checkButtonDisabled() {
      if (this.totale_producten > 0) {
        return false;
      }

      return true;
    },
    // findProductInCart(product, option) {
    //   if (product.type === "options") {
    //     if (option.id) {
    //       const cartItem = this.cart.find(
    //         (a) => a.product.id === product.id && a.option.id === option.id
    //       );

    //       return cartItem;
    //     }
    //   }
    //   return this.cart.find((a) => a.product.id === product.id);
    // },
    // checkOptionDisabled(product, productOption) {
    //   if (
    //     this.calculateAvailable(product, productOption) === 0 &&
    //     !this.findProductInCart(product, productOption)
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // removeFromCart(product) {
    //   const payload = {
    //     product: this.product,
    //     option: this.option,
    //   };

    //   this.$buefy.toast.open({
    //     message: "Product removed from cart",
    //     type: "is-danger",
    //   });
    //   this.$store.dispatch("cart/RemoveProductFromCart", payload);
    // },
    // productsLeftToAdd(product) {
    //   if (product && product.type === "options" && !this.option.id) {
    //     return true;
    //   }

    //   const inCart = this.findProductInCart(product, this.option);

    //   if (inCart) {
    //     if (product.type === "unique") {
    //       return true;
    //     }

    //     if (
    //       inCart.product.type === "multiple" &&
    //       this.calculateAvailable(product) === 0
    //     ) {
    //       return true;
    //     }

    //     if (
    //       product.type === "options" &&
    //       this.calculateAvailable(product, this.option) === 0
    //     ) {
    //       return true;
    //     }
    //   } else {
    //     return false;
    //   }
    // },
  },

  mounted() {
    if (!this.product) {
      this.$router.push({ name: "dagelijkse-soep-landing" });
    }
  },
};
</script>
