<style lang="scss">
@import "@/styles/main.scss";
// @import "@/styles/stylesheet.scss";

* {
  margin: 0;
}
html,
body {
  height: 100%;
}
.page-wrap {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -189px;
}
.page-wrap:after {
  content: "";
  display: block;
}
.site-footer,
.page-wrap:after {
  height: 189px;
}
.site-footer {
  // background: orange;
}
</style>

<template>
  <div style="height:100%;">
    <div class="page-wrap">
      <router-view />
    </div>
    <footnote class="site-footer" />
  </div>
</template>

<script>
import footer from "@/views/Footer.vue";

export default {
  components: {
    footnote: footer
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch("getAllPictures");
    this.$store.dispatch("getSettings");
  }
};
</script>
