import Vue from "vue";
import { mapGetters } from "vuex";

const validation = Vue.mixin({
  computed: {
    ...mapGetters({
      validation: "valerrors",
    }),
  },
  methods: {
    clearValidationError(key) {
      this.$store.dispatch("clearValidationError", key);
    },
  },
});

export default validation;
