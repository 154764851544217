<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Onze woorden</div>
          Landen: Wanneer je net nieuw bent op Het Dagelijks Bestaan komt er
          veel op je af. Nieuwe gezichten en namen, een nieuwe locatie, nieuwe
          rituelen en gewoonten. Dat is best even wennen! Dit duurt vaak een
          paar weken. In die tijd krijg je de ruimte om kennis te maken met de
          mensen en onze gewoontes. Zodra je je meer thuis voelt, zeggen wij
          vaak: je bent geland.
          <br />
          <br />
          Verbinding: Dit noemen wij vaak “de tweede fase”. Wanneer je geland
          bent bij Het Dagelijks Bestaan, durf je wat meer van jezelf te laten
          zien. In die periode wordt je liefdevol uitgedaagd om stappen te
          maken, dingen aan de rand van je comfortzone te doen en te ontdekken;
          wat zijn nu mijn talenten? En mijn gevoeligheden?
          <br />
          <br />
          Uitvliegen: De laatste fase binnen Het Dagelijks Bestaan. Je blik
          wordt meer op buiten gericht: wat voor werk zou ik willen doen? Kan ik
          ergens stage lopen? Wil ik (weer) naar school toe ? Welke
          studierichting vind ik leuk? In deze periode maak je je langzaam los
          van Het Dagelijks Bestaan en bereid je je voor op de stap richting de
          maatschappij.
          <br />
          <br />
          Langzame Tijd: Op Het Dagelijks Bestaan proberen we overal de tijd
          voor te nemen. Om echt te proeven van je eten, om de mooie bloemen op
          tafel te bewonderen, om stil te staan bij feesten en gebeurtenissen,
          een nachtje slapen over beslissingen. De langzame tijd is een
          tegenhanger van gehaastheid.
        </div>
      </div>
      <div class="column">
        <img src="@/assets/pics/3.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
