<style scoped>
.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="title">Besteldagen</div>
    <div class="subtitle">Eerstvolgende bestellingsdag</div>
    <bestellingendagTable :index="upComingOrderDay" />
    <br />
    <hr />
    <br />
    <div>
      <b-tabs
        expanded
        position="is-centered"
        class="block"
        v-model="activeTab"
        @input="syncActiveTabToQuery()"
      >
        <b-tab-item label="Bestellingen" value="bestellingen">
          <bestellingendagTable :index="upComingDays" />
        </b-tab-item>
        <b-tab-item label="Archief" value="archief">
          <bestellingendagTable :index="archief" />
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import bestellingendagTable from "./BestellingendagTable.vue";

export default {
  components: {
    bestellingendagTable,
  },
  props: [],
  data() {
    return {
      activeTab: "bestellingen",
      selected: null,
    };
  },
  computed: {
    index() {
      return this.$store.getters["bestellingsdagen/index"];
    },
    archief() {
      const today = new Date();
      today.setMinutes(0);
      today.setHours(0);
      today.setSeconds(0);
      const aankomende_dagen = this.index.filter(
        (a) => new Date(a.bestellingsdag) < today
      );
      if (aankomende_dagen.length) {
        return aankomende_dagen;
      }
      return [];
    },
    upComingDays() {
      const today = new Date();
      today.setMinutes(0);
      today.setHours(0);
      today.setSeconds(0);
      const aankomende_dagen = this.index.filter(
        (a) => new Date(a.bestellingsdag) > today
      );
      if (aankomende_dagen.length) {
        return aankomende_dagen.reverse();
      }
      return [];
    },
    upComingOrderDay() {
      if (this.upComingDays.length) {
        const day = this.upComingDays;

        const orderdayarray = [];
        orderdayarray.push(day[0]);
        return orderdayarray;
      }

      return [];
    },
  },
  methods: {
    updateSelected(value) {
      this.selected = value;
    },
    syncActiveTabToQuery() {
      if (this.$router.currentRoute.query.activeTab !== this.activeTab) {
        this.$router.replace({ query: { activeTab: this.activeTab } });
      }
    },
    getActiveTabFromQuery() {
      if (this.$router.currentRoute.query.activeTab) {
        this.activeTab = this.$router.currentRoute.query.activeTab;
      }
    },
  },
  mounted() {
    this.getActiveTabFromQuery();
  },
};
</script>
