<template>
  <b-field
    :label="hoofdletter(computedTitle)"
    :label-position="label"
    :type="{ 'is-danger': validation[computedValidation] }"
    :message="validation[computedValidation]"
  >
    <b-numberinput
      :ref="modelKey"
      :type="type"
      :value="value"
      @blur="blur()"
      @input="clearValidationError()"
      v-model="model[modelKey]"
      :size="size"
      :placeholder="placeholder"
      :disabled="disabled"
      :expanded="expanded"
      :min="1"
    >
    </b-numberinput>
  </b-field>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    model: {
      required: true,
      type: Object,
    },
    modelKey: {
      required: true,
      type: String,
    },
    expanded: {
      default: true,
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    validationKey: {
      type: String,
    },
    min: {
      default: 1,
      type: Number,
    },
    max: {
      default: 1,
      type: Number,
    },
    size: {
      default: "is-normal",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    value: {
      required: false,
    },
    type: {
      default: "input",
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      syncValue: true,
    };
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedValidation() {
      if (this.validationKey) {
        return this.validationKey;
      } else {
        return this.modelKey;
      }
    },
  },
  methods: {
    blur() {
      this.$emit("blur");
    },
    clearValidationError() {
      this.$emit("input");
      this.syncValue = false;
      this.$store.dispatch("clearValidationError", this.computedValidation);
    },
    setValue() {
      if (this.value !== undefined && this.syncValue) {
        Vue.set(this.model, this.modelKey, this.value);
      }
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.setValue();
      },
    },
    model: {
      deep: true,
      handler() {
        this.setValue();
      },
    },
  },
  mounted() {
    this.setValue();
  },
};
</script>
