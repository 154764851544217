export const getIndex = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get_all_settings";
  data.method = "GET";
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setIndex", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveVariables = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "save_variables";
  data.method = "post";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      dispatch("getIndex");
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
