<style scoped>
.is-active {
  /* background-color: inherit !important; */
  /* color: orange !important; */
}
</style>

<template>
  <div>
    <aside class="menu">
      <ul class="menu-list">
        <li>
          <router-link
            v-if="['superuser', 'beheerder'].includes(user.rol)"
            @click="closeMobile()"
            tag="a"
            exact
            :to="{ name: 'admin' }"
            >Website</router-link
          >
        </li>
        <li>
          <router-link
            v-if="
              ['soep_coordinator', 'superuser', 'beheerder'].includes(user.rol)
            "
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'bezorglijsten-index' }"
            >Leverdagen</router-link
          >
        </li>
        <li>
          <router-link
            v-if="
              ['soep_coordinator', 'superuser', 'beheerder'].includes(user.rol)
            "
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'bestellingen-index' }"
            >Bestellingen</router-link
          >
        </li>
        <li>
          <router-link
            v-if="
              ['soep_coordinator', 'superuser', 'beheerder'].includes(user.rol)
            "
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'bestellingsdagen-index' }"
            >Besteldagen</router-link
          >
        </li>
        <li>
          <router-link
            v-if="
              ['soep_coordinator', 'superuser', 'beheerder'].includes(user.rol)
            "
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'soep-index' }"
            >Soepen</router-link
          >
        </li>
        <li>
          <router-link
            v-if="
              ['soep_coordinator', 'superuser', 'beheerder'].includes(user.rol)
            "
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'postcode-index' }"
            >Postcodes</router-link
          >
        </li>
        <li>
          <router-link
            v-if="
              ['soep_coordinator', 'superuser', 'beheerder'].includes(user.rol)
            "
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'blacklist-index' }"
            >Blacklist</router-link
          >
        </li>
        <li>
          <router-link
            v-if="
              ['soep_coordinator', 'superuser', 'beheerder'].includes(user.rol)
            "
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'klanten-index' }"
            >Klanten</router-link
          >
        </li>

        <li>
          <router-link
            v-if="['superuser', 'beheerder'].includes(user.rol)"
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-gebruikers' }"
            >Gebruikers</router-link
          >
        </li>

        <hr />
        <li>
          <router-link
            v-if="['superuser', 'beheerder'].includes(user.rol)"
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-foto-upload' }"
            >Foto uploaden</router-link
          >
        </li>
        <li>
          <router-link
            v-if="['superuser', 'beheerder'].includes(user.rol)"
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-fotos' }"
            >Fotos</router-link
          >
        </li>
        <li>
          <router-link
            v-if="['superuser', 'beheerder'].includes(user.rol)"
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-contactformulier' }"
            >ContactFormulier</router-link
          >
        </li>
        <hr />
        <li>
          <router-link
            v-if="['superuser', 'beheerder'].includes(user.rol)"
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'settings-index' }"
            >Instellingen</router-link
          >
        </li>
        <li>
          <a @click="logout()">Uitloggen</a>
        </li>
      </ul>
    </aside>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ name: "home" });
      });
    },
  },
  mounted() {},
};
</script>
