<style scoped>
.customTitle {
  font-weight: bold;
}

.customBox {
  border-style: solid;
}
</style>

<template>
  <div>
    <quote
      quote="Alles wat je aandacht geeft groeit daarom besteden wij graag aandacht aan
      het mooi maken van onze leefomgeving"
    ></quote>

    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Beschermd wonen</div>
          Op Het Dagelijks Bestaan is plek voor twaalf jongeren Wij ondersteunen
          je een (aantal) jaar in het oefenen met zelfstandig wonen. Je leert
          koken, krijgt ondersteuning in het ordenen van je huishoudelijke taken
          en financiën. Er is ook veel ruimte voor zelfontwikkeling en
          zingeving: het ontdekken van je talenten, hoe om te gaan met
          vriendschappen en (familie)relaties en andere levensvragen. Dagelijks
          is er de mogelijkheid gezamenlijk te eten met de andere jongeren die
          hier wonen. Iedereen heeft een individueel begeleider, maar voor
          vragen kun je bij iedereen uit het team terecht. Jongeren die bij ons
          wonen, hebben een zinvolle daginvulling, bijvoorbeeld een studie,
          (vrijwilligers)werk of dagbesteding.
          <br />
          <br />
          <div class="customTitle">Woongemeenschap</div>
          Naast de dertien studio’s voor jongeren zijn er dertien andere huizen
          op het terrein. Deze buren vormen samen de woongemeenschap. Samen
          onderhouden we het terrein en delen geregeld maaltijden. We stoken
          graag een vuurtje en vieren samen feestdagen en bijzondere
          gebeurtenissen. Jongeren zijn ook van harte uitgenodigd deel te nemen
          aan de activiteiten in de woongemeenschap. Het is een mooie manier om
          sociale vraagstukken te oefenen en in beweging te komen. Je leest hier
          meer over de
          <a @click="$router.push({ name: 'woongemeenschap' })"
            >woongemeenschap</a
          >.
          <br />
          <br />
          <div class="customTitle">Voor wie is het?</div>
          Jongeren tussen de 18 en 27 jaar kunnen bij ons komen wonen. De meeste
          jongeren die bij ons komen wonen, willen graag zelfstandig leren
          worden. Bij ons kun je oefenen met sociale vraagstukken, (gezond)
          leren koken en leren om jezelf en je huis te verzorgen. We hebben
          woningen van 16m2 en 24m2. In deze studio’s is een eigen
          kookgelegenheid, toilet en douche aanwezig. In de groepsruimte kun je
          gebruik maken van een wasmachine. Naast je eigen studio, hebben we een
          gezamenlijke woonkamer. Hier wordt gegeten, gekletst, worden
          spelletjes gespeeld of huiswerk gemaakt.
        </div>
      </div>
      <div class="column">
        <carrousel :page="'beschermdwonen'" />
      </div>
    </div>

    <div class="box">
      <div class="customTitle">Hoe ziet het traject er uit?</div>
      Wanneer je net nieuw bent op Het Dagelijks Bestaan komt er veel op je af.
      Nieuwe mensen en een nieuwe omgeving en een nieuw huis, dat is best even
      wennen! Wanneer je net bij ons komt wonen, noemen wij dit de landingsfase.
      In die tijd krijg je de ruimte om kennis te maken met de jongeren,
      begeleiders en buren. Het belangrijkste vinden we dat je er bent. We
      hebben dagelijks contact en nodigen je actief uit voor de maaltijden en
      andere momenten, zodat je de gewoontes en rituelen van Het Dagelijks
      Bestaan kan leren kennen. In deze periode werk je aan het krijgen van een
      gezond ritme, het vinden van rust en het ontdekken van de vragen waar jij
      mee werken wilt. Zodra je je meer thuis voelt, zeggen wij vaak: je bent
      geland.
      <br /><br />
      Wanneer je geland bent, begint de volgende fase: de verbinding. Je gaat de
      verbinding aan met de andere mensen op Het Dagelijks bestaan, en nog
      belangrijker, met jezelf. In deze periode kom je achter je talenten en je
      valkuilen. Wat helpt mij op weg naar zelfstandigheid? Hoe ga ik om met
      mijn familie en/of vriendschapsrelaties? Hoe ga ik om met de tijd dat ik
      alleen ben? In die periode word je liefdevol uitgedaagd om stappen te
      maken, dingen aan de rand van je comfortzone te doen en te ontdekken.
      <br /><br />
      De laatste fase binnen Het Dagelijks Bestaan is het ontwikkelen van je
      toekomstperspectief. Je blik wordt meer op buiten gericht: waar wil ik
      hierna wonen? Wat wil ik nog leren voordat ik die stap kan maken? Wat voor
      hulp heb ik nog nodig, als ik eenmaal verhuis van Het Dagelijks Bestaan?
      In deze periode maak je je langzaam los van Het Dagelijks Bestaan en
      bereid je je voor op de volgende stap. Dat kan bijvoorbeeld een
      zelfstandige woning met ambulante begeleiding of een andere plek met
      andere zorg zijn. In deze periode kook je vaker voor jezelf, neem je meer
      verantwoordelijkheden en bereid je je voor op de voor jou passende
      vervolgstap.
    </div>
    <div class="box customBox">
      <span class="customTitle">Hoe kom je bij ons terecht?</span> <br />
      Om bij ons te kunnen wonen, heb je een indicatie van Beschermd Wonen
      nodig. Je kunt hier meer informatie over lezen via
      <a href="https://www.deventer.nl/beschermdwonen"
        >www.deventer.nl/beschermdwonen</a
      >
      of mailen naar
      <a href="mailto:beschermdwonen@deventer.nl">beschermdwonen@deventer.nl</a>
      Zodra je indicatie rond is, kun je bij ons een kennismakingsgesprek
      aanvragen. We kijken dan of er een goede match is tussen jouw
      ontwikkelvragen en de begeleiding die wij bieden. Mocht je na het
      kennismakingsgesprek graag willen komen, volgt het intake gesprek. Dan
      kijken we inhoudelijk naar hoe het wonen er voor jou uit komt te zien.
    </div>
  </div>
</template>

<script>
import quote from "@/views/Components/Quote.vue";

export default {
  components: {
    quote: quote,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
