<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.name_columns {
  margin: 0px;
  padding: 0px;
}

.name_column {
  margin: 0px;
  padding: 0px;
}

.customInput {
  margin-bottom: 10px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Vaste klanten bestelling aanmaken</div>
    <hr />
    <form @submit="aanmaken()">
      <br />
      <br />
      <formSelect
        class="customInput"
        modelKey="besteldag"
        label="on-border"
        :model="form"
        placeholder="Selecteer dag"
        :selectOptions="bestellingsdagen"
        :formatDate="true"
        selectKey="bestellingsdag"
      />

      <br />
      <formSelect
        v-if="form.besteldag"
        class="customInput"
        modelKey="product"
        label="on-border"
        :model="form"
        placeholder="Selecteer product"
        :selectOptions="form.besteldag.products"
        selectKey="naam"
      />

      <br />
      <span v-if="form.product">
        <div class="columns name_columns">
          <div class="column name_column">Beschikbare producten</div>
          <div class="column name_column">
            {{
              form.besteldag.totale_producten -
              form.besteldag.bestelde_producten
            }}
          </div>
        </div>
        <div class="columns name_columns">
          <div class="column name_column">Producten in deze bestelling</div>
          <div class="column name_column">{{ bestelde_soepen }}</div>
        </div>
        <div class="columns name_columns">
          <div class="column name_column">Resterende producten</div>
          <div class="column name_column">
            {{
              form.besteldag.totale_producten -
              form.besteldag.bestelde_producten -
              bestelde_soepen
            }}
          </div>
        </div>

        <br />

        <br />

        <div id="vaste_klanten" class="title is-5">Vaste klanten</div>

        <b-table
          :data="vaste_klanten"
          :bordered="true"
          :striped="true"
          :hoverable="true"
          :focusable="false"
          :mobile-cards="false"
          detailed
          detail-key="id"
        >
          <b-table-column label="Naam" v-slot="props">
            {{ props.row.achternaam }}, {{ props.row.voornaam }}
          </b-table-column>

          <b-table-column label="Hoeveelheid" v-slot="props">
            <b-numberinput
              :editable="false"
              controls-rounded
              :min="1"
              size="is-small"
              v-model="props.row.vaste_klant_producten"
              controls-position="compact"
            >
            </b-numberinput>
          </b-table-column>

          <b-table-column label="Bezorgkeuze" v-slot="props">
            <formSelect
              class="customInput"
              modelKey="vaste_klant_bezorgkeuze"
              label="on-border"
              title=" "
              :model="props.row"
              placeholder="Selecteer product"
              :selectOptions="['ophalen', 'bezorgen']"
            />
          </b-table-column>

          <b-table-column label="Bevestigingsmail" v-slot="props">
            <formCheckbox
              class="customInput"
              modelKey="vaste_klant_mailen"
              label="on-border"
              title=" "
              :model="props.row"
            />
          </b-table-column>

          <b-table-column label="Wijzigingen opslaan" v-slot="props">
            <formCheckbox
              class="customInput"
              modelKey="save_changes"
              label="on-border"
              title=" "
              :model="props.row"
            />
          </b-table-column>

          <b-table-column label="Verwijderen" v-slot="props">
            <button
              class="button is-small is-danger is-outlined"
              @click.prevent="preDeleteCustomer(props.row)"
            >
              Verwijderen
            </button>
          </b-table-column>

          <template #detail="props">
            <div class="content">
              {{ props.row.straat }} {{ props.row.huisnummer }} <br />
              {{ props.row.postcode }} {{ props.row.plaats }} <br />
              <br />
              {{ props.row.telefoonnummer }} <br />
              {{ props.row.email }} <br />
            </div>
          </template>
        </b-table>
      </span>
    </form>

    <br />

    <button
      v-if="form.product"
      :class="{ 'is-loading': ajaxloading.create_massa_bestelling }"
      class="button is-primary"
      @click="preMakeOrder()"
    >
      Bestelling aanmaken</button
    >&nbsp;

    <button
      :class="{ 'is-loading': ajaxloading.create_massa_bestelling }"
      class="button"
      @click="cancelUpdate()"
    >
      Annuleren
    </button>

    <deleteModal
      :deleteCustomer="deleteCustomer"
      v-on:close="deleteCustomer = false"
      v-on:deleteCustomer="deleteCustomerFromCustomers(deleteCustomer)"
    />
  </div>
</template>

<script>
import Vue from "vue";
import deleteModal from "./DeleteCustomerModal.vue";

export default {
  components: {
    deleteModal,
  },
  props: [],
  data() {
    return {
      deleteCustomer: null,
      form: {},
    };
  },
  computed: {
    bestellingsdagen() {
      return this.$store.getters["bestellingen/besteldagen"];
    },
    klanten() {
      return this.$store.getters["bestellingen/klanten"];
    },
    vaste_klanten() {
      return this.klanten
        .filter((a) => a.vaste_klant === true)
        .filter((a) => a.vaste_klant_producten > 0)
        .sort((a, b) => a.achternaam - b.achternaam);
    },
    vaste_klanten_for_order() {
      return this.klanten
        .filter((a) => a.vaste_klant === true)
        .sort((a, b) => a.achternaam - b.achternaam);
    },
    bestelde_soepen() {
      let soepen = 0;
      for (let klant of this.vaste_klanten) {
        soepen += klant.vaste_klant_producten;
      }

      return soepen;
    },
    emails_to_send() {
      let emails = 0;
      for (let klant of this.vaste_klanten) {
        if (klant.vaste_klant_mailen) emails += 1;
      }

      return emails;
    },
  },
  methods: {
    deleteCustomerFromCustomers(customer) {
      customer.vaste_klant_producten = 0;
      document.getElementById("vaste_klanten").click();
    },
    preDeleteCustomer(customer) {
      this.deleteCustomer = customer;
    },
    preMakeOrder() {
      this.$buefy.dialog.confirm({
        title: "Bestelling aanmaken",
        message:
          "Weet je zeker dat je een bestelling wil aanmaken met de volgende gegevens: <br>" +
          '<div class="columns">' +
          '<div class="column">Besteldag:<br>Product:</div>' +
          '<div class="column">' +
          this.formatDatabaseDateShort(this.form.besteldag.bestellingsdag) +
          "<br>" +
          this.form.product.naam +
          "</div>" +
          "</div>" +
          '<div class="columns">' +
          '<div class="column">Klanten:<br>Producten:<br>Emails te versturen:</div>' +
          '<div class="column">' +
          this.vaste_klanten.length +
          "<br>" +
          this.bestelde_soepen +
          "<br>" +
          this.emails_to_send +
          "</div>" +
          "</div>" +
          '<div class="columns">' +
          " ",

        confirmText: "Bestelling maken",
        type: "is-info",
        hasIcon: true,
        onConfirm: () => this.bestellingAanmaken(),
      });
    },
    // calculateAvailable(besteldag, product, reeds_besteld, oude_hoeveelheid) {
    //   const max = this.calculateMaxToOrder(besteldag, product);
    //   if (oude_hoeveelheid) {
    //     return max - reeds_besteld + oude_hoeveelheid;
    //   } else {
    //     return max - reeds_besteld;
    //   }
    // },
    // calculateMaxToOrder(besteldag, product) {
    //   const totaal_product_beschikbaar = product.hoeveelheid - product.besteld;
    //   return totaal_product_beschikbaar;
    // },
    // calcMaxMessage(dag) {
    //   const available = this.calculateAvailable(dag);
    //   if (available < 0) {
    //     return 0;
    //   } else {
    //     return available;
    //   }
    // },
    bestellingAanmaken() {
      const data = {
        product: this.form.product,
        klanten: this.vaste_klanten_for_order,
      };
      this.$store
        .dispatch("bestellingen/createMassaBestelling", data)
        .then(() => {
          this.$buefy.toast.open("Bestelling aangemaakt");
          this.$store.dispatch("bestellingen/index");
          this.$router.push({ name: "bestellingen-index" });
        });
    },
    cancelUpdate() {
      this.$router.go(-1);
    },
    // findAddress() {
    //   if (!this.form.postcode) {
    //     return;
    //   }
    //   if (!this.form.huisnummer) {
    //     return;
    //   }
    //   this.editStraat = false;
    //   this.addressLoading = true;
    //   return this.findAddressFromPostalCode(
    //     this.form.postcode,
    //     this.form.huisnummer,
    //     this.form
    //   ).then(() => {
    //     this.editStraat = true;
    //     this.addressLoading = false;
    //   });
    // },
    // removeProduct(product) {
    //   this.removeUnsavedProduct(product);
    // },
    // numberInputMessage(product) {
    //   if (this.validation["products." + (product - 1) + ".hoeveelheid"]) {
    //     return this.validation["products." + (product - 1) + ".hoeveelheid"];
    //   }
    //   return (
    //     this.calculateAvailable(
    //       this.form.products[product - 1].orderday,
    //       this.form.products[product - 1].orderday_product,
    //       this.form.products[product - 1].hoeveelheid,
    //       this.form.products[product - 1].old_hoeveelheid
    //     ) + " beschikbaar."
    //   );
    // },
    // removeUnsavedProduct(product) {
    //   const array = this.form.products;
    //   const id = product.localId;
    //   const index = array.findIndex((a) => a.localId === id);
    //   array.splice(index, 1);
    // },
    // removeSavedProduct(product) {
    //   const array = this.form.products;
    //   const id = product.id;
    //   const index = array.findIndex((a) => a.id === id);
    //   array.splice(index, 1);
    // },
    // searchCustomers(zoekwaarde) {
    //   this.klant.loading = true;
    //   this.$store
    //     .dispatch("bestellingen/zoekKlanten", this.klant.zoekwaarde)
    //     .then(() => {
    //       this.klant.loading = false;
    //     })
    //     .catch(() => {
    //       this.klant.loading = false;
    //     });
    // },
    // setCustomer(customer) {
    //   this.klant_gevonden = true;
    //   Vue.set(this.form, "customer_id", customer.id);
    //   Vue.set(this.form, "voornaam", customer.voornaam);
    //   Vue.set(this.form, "achternaam", customer.achternaam);
    //   Vue.set(this.form, "email", customer.email);
    //   Vue.set(this.form, "telefoonnummer", customer.telefoonnummer);
    //   Vue.set(this.form, "postcode", customer.postcode);
    //   Vue.set(this.form, "huisnummer", customer.huisnummer);
    //   Vue.set(this.form, "straat", customer.straat);
    //   Vue.set(this.form, "plaats", customer.plaats);
    // },
  },
  mounted() {
    this.$store.dispatch("bestellingen/getBesteldagen");
    this.$store.dispatch("bestellingen/getKlanten");
  },
};
</script>
