<template>
  <router-link
    @click.native="routeClicked()"
    :exact="link.exact"
    class="navbar-item"
    :to="{ name: link.routerLink }"
  >
    {{ link.naam }}
  </router-link>
</template>

<script>
export default {
  components: {},
  props: {
    link: {
      required: true,
      type: Object
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    routeClicked() {
      this.$emit("closedMobileMenu", true);
    }
  },
  mounted() {}
};
</script>
