<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Bestellingsdag aanpassen</div>
    <form @submit.prevent="aanpassen()">
      <br />
      <br />

      <div class="columns"></div>

      <formDatePicker
        label="on-border"
        :model="form"
        modelKey="bestellingsdag"
        placeholder="DD-MM-JJJJ"
        :min-date="minBestellingsDate"
      />
      <br />

      <div class="title is-5">Opening en sluiting van inschrijvingen</div>

      <div class="columns">
        <div class="column">
          <formDatePicker
            label="on-border"
            :model="form"
            title="bestellings
          open vanaf"
            modelKey="bestelling_openings_datum"
            placeholder="DD-MM-JJJJ"
          />
        </div>

        <div class="column">
          <formTimePicker
            label="on-border"
            :model="form"
            placeholder="23:45"
            title="bestellings
          open vanaf"
            modelKey="bestelling_openings_tijdstip"
          />
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <formDatePicker
            label="on-border"
            :model="form"
            title="bestelling gesloten vanaf"
            modelKey="bestelling_sluitings_datum"
            placeholder="DD-MM-JJJJ"
          />
        </div>
        <div class="column">
          <formTimePicker
            label="on-border"
            :model="form"
            placeholder="23:45"
            title="bestelling gesloten vanaf"
            modelKey="bestelling_sluitings_tijdstip"
          />
        </div>
      </div>

      <div class="title is-5">
        Zichtbaar en onzichtbaar maken van bestelling
      </div>

      <div class="columns">
        <div class="column">
          <formDatePicker
            label="on-border"
            :model="form"
            title="bestellings
          zichtbaar vanaf"
            modelKey="bestelling_zichtbaar_datum"
            placeholder="DD-MM-JJJJ"
          />
        </div>
        <div class="column">
          <formTimePicker
            label="on-border"
            :model="form"
            placeholder="23:45"
            title="bestellings
          zichtbaar vanaf"
            modelKey="bestelling_zichtbaar_tijdstip"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <formDatePicker
            label="on-border"
            :model="form"
            title="bestellings
          onzichtbaar vanaf"
            modelKey="bestelling_onzichtbaar_datum"
            placeholder="DD-MM-JJJJ"
          />
        </div>
        <div class="column">
          <formTimePicker
            label="on-border"
            :model="form"
            placeholder="23:45"
            title="bestellings
          onzichtbaar vanaf"
            modelKey="bestelling_onzichtbaar_tijdstip"
          />
        </div>
      </div>

      <numberInput
        class="customInput"
        :model="form"
        modelKey="maximale_producten_per_klant"
        title="Maximale producten per klant"
        label="on-border"
        placeholder="4"
      />

      <b-field>
        <b-switch v-model="form.inschrijving_geopend"
          >Bestelling <span v-if="form.inschrijving_geopend">open</span
          ><span v-else>gesloten</span> voor nieuwe bestellingen</b-switch
        >
      </b-field>

      <b-field>
        <b-switch v-model="form.zichtbaar"
          >Bestelling <span v-if="form.zichtbaar">zichtbaar</span
          ><span v-else>onzichtbaar</span> voor bestellers</b-switch
        >
      </b-field>

      <hr />

      <span v-for="product in form.products.length" :key="product">
        <div class="columns">
          <div class="column">
            <formSelect
              class="customInput"
              modelKey="naam"
              label="on-border"
              :model="form.products[product - 1]"
              placeholder="Selecteer product"
              :selectOptions="
                products.filter((a) => a.status === 'beschikbaar')
              "
              selectKey="naam"
              :validationKey="'products.' + (product - 1) + '.naam'"
              :extraValidationKey="'products.' + (product - 1) + '.naam.id'"
              selectValue="id"
            />
          </div>
          <div class="column">
            <numberInput
              :model="form.products[product - 1]"
              modelKey="hoeveelheid"
              title="Totaal beschikbaar"
              label="on-border"
              placeholder="30"
              :validationKey="'products.' + (product - 1) + '.hoeveelheid'"
            />
          </div>

          <div class="column">
            <div
              v-if="
                form.products[product - 1]['besteld'] === 0 ||
                form.products[product - 1].localId
              "
              @click="removeProduct(form.products[product - 1])"
              class="button is-danger is-outlined is-fullwidth"
            >
              Verwijderen
            </div>
          </div>
        </div>
      </span>

      <hr />

      <div
        @click="form.products.push({ localId: form.products.length + 1 })"
        class="button is-primary is-fullwidth"
      >
        Product toevoegen
      </div>

      <br />

      <b-button
        v-if="form.products.length"
        native-type="submit"
        :loading="ajaxloading.edit_orderday"
        type="is-primary is-rounded"
        >Aanpassen</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="cancelUpdate()">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    const today = new Date();

    return {
      today: new Date(),
      minBestellingsDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),
      form: {
        id: 0,
        status: true,
        maximale_producten_per_klant: 1,
        products: [],
        zichtbaar: false,
        inschrijving_geopend: false,
        bestelling_openings_tijdstip: null,
      },
    };
  },
  computed: {
    products() {
      return this.$store.getters["soep/index"];
    },
    bestellingsdagen() {
      return this.$store.getters["bestellingsdagen/index"];
    },
    dag() {
      return this.bestellingsdagen.find(
        (a) => a.id == this.$route.params.orderday
      );
    },
  },
  methods: {
    aanpassen() {
      this.$store.dispatch("bestellingsdagen/edit", this.form).then(() => {
        this.$buefy.toast.open("Bestelling aangepast");
        this.$router.push({ name: "bestellingsdagen-index" });
      });
    },
    cancelUpdate() {
      this.$store.dispatch("bestellingsdagen/refresh", this.form);
      this.$router.go(-1);
    },
    removeProduct(product) {
      if (product.id) {
        this.$buefy.dialog.confirm({
          title: "Product verwijderen",
          message: "Weet je zeker dat je dit product wil verwijderen?",
          confirmText: "Product verwijderen",
          type: "is-danger",
          hasIcon: true,
          onConfirm: function () {
            product.localId = product.id;

            if (!this.form.deleted_products) {
              this.form.deleted_products = [];
            }
            this.form.deleted_products.push(product);
            this.removedSavedProduct(product);
          }.bind(this),
        });
      } else {
        this.removeUnsavedProduct(product);
      }
    },
    removedSavedProduct(product) {
      const array = this.form.products;
      const id = product.localId;
      const index = array.findIndex((a) => a.localId === id);
      array.splice(index, 1);
    },
    removeUnsavedProduct(product) {
      const array = this.form.products;
      const id = product.localId;
      const index = array.findIndex((a) => a.localId === id);
      array.splice(index, 1);
    },
  },
  mounted() {
    this.$store.dispatch("soep/index").then(() => {
      if (this.dag) {
        this.form = this.dag;
        for (let orderdayproduct of this.form.products) {
          let product = this.products.find(
            (a) => a.id === orderdayproduct.product_id
          );
          if (product) {
            orderdayproduct.naam = product;
          }
        }
      } else {
        this.$router.go(-1);
      }
    });
  },
};
</script>
