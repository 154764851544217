var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('aside',{staticClass:"menu"},[_c('ul',{staticClass:"menu-list"},[_c('li',[(['superuser', 'beheerder'].includes(_vm.user.rol))?_c('router-link',{attrs:{"tag":"a","exact":"","to":{ name: 'admin' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("Website")]):_vm._e()],1),_c('li',[(
            ['soep_coordinator', 'superuser', 'beheerder'].includes(_vm.user.rol)
          )?_c('router-link',{attrs:{"tag":"a","to":{ name: 'bezorglijsten-index' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("Leverdagen")]):_vm._e()],1),_c('li',[(
            ['soep_coordinator', 'superuser', 'beheerder'].includes(_vm.user.rol)
          )?_c('router-link',{attrs:{"tag":"a","to":{ name: 'bestellingen-index' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("Bestellingen")]):_vm._e()],1),_c('li',[(
            ['soep_coordinator', 'superuser', 'beheerder'].includes(_vm.user.rol)
          )?_c('router-link',{attrs:{"tag":"a","to":{ name: 'bestellingsdagen-index' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("Besteldagen")]):_vm._e()],1),_c('li',[(
            ['soep_coordinator', 'superuser', 'beheerder'].includes(_vm.user.rol)
          )?_c('router-link',{attrs:{"tag":"a","to":{ name: 'soep-index' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("Soepen")]):_vm._e()],1),_c('li',[(
            ['soep_coordinator', 'superuser', 'beheerder'].includes(_vm.user.rol)
          )?_c('router-link',{attrs:{"tag":"a","to":{ name: 'postcode-index' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("Postcodes")]):_vm._e()],1),_c('li',[(
            ['soep_coordinator', 'superuser', 'beheerder'].includes(_vm.user.rol)
          )?_c('router-link',{attrs:{"tag":"a","to":{ name: 'blacklist-index' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("Blacklist")]):_vm._e()],1),_c('li',[(
            ['soep_coordinator', 'superuser', 'beheerder'].includes(_vm.user.rol)
          )?_c('router-link',{attrs:{"tag":"a","to":{ name: 'klanten-index' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("Klanten")]):_vm._e()],1),_c('li',[(['superuser', 'beheerder'].includes(_vm.user.rol))?_c('router-link',{attrs:{"tag":"a","to":{ name: 'admin-gebruikers' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("Gebruikers")]):_vm._e()],1),_c('hr'),_c('li',[(['superuser', 'beheerder'].includes(_vm.user.rol))?_c('router-link',{attrs:{"tag":"a","to":{ name: 'admin-foto-upload' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("Foto uploaden")]):_vm._e()],1),_c('li',[(['superuser', 'beheerder'].includes(_vm.user.rol))?_c('router-link',{attrs:{"tag":"a","to":{ name: 'admin-fotos' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("Fotos")]):_vm._e()],1),_c('li',[(['superuser', 'beheerder'].includes(_vm.user.rol))?_c('router-link',{attrs:{"tag":"a","to":{ name: 'admin-contactformulier' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("ContactFormulier")]):_vm._e()],1),_c('hr'),_c('li',[(['superuser', 'beheerder'].includes(_vm.user.rol))?_c('router-link',{attrs:{"tag":"a","to":{ name: 'settings-index' }},on:{"click":function($event){return _vm.closeMobile()}}},[_vm._v("Instellingen")]):_vm._e()],1),_c('li',[_c('a',{on:{"click":function($event){return _vm.logout()}}},[_vm._v("Uitloggen")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }