export const index = function ({ dispatch, commit }, payload) {
  const data = {};
  if (payload) {
    data.url = payload;
    data.fullUrl = true;
  } else {
    data.url = "get_customers";
  }
  data.loading = "get_index";
  data.method = "GET";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setIndex", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const search = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "search_customers/" + payload.zoekwaarde;
  data.loading = "search";
  data.method = "get";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setIndex", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

// export const create = function ({ dispatch, commit }, payload) {
//   const data = {};
//   data.url = "create_orderday";
//   data.method = "POST";
//   data.data = payload;
//   return dispatch("axiosAdmin", data, { root: true })
//     .then((response) => {
//       commit("setNewItem", response.data);
//       return Promise.resolve(response);
//     })
//     .catch((error) => {
//       return Promise.reject(error);
//     });
// };

export const edit = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "edit_customer/" + payload.id;
  data.loading = "edit";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

// export const destroy = function ({ dispatch, commit }, payload) {
//   const data = {};
//   data.url = "destroy_order/" + payload.id;
//   data.method = "POST";
//   data.data = payload;
//   return dispatch("axiosAdmin", data, { root: true })
//     .then((response) => {
//       commit("deleteItem", payload);
//       return Promise.resolve(response);
//     })
//     .catch((error) => {
//       return Promise.reject(error);
//     });
// };

export const refresh = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "refresh_customer/" + payload.id;
  data.method = "get";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
