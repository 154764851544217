<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.addressBox {
  margin-bottom: 10px;
}

.no-margin {
  margin: 0px;
  padding: 0px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Bezorglijst aanmaken</div>
    <form @submit.prevent="toevoegen()">
      <br />
      <br />

      Bestellingsdag:
      {{ formatDatePickerDate(orderday.bestellingsdag) }}

      <br />
      <br />
      <formInput
        class="customInput"
        :model="form"
        modelKey="naam"
        label="on-border"
        placeholder="Naam voor route"
      />

      <formInput
        class="customInput"
        :model="form"
        title="Link voor routebeschrijving"
        modelKey="route_link"
        label="on-border"
        placeholder="https://maps.google.com/xxxxxxx"
      />

      <formSelect
        class="customInput"
        :model="form"
        modelKey="bezorger"
        label="on-border"
        placeholder="Kies bezorger"
        :selectOptions="actieve_gebruikers"
        selectKey="name"
      />

      <br />

      <div class="columns no-margin">
        <div class="column is-3 no-margin">Bezorgaddressen</div>
        <div class="column no-margin">{{ toegewezen_orders.length }}</div>
      </div>
      <div class="columns no-margin">
        <div class="column is-3 no-margin">Producten te bezorgen</div>
        <div class="column no-margin">{{ te_bezorgen_producten }}</div>
      </div>

      <p v-if="validation.adressen" class="help is-danger">
        {{ validation.adressen[0] }}
      </p>

      <br />

      <b-button
        :disabled="form.adressen.length < 1"
        native-type="submit"
        :loading="ajaxloading.create_blacklist"
        type="is-primary is-rounded"
        >Toevoegen</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>

      <br />
      <br />

      <div class="title is-5">Adressen</div>

      <div class="columns">
        <div class="column">
          <div class="subtitle">
            Toe te wijzen adressen ({{ niet_toegewezen_orders.length }})
          </div>
          <span v-for="order in niet_toegewezen_orders" :key="order.id">
            <div class="box addressBox">
              <div class="columns">
                <div class="column is-8">
                  <a @click="createGoogleLink(order.order)">
                    {{ order.order.voornaam }} {{ order.order.achternaam }}
                    <br />
                    {{ order.order.straat }} {{ order.order.huisnummer
                    }}{{ order.order.huisnummer_extension }} <br />
                    {{ order.order.postcode }} {{ order.order.plaats }}
                  </a>
                  <br />
                  Producten: {{ calcOrderProducts(order) }}
                </div>
                <div class="column">
                  <br />
                  <button
                    class="button is-rounded is-success is-pulled-right is-small"
                    @click.prevent="addOrderToAddressen(order)"
                  >
                    toevoegen
                  </button>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div class="column">
          <div class="subtitle">
            Toegewezen adressen ({{ toegewezen_orders.length }})
          </div>
          <span v-for="order in toegewezen_orders" :key="order.id">
            <div class="box addressBox">
              <div class="columns">
                <div class="column is-8">
                  <div class="">
                    <a @click="createGoogleLink(order.order)">
                      {{ order.order.voornaam }} {{ order.order.achternaam }}
                      <br />
                      {{ order.order.straat }} {{ order.order.huisnummer
                      }}{{ order.order.huisnummer_extension }} <br />
                      {{ order.order.postcode }} {{ order.order.plaats }}
                    </a>
                    <br />
                    Producten: {{ calcOrderProducts(order) }}
                  </div>
                </div>
                <div class="column">
                  <br />
                  <button
                    class="button is-rounded is-danger is-pulled-left is-small"
                    @click.prevent="removeOrderFromAdressen(order)"
                  >
                    verwijderen
                  </button>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>

      <b-button
        native-type="submit"
        :loading="ajaxloading.create_blacklist"
        type="is-primary is-rounded"
        >Toevoegen</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        id: 0,
        status: "te bezorgen",
        adressen: [],
      },
    };
  },
  computed: {
    orderday() {
      return this.$store.getters["bestellijsten/orderday"];
    },
    orders() {
      return this.$store.getters["bestellijsten/orders"].filter(
        (a) => a.order.bezorg_optie === "bezorgen"
      );
    },
    gebruikers() {
      return this.$store.getters["adminUsers/users"];
    },
    actieve_gebruikers() {
      return this.gebruikers.filter((a) => a.status === "actief");
    },
    niet_toegewezen_orders() {
      const orders = [];
      for (let order of this.orders) {
        if (!this.form.adressen.includes(order)) {
          orders.push(order);
        }
      }
      return orders;
    },
    toegewezen_orders() {
      const orders = [];
      for (let order of this.orders) {
        if (this.form.adressen.includes(order)) {
          orders.push(order);
        }
      }
      return orders;
    },
    te_bezorgen_producten() {
      var producten = 0;
      for (let order of this.toegewezen_orders) {
        for (let product of order.products) {
          producten += product.hoeveelheid;
        }
      }

      return producten;
    },
  },
  methods: {
    calcOrderProducts(order) {
      var producten = 0;

      for (let product of order.products) {
        producten += product.hoeveelheid;
      }

      return producten;
    },
    createGoogleLink(order) {
      const street = order.straat.replace(" ", "+");
      var housenumber = order.huisnummer;
      if (order.huisnummer_extension) {
        housenumber += order.huisnummer_extension;
      }

      window.open(
        "https://maps.google.com/?q=" +
          street +
          "," +
          order.plaats +
          "," +
          housenumber
      );
    },
    addOrderToAddressen(order) {
      order.id + this.form.adressen.length + 1;
      this.form.adressen.push(order);
    },
    removeOrderFromAdressen(order) {
      const array = this.form.adressen;
      const id = order.id;
      const index = array.findIndex((a) => a.id === id);
      array.splice(index, 1);
    },
    toevoegen() {
      this.form.orderday = this.orderday.id;
      if (this.form.bezorger && this.form.bezorger.id) {
        this.form.bezorger = this.form.bezorger.id;
      }
      this.$store
        .dispatch("bestellijsten/createDeliveryList", this.form)
        .then(() => {
          this.$router.go(-1);
        });
    },
  },
  mounted() {
    this.$store.dispatch("adminUsers/getUsers");
  },
};
</script>
