import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import state from "./state";
import * as mutations from "./mutations";
import * as getters from "./getters";
import * as actions from "./actions";

import auth from "@/store/auth";
import all_settings from "@/store/admin/settings";
import checkout from "@/store/checkout";
import adminUsers from "@/store/admin/users";
import postalCodes from "@/store/admin/postalCodes";
import blacklist from "@/store/admin/blacklist";
import soep from "@/store/admin/soep";
import bestellingsdagen from "@/store/admin/bestellingsdagen";
import bestellingen from "@/store/admin/bestellingen";
import klanten from "@/store/admin/klanten";
import bestellijsten from "@/store/admin/bestellijsten";
import statistics from "@/store/admin/statistics";

export default new Vuex.Store({
  modules: {
    checkout,
    all_settings,
    auth,
    adminUsers,
    statistics,
    bestellijsten,
    klanten,
    bestellingen,
    bestellingsdagen,
    soep,
    blacklist,
    postalCodes,
  },
  state,
  mutations,
  getters,
  actions,
});
