<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Sociale Spelen</div>
          Na de dagopening beginnen wij met sociale spelen of sport en spel. We
          geloven er in dat het een goede manier is om in je lijf te komen en om
          elkaar én jezelf te leren kennen. De sociale spelen leggen de nadruk
          op sociale vaardigheden; je wordt je bewust van welke rol je inneemt
          in een groep, leert samenwerken en communiceren en oefent met
          vertrouwen krijgen in jezelf en de ander. Ook bij sport en spel komen
          deze vaardigheden natuurlijk kijken, een potje voetballen,
          basketballen of volleybal doen we graag.
        </div>
      </div>
      <div class="column">
        <carrousel :page="'werkplaatsen_sport'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
