export const index = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get_orderdays";
  data.loading = "get_index";
  data.method = "GET";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setIndex", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const create = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create_orderday";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setNewItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const edit = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "edit_orderday/" + payload.id;
  data.loading = "edit_orderday";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const destroy = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "destroy_orderday/" + payload.id;
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("deleteItem", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const refresh = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "refresh_orderday/" + payload.id;
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const visibleToggle = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "edit_orderday_visible/" + payload.selected.id;
  data.loading = "edit_orderday";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      payload.selected.zichtbaar = payload.action;
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const openToggle = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "edit_orderday_open/" + payload.selected.id;
  data.loading = "edit_orderday";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      payload.selected.inschrijving_geopend = payload.action;
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
