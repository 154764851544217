<style scoped>
.navbar-menu.is-active {
  position: absolute;
  width: 100%;
}
</style>

<template>
  <div>
    <nav
      class="navbar is-primary"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="container">
        <div class="navbar-brand">
          <a>
            <!-- <img src="@/assets/logo/HDB-Logo.png" width="112" height="28" /> -->
          </a>

          <a
            @click="toggleMobile()"
            role="button"
            class="navbar-burger"
            aria-label="menu"
            aria-expanded="true"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div :class="['navbar-menu', { 'is-active': showMobile }]">
          <div class="navbar-start">
            <singleLink
              :link="{ naam: 'Home', routerLink: 'home', exact: true }"
              v-on:closedMobileMenu="closeMobile()"
            ></singleLink>

            <dropDownLink
              :showMobileParent="showMobile"
              v-on:closedMobileMenu="closeMobile()"
              :link="{
                naam: 'Over ons',
                routerLink: 'over-ons',
                exact: true,
                children: [
                  {
                    naam: 'Algemeen',
                    routerLink: 'over-ons',
                    exact: true,
                  },
                  {
                    naam: 'Visie',
                    routerLink: 'visie',
                  },
                  {
                    naam: 'Geschiedenis',
                    routerLink: 'geschiedenis',
                  },
                  {
                    naam: 'Grondsteenspreuk',
                    routerLink: 'grondsteenspreuk',
                  },

                  {
                    naam: 'Organisatie',
                    routerLink: 'zakelijke-informatie',
                  },
                ],
              }"
            ></dropDownLink>

            <singleLink
              :link="{ naam: 'Dagprogramma', routerLink: 'dagbesteding' }"
              v-on:closedMobileMenu="closeMobile()"
            ></singleLink>

            <singleLink
              :link="{ naam: 'Beschermd Wonen', routerLink: 'beschermd-wonen' }"
              v-on:closedMobileMenu="closeMobile()"
            ></singleLink>

            <dropDownLink
              :showMobileParent="showMobile"
              v-on:closedMobileMenu="closeMobile()"
              :link="{
                naam: 'Werkplaatsen',
                routerLink: 'werkplaatsen',
                exact: true,
                children: [
                  {
                    naam: 'Alle werkplaatsen',
                    routerLink: 'werkplaatsen',
                    exact: true,
                  },
                  {
                    naam: 'Sport & spel',
                    routerLink: 'sport-en-spel',
                  },
                  {
                    naam: 'Het leerhuis',
                    routerLink: 'het-leerhuis',
                  },
                  {
                    naam: 'Horeca',
                    routerLink: 'horeca',
                  },
                  {
                    naam: 'Buiten',
                    routerLink: 'buiten',
                  },
                  {
                    naam: 'Kunst',
                    routerLink: 'kunst',
                  },
                  {
                    naam: 'Huis en haard',
                    routerLink: 'gast-aan-tafel',
                  },
                ],
              }"
            ></dropDownLink>

            <singleLink
              :link="{
                naam: 'Dagelijkse soep',
                routerLink: 'dagelijkse-soep-landing',
              }"
              v-on:closedMobileMenu="closeMobile()"
            ></singleLink>

            <dropDownLink
              :showMobileParent="showMobile"
              v-on:closedMobileMenu="closeMobile()"
              :link="{
                naam: 'Meedoen',
                routerLink: 'meedoen',
                exact: true,
                children: [
                  {
                    naam: 'Doe mee',
                    routerLink: 'meedoen',
                    exact: true,
                  },
                  {
                    naam: 'Werken bij',
                    routerLink: 'werken-bij',
                  },
                  {
                    naam: 'Stage lopen',
                    routerLink: 'stage-lopen',
                  },
                  {
                    naam: 'Vrijwilligers',
                    routerLink: 'vrijwilligers',
                  },
                  {
                    naam: 'Woongemeenschap',
                    routerLink: 'woongemeenschap',
                  },
                  {
                    naam: 'Donaties',
                    routerLink: 'donaties',
                  },
                  {
                    naam: 'Vrienden van',
                    routerLink: 'vrienden-van',
                  },
                ],
              }"
            ></dropDownLink>

            <singleLink
              v-on:closedMobileMenu="closeMobile()"
              :link="{ naam: 'Contact', routerLink: 'contact' }"
            ></singleLink>

            <singleLink
              v-if="user"
              v-on:closedMobileMenu="closeMobile()"
              :link="{ naam: 'Admin', routerLink: 'admin' }"
            ></singleLink>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import singleLink from "@/views/Components/SingleLink.vue";
import dropDownLink from "@/views/Components/DropDownLink.vue";

export default {
  components: {
    singleLink,
    dropDownLink,
  },
  props: [],
  data() {
    return {
      showMobile: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    toggleMobile() {
      if (this.showMobile) {
        this.showMobile = false;
      } else {
        this.showMobile = true;
      }
    },
    closeMobile() {
      this.showMobile = false;
    },
  },
  mounted() {},
};
</script>
