<template>
  <b-field
    :label="hoofdletter(computedTitle)"
    :label-position="label"
    :type="{ 'is-danger': computedValidation }"
    :message="computedValidation"
  >
    <b-select
      :size="size"
      v-if="computedSelectKey"
      :placeholder="placeholder"
      expanded
      @input="clearValidationError()"
      v-model="model[modelKey]"
    >
      <option v-for="option of selectOptions" :key="option.id" :value="option">
        <span v-if="formatDate">
          {{ formatDatabaseDateShort(option[selectKey]) }}
        </span>
        <span v-else>
          {{ option[selectKey] }}
        </span>
      </option>
    </b-select>

    <b-select
      :size="size"
      v-else
      :placeholder="placeholder"
      expanded
      @input="clearValidationError()"
      v-model="model[modelKey]"
    >
      <option v-for="option of selectOptions" :key="option" :value="option">
        {{ option }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    model: {
      required: true,
      type: Object,
    },
    modelKey: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
    },
    size: {
      default: "is-normal",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    type: {
      default: "input",
      type: String,
    },
    selectOptions: {
      required: true,
      type: Array,
    },
    selectKey: {
      type: String,
    },
    selectValue: {
      type: String,
    },
    extraValidationKey: {
      type: String,
    },
    validationKey: {
      type: String,
    },
    formatDate: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedSelectKey() {
      if (this.selectKey) {
        return true;
      } else {
        return false;
      }
    },
    computedValidationKey() {
      if (this.validationKey) {
        return this.validationKey;
      } else {
        return this.modelKey;
      }
    },
    computedValidation() {
      if (this.extraValidationKey && this.validation[this.extraValidationKey]) {
        return this.validation[this.extraValidationKey];
      }
      return this.validation[this.computedValidationKey];
    },
  },
  methods: {
    clearValidationError() {
      this.$emit("change");
      this.$store.dispatch("clearValidationError", this.computedValidation);
      this.$store.dispatch("clearValidationError", this.computedValidationKey);
    },
  },
  mounted() {},
};
</script>
