<style scoped>
.customTitle {
  font-weight: bold;
}

.customBox {
  border-style: solid;
}
</style>

<template>
  <div>
    <quote quote="Altijd blijven dromen"></quote>
    <br />
    <div class="columns">
      <div class="column is-6">
        <div class="title is-5">
          Ja, ik help mee en doe een eenmalige donatie!
          <br />
          <br />
          <b-field
            :type="{ 'is-danger': validation['bedrag'] }"
            :message="validation['bedrag']"
            grouped
            group-multiline
            label="Bedrag:"
          >
            <p class="control">
              <b-button
                rounded
                :class="{ 'is-primary': form.bedrag === 5 }"
                @click.native="setBedrag(5)"
                expanded
                label="€ 5,-"
              />
            </p>
            <p class="control">
              <b-button
                rounded
                :class="{ 'is-primary': form.bedrag === 10 }"
                @click.native="setBedrag(10)"
                expanded
                label="€ 10,-"
              />
            </p>
            <p class="control">
              <b-button
                rounded
                :class="{ 'is-primary': form.bedrag === 25 }"
                @click.native="setBedrag(25)"
                expanded
                label="€ 25,-"
              />
            </p>
            <p class="control">
              <b-button
                rounded
                :class="{ 'is-primary': show_bedrag_input }"
                @click.native="showCustombedrag()"
                expanded
                label="Ander bedrag"
              />
            </p>
          </b-field>
          <formInput
            v-if="show_bedrag_input"
            title="Ander bedrag"
            :model="form"
            modelKey="bedrag"
          />
        </div>

        <b-field label="Betaalmethode:">
          <b-button rounded class="is-primary" label="iDeal" />
        </b-field>

        <div class="title is-5">Mijn gegevens</div>

        <formCheckbox
          :model="form"
          :labelHidden="true"
          modelKey="anoniem"
          title="Ik wil anoniem doneren"
        />

        <span v-if="!form.anoniem">
          <formCheckbox
            :model="form"
            :labelHidden="true"
            modelKey="zakelijk"
            title="Ik wil zakelijk doneren"
          />

          <div class="columns" v-if="form.zakelijk">
            <div class="column">
              <formInput
                label="on-border"
                placeholder="Het Dagelijks Bestaan"
                :model="form"
                modelKey="bedrijfsnaam"
              />
            </div>
          </div>

          <div class="columns" v-if="!form.zakelijk">
            <div class="column">
              <formInput
                label="on-border"
                title="Voornaam"
                placeholder="Julia"
                :model="form"
                modelKey="voornaam"
              />
            </div>
            <div class="column">
              <formInput
                label="on-border"
                title="Achternaam"
                placeholder="de Jong"
                :model="form"
                modelKey="achternaam"
              />
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <formInput
                label="on-border"
                placeholder="7204LA"
                :model="form"
                modelKey="postcode"
                @blur="findAddress()"
              />
            </div>
            <div class="column">
              <formInput
                label="on-border"
                placeholder="47a"
                :model="form"
                modelKey="huisnummer"
                @blur="findAddress()"
              />
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <formInput
                :loading="addressLoading"
                :model="form"
                :disabled="editStraat === false"
                modelKey="straat"
                label="on-border"
                placeholder="Vul eerst uw postcode en huisnummer in"
              />
            </div>
            <div class="column">
              <formInput
                :loading="addressLoading"
                :model="form"
                modelKey="plaats"
                label="on-border"
                :disabled="editStraat === false"
                placeholder="Vul eerst uw postcode en huisnummer in"
              />
            </div>
          </div>

          <formInput
            title="E-mail"
            :model="form"
            label="on-border"
            placeholder="julia@gmail.com"
            modelKey="email"
          />
          <br />
        </span>

        <button
          @click="donate()"
          class="button is-fullwidth is-rounded is-primary"
          :class="{ 'is-loading': ajaxloading.donate }"
        >
          Doneren
        </button>
      </div>

      <div class="column">
        <carrousel :page="'meedoen_doneren'" />
      </div>
    </div>
  </div>
</template>

<script>
import quote from "@/views/Components/Quote.vue";
export default {
  components: {
    quote,
  },
  props: [],
  data() {
    return {
      show_bedrag_input: false,
      form: {
        bedrag: 0,
        anoniem: false,
        zakelijk: false,
      },
      addressLoading: false,
      editStraat: false,
    };
  },
  computed: {},
  methods: {
    findAddress() {
      if (!this.form.postcode) {
        return;
      }

      if (!this.form.huisnummer) {
        return;
      }
      this.editStraat = false;
      this.addressLoading = true;
      return this.findAddressFromPostalCode(
        this.form.postcode,
        this.form.huisnummer,
        this.form
      ).then(() => {
        this.editStraat = true;
        this.addressLoading = false;
      });
    },
    donate() {
      if (this.form.bedrag === 0) {
        this.form.bedrag = null;
      }

      if (this.form.anoniem) {
        this.form.voornaam = null;
        this.form.achternaam = null;
        this.form.bedrijfsnaam = null;
        this.form.postcode = null;
        this.form.huisnummer = null;
        this.form.straat = null;
        this.form.plaats = null;
        this.form.email = null;
      }

      this.$store.dispatch("donate", this.form).then((response) => {
        location.assign(response.data);
      });
    },
    setBedrag(bedrag) {
      this.show_bedrag_input = false;
      this.form.bedrag = bedrag;
    },
    showCustombedrag() {
      this.show_bedrag_input = true;
      this.form.bedrag = 0;
    },
  },
  mounted() {
    if (this.$route.query && 'anoniem' in this.$route.query) {
      this.form.anoniem = true
    }
  },
};
</script>
