<template>
  <div>
    <div class="columns">
      <div
        class="column is-6 content"
        v-html="findVariable('meedoen_vrijwilligers_tekst')"
      ></div>
      <div class="column">
        <carrousel :page="'meedoen_vrijwilligers'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
