import axios from "axios";

export const axiosApi = function ({ rootGetters, commit }, payload) {
  // const token = rootGetters["auth/token"];
  // const user = rootGetters["auth/user"];

  commit("loadingOn", payload.loading);
  return axios({
    url: process.env.VUE_APP_API_URL + "/api/" + payload.url,
    method: payload.method,
    data: payload.data,
    // headers: {
    // Authorization: "Bearer " + user.id + " " + token
    // }
  })
    .then(function (response) {
      commit("loadingOff", payload.loading);
      return Promise.resolve(response);
    })
    .catch(function (error) {
      commit("loadingOff", payload.loading);
      return Promise.reject(error);
    });
};

export const axiosWeb = function ({ commit }, payload) {
  if (!payload.loading) {
    payload.loading = payload.url;
  }

  var url;

  if (payload.fullUrl) {
    url = payload.url;
  } else {
    url = process.env.VUE_APP_API_URL + "/web/" + payload.url;
  }
  commit("loadingOn", payload.loading);
  return axios({
    url: url,
    method: payload.method,
    data: payload.data,
    withCredentials: true,
  })
    .then(function (response) {
      if (!payload.disableLoadingOff) {
        commit("loadingOff", payload.loading);
      }
      return Promise.resolve(response);
    })
    .catch(function (error) {
      commit("loadingOff", payload.loading);
      return Promise.reject(error);
    });
};

export const axiosAdmin = function ({ commit }, payload) {
  if (!payload.loading) {
    payload.loading = payload.url;
  }

  var url;

  if (payload.fullUrl) {
    url = payload.url;
  } else {
    url = process.env.VUE_APP_API_URL + "/web/admin/" + payload.url;
  }

  commit("loadingOn", payload.loading);
  return axios({
    url: url,
    method: payload.method,
    data: payload.data,
    withCredentials: true,
  })
    .then(function (response) {
      commit("loadingOff", payload.loading);
      return Promise.resolve(response);
    })
    .catch(function (error) {
      commit("loadingOff", payload.loading);
      return Promise.reject(error);
    });
};

export const setValidationErrors = function ({ commit }, payload) {
  commit("setValidationErrors", payload);
};

export const clearValidationErrors = function ({ commit }) {
  commit("setValidationErrors", {});
};

export const createFlashBanner = function ({ commit, dispatch }, payload) {
  var message = String;
  var timeout = Number;

  if (typeof payload === "string") {
    message = payload;
    timeout = 2500;
  } else {
    message = payload.message;
    timeout = payload.timeout * 1000;
  }

  commit("setFlashBanner", message);
  setTimeout(function () {
    dispatch("destroyFlashBanner");
  }, timeout);
};

export const destroyFlashBanner = function ({ commit }, payload) {
  commit("setFlashBanner", null);
};

export const uploadPicture = function ({ commit, dispatch }, payload) {
  const data = {};
  data.url = "upload-picture";
  data.method = "POST";
  data.loading = "uploadingPictures";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getAllPictures = function ({ commit, dispatch }, payload) {
  const data = {};
  data.url = "get-all-pictures";
  data.method = "GET";
  data.loading = "gettingpicturesPictures";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setPictures", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getSettings = function ({ commit, dispatch }, payload) {
  const data = {};
  data.url = "get-settings";
  data.method = "GET";
  data.loading = "getSettings";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setSettings", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deletePicture = function ({ commit, dispatch }, payload) {
  const data = {};
  data.url = "delete-picture/" + payload.uuid;
  data.method = "POST";
  data.loading = "deletePicture";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("deletePicture", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const submitContactForm = function ({ commit, dispatch }, payload) {
  const data = {};
  data.url = "submit-contact-form";
  data.method = "POST";
  data.loading = "submitContactForm";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const savePictureCategory = function ({ commit, dispatch }, payload) {
  const data = {};
  data.url = "save-picture-category/" + payload.id;
  data.method = "POST";
  data.loading = "savePicture";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const clearValidationError = function ({ commit }, payload) {
  commit("clearValidationError", payload);
};

export const saveSubjects = function ({ commit, dispatch }, payload) {
  const data = {};
  data.url = "save-contactform-subjects";
  data.method = "POST";
  data.loading = "savingContactForm";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getSubjects = function ({ commit, dispatch }, payload) {
  const data = {};
  data.url = "get-contactform-subjects";
  data.method = "GET";
  data.loading = "gettingSubjects";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getLoggedInUser = function ({ commit, dispatch }, payload) {
  const data = {};
  data.url = "get-logged-in-user";
  data.method = "GET";
  data.loading = "gettingSubjects";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const loadingOn = function ({ commit }, payload) {
  commit("loadingOn", payload);
};

export const loadingOff = function ({ commit }, payload) {
  commit("loadingOff", payload);
};

export const donate = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "donate";
  data.method = "POST";
  data.data = payload;
  data.disableLoadingOff = true;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
