<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Het leerhuis</div>
          <!-- In het leerhuis kun je werken aan de stap richting school of een
          studie. We hebben de mogelijkheid om je te ondersteunen in vakken zoals 
          Engels of Nederlands. We focussen ook op de andere algemene schoolse
          vaardigheden, zodat je kunt ontdekken: welke manier van leren past er bij mij? 
          
          Wil je graag een specifiek vak oefenen? We
          kijken dan in ons netwerk en daar buiten of we iemand kunnen vinden
          die je daar in kan begeleiden. 
<br/>
<br/>
          We hebben warme banden met Het Graafschapscollege. Het is mogelijk om bij
          ons een MBO entreebewijs te behalen. -->

          <!-- <br />
          <br /> -->

          In het Leerhuis leren we van elkaar en werken we met elkaar. Ieder op
          eigen manier en op eigen niveau. <br /><br />
          Iedere werkplaats start met een gezamenlijke activiteit. De ene keer
          is dat over een onderwerp gerelateerd aan burgerschap, de andere keer
          gaat het over leren leren en weer een andere keer doen we een korte
          creatieve oefening.
          <br /><br />
          Hoe de werkplaats er daarna uitziet hangt af van jouw leervraag. Wil
          je werken aan je cv, je rekenvaardigheden bijschaven, werken aan
          Nederlands, je Engels verbeteren of wil je je verdiepen in een ander
          onderwerp? Dat kan! Samen zoeken we naar een voor jou passende
          invulling. Wil je graag een specifiek vak oefenen, waar de vaste
          Leerhuis-docent niet voldoende kennis over heeft? Dan kijken we in ons
          netwerk en daarbuiten of we iemand kunnen vinden die je daar in kan
          begeleiden.<br /><br />
          De werkplaats wordt gezamenlijk afgesloten. Wat neem je mee? Wat vond
          je lastig? Waar ben je trots op? <br /><br />
          In het Leerhuis kun je ook gericht werken aan de stap richting school
          of een studie. <br />
          We hebben warme banden met Het Graafschap College. Het is mogelijk om
          bij ons een Entree-diploma te behalen, door een Entree-opleiding te
          volgen.
        </div>
      </div>
      <div class="column">
        <carrousel :page="'werkplaatsen_hetleerhuis'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
