<style scoped>
.customtitle {
  font-weight: bold;
}

.customInput {
  padding-bottom: 8px;
}
</style>

<template>
  <div>
    <div class="columns">
      <div class="column is-8">
        <div class="box">
          <div class="bold">Contactformulier</div>
          Heb je een vraag aan Het Dagelijks Bestaan of wil je graag meer
          informatie ontvangen? <br />Vul dan onderstaand contactformulier in en
          er wordt zo spoedig mogelijk contact met je opgenomen.
          <br />
          <br />

          <formInput
            class="customInput"
            modelKey="naam"
            :model="form"
            placeholder="Naam"
            label="inside"
          />

          <b-field>
            <b-switch class="customInput" v-model="zakelijk"
              >Zakelijk contact</b-switch
            >
          </b-field>

          <formInput
            v-if="zakelijk"
            class="customInput"
            modelKey="bedrijf"
            :model="form"
            placeholder="Naam van bedrijf"
            label="inside"
          />

          <formInput
            class="customInput"
            modelKey="email"
            :model="form"
            placeholder="Email"
            label="inside"
          />

          <formInput
            class="customInput"
            modelKey="telefoonnummer"
            :model="form"
            placeholder="Telefoonnummer"
            label="inside"
          />

          <formSelect
            class="customInput"
            modelKey="onderwerp"
            label="inside"
            :model="form"
            placeholder="Kies onderwerp"
            :selectOptions="onderwerpen"
          />

          <!-- <b-field label="">
            <b-input maxlength="1000" rows="7" type="textarea"></b-input>
          </b-field> -->

          <b-field
            label="Vraag"
            label-position="inside"
            :type="{ 'is-danger': validation.vraag }"
            :message="{
              [validation.vraag]: validation.vraag,
            }"
          >
            <b-input
              v-model="form.vraag"
              type="textarea"
              maxlength="10000"
              placeholder="Omschrijving van de vraag"
              @input="clearValidationError('vraag')"
            ></b-input>
          </b-field>

          <div class="has-text-centered">
            <button
              @click="buttonClicked()"
              :class="[
                'button is-rounded is-primary',
                { 'is-loading': ajaxloading.submitContactForm },
              ]"
            >
              Verzenden
            </button>
          </div>
        </div>
      </div>
      <div class="column">
        <!-- adresblok -->
        <div class="customtitle">Stichting Het Dagelijks Bestaan</div>
        <div>
          Weg naar Vierakker 47 <br />
          7204LA Zutphen<br />
          E:
          <a href="mailto:info@hetdagelijksbestaan.nl"
            >info@hetdagelijksbestaan.nl</a
          >
        </div>
        T: 0575-529222: <br />
        <!-- &nbsp;&nbsp;&nbsp;&nbsp;Optie 1: Aanmeldingen zorg<br />
        &nbsp;&nbsp;&nbsp;&nbsp;Optie 2: Dagprogramma<br />
        &nbsp;&nbsp;&nbsp;&nbsp;Optie 3: Beschermd wonen<br />
        &nbsp;&nbsp;&nbsp;&nbsp;Optie 4: Overig<br /> -->

        <!-- contactinfo -->
        <div class="customtitle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: [],
  data() {
    return {
      zakelijk: false,
      loading: false,
      form: {},
    };
  },
  computed: {
    onderwerpen() {
      const settings = this.$store.getters["settings"];

      if (settings.onderwerpen) {
        return settings.onderwerpen;
      } else {
        return [];
      }
    },
  },
  methods: {
    buttonClicked() {
      // this.loading = true;
      // setTimeout(
      //   function() {
      //     this.loading = false;
      //     this.$router.push({ name: "contact-formulier-verzonden" });
      //   }.bind(this),
      //   2000
      // );
      this.$store.dispatch("submitContactForm", this.form).then(() => {
        this.$router.push({ name: "contact-formulier-verzonden" });
      });
    },
    clearValidationError(key) {
      this.$store.dispatch("clearValidationError", key);
    },
    findAndSetSubject() {
      if (this.$route.query && this.$route.query.onderwerp) {
        const new_onderwerp = this.$route.query.onderwerp.replaceAll("_", " ");
        const found_onderwerp = this.onderwerpen.find(
          (a) => a.toLowerCase() === new_onderwerp
        );
        if (found_onderwerp) {
          Vue.set(this.form, "onderwerp", found_onderwerp);
          this.form.onderwerp = found_onderwerp;
        }
      }
    },
  },
  mounted() {
    if (this.onderwerpen.length) {
      this.findAndSetSubject();
    } else {
      this.$store.dispatch("getSettings").then(() => {
        this.findAndSetSubject();
      });
    }
  },
};
</script>
