<style scoped>
.customInput {
  margin-bottom: 20px;
}
</style>

<template>
  <div>
    <div class="title">Website Instellingen</div>
    <br />

    <span v-if="settings && settings.admin && settings.admin.variables.length">
      <!-- soep paginas -->
      <settingsCard
        :open="open"
        :form="form"
        v-on:toggle="toggleOpen"
        v-on:save="saveVariables"
        category="soep_paginas"
        :card_settings="settings.admin.variables"
      />
      <!-- soep emails -->
      <br />
      <settingsCard
        :open="open"
        :form="form"
        v-on:toggle="toggleOpen"
        v-on:save="saveVariables"
        :showVariables="true"
        category="soep_emails"
        :card_settings="settings.admin.variables"
      />
      <br />
      <settingsCard
        :open="open"
        :form="form"
        v-on:toggle="toggleOpen"
        v-on:save="saveVariables"
        category="soep_bezorgen_en_ophalen"
        :card_settings="settings.admin.variables"
      />
      <br />
      <settingsCard
        :open="open"
        :form="form"
        v-on:toggle="toggleOpen"
        v-on:save="saveVariables"
        category="soep_contact_gegevens"
        :card_settings="settings.admin.variables"
      />
      <br />
      <settingsCard
        :open="open"
        :form="form"
        v-on:toggle="toggleOpen"
        v-on:save="saveVariables"
        category="home_tekst"
        :card_settings="settings.admin.variables"
      />

      <br />
      <settingsCard
        :open="open"
        :form="form"
        v-on:toggle="toggleOpen"
        v-on:save="saveVariables"
        category="meedoen_tekst"
        :card_settings="settings.admin.variables"
      />

      <br />
      <settingsCard
        :open="open"
        :form="form"
        v-on:toggle="toggleOpen"
        v-on:save="saveVariables"
        category="donaties"
        :showVariables="true"
        :card_settings="settings.admin.variables"
      />

      <br />

      <settingsCard
        :open="open"
        :form="form"
        v-on:toggle="toggleOpen"
        v-on:save="saveVariables"
        category="vacatures"
        :card_settings="settings.admin.variables"
      />
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import { createFlashBanner } from "@/store/actions";

export default {
  components: {},
  props: [],
  data() {
    return {
      open: "",
      form: {
        new_order_notification_monday: false,
        new_order_notification_tuesday: false,
        new_order_notification_wednesday: false,
        new_order_notification_thursday: false,
        new_order_notification_friday: false,
        new_order_notification_saturday: false,
        new_order_notification_sunday: false,
        kor: false,
        moss: false,
      },
    };
  },
  computed: {
    settings() {
      return this.$store.getters["all_settings/index"];
    },
  },
  methods: {
    saveVariables(category) {
      const closedMessage = this.form.website_closed_message;
      if (closedMessage && closedMessage.length && closedMessage.length < 8) {
        this.form.website_closed_message = "";
      }

      if (category) {
        this.form.category = category;
      }

      this.$store
        .dispatch("all_settings/saveVariables", this.form)
        .then(() => {
          this.form.category = null;
          this.open = "";
          this.$store.dispatch("getSettings");
          this.$buefy.toast.open({
            message: "Variabelen opgeslagen",
            type: "is-success",
          });
        })
        .catch((error) => {
          this.form.category = null;
          if (error.response.status === 422) {
            this.$buefy.snackbar.open({
              duration: 10000,
              message: error.response.data,
              type: "is-danger",
              queue: false,
              position: "is-top",
            });
          }
        });
    },
    setVariables() {
      const array = this.settings.variables;

      array.forEach((a) => {
        if (a.type === "boolean") {
          if (a.value == 1) {
            Vue.set(this.form, a.key, true);
          }

          if (a.value == 0) {
            Vue.set(this.form, a.key, false);
          }
        } else {
          Vue.set(this.form, a.key, a.value);
        }
      });
    },
    toggleOpen(category) {
      if (this.open === category) {
        this.open = "";
      } else {
        this.open = category;
      }
    },
  },
  mounted() {
    if (this.all_settings && this.all_settings.length) {
      this.setVariables();
    } else {
      this.$store.dispatch("all_settings/getIndex").then(() => {
        this.setVariables();
      });
    }
  },
};
</script>
