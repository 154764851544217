<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Bestellingsdag toevoegen</div>
    <form @submit.prevent="toevoegen()">
      <br />
      <br />

      <div class="columns"></div>

      <formDatePicker
        label="on-border"
        :model="form"
        modelKey="bestellingsdag"
        placeholder="DD-MM-JJJJ"
        :min-date="minBestellingsDate"
      />
      <br />

      <div class="title is-5">Opening en sluiting van inschrijvingen</div>

      <div class="columns">
        <div class="column">
          <formDatePicker
            label="on-border"
            :model="form"
            title="bestellings
          open vanaf"
            modelKey="bestelling_openings_datum"
            placeholder="DD-MM-JJJJ"
          />
        </div>
        <div class="column">
          <formTimePicker
            label="on-border"
            :model="form"
            placeholder="23:45"
            title="bestellings
          open vanaf"
            modelKey="bestelling_openings_tijdstip"
          />
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <formDatePicker
            label="on-border"
            :model="form"
            title="bestelling gesloten vanaf"
            modelKey="bestelling_sluitings_datum"
            placeholder="DD-MM-JJJJ"
          />
        </div>
        <div class="column">
          <formTimePicker
            label="on-border"
            :model="form"
            placeholder="23:45"
            title="bestelling gesloten vanaf"
            modelKey="bestelling_sluitings_tijdstip"
          />
        </div>
      </div>

      <div class="title is-5">
        Zichtbaar en onzichtbaar maken van bestelling
      </div>

      <div class="columns">
        <div class="column">
          <formDatePicker
            label="on-border"
            :model="form"
            title="bestellings
          zichtbaar vanaf"
            modelKey="bestelling_zichtbaar_datum"
            placeholder="DD-MM-JJJJ"
          />
        </div>
        <div class="column">
          <formTimePicker
            label="on-border"
            :model="form"
            placeholder="23:45"
            title="bestellings
          zichtbaar vanaf"
            modelKey="bestelling_zichtbaar_tijdstip"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <formDatePicker
            label="on-border"
            :model="form"
            title="bestellings
          onzichtbaar vanaf"
            modelKey="bestelling_onzichtbaar_datum"
            placeholder="DD-MM-JJJJ"
          />
        </div>
        <div class="column">
          <formTimePicker
            label="on-border"
            :model="form"
            placeholder="23:45"
            title="bestellings
          onzichtbaar vanaf"
            modelKey="bestelling_onzichtbaar_tijdstip"
          />
        </div>
      </div>

      <numberInput
        class="customInput"
        :model="form"
        modelKey="maximale_producten_per_klant"
        title="Maximale producten per klant"
        label="on-border"
        placeholder="4"
      />

      <b-field>
        <b-switch v-model="form.inschrijving_geopend"
          >Bestelling <span v-if="form.inschrijving_geopend">open</span
          ><span v-else>gesloten</span> voor nieuwe bestellingen</b-switch
        >
      </b-field>

      <b-field>
        <b-switch v-model="form.zichtbaar"
          >Bestelling <span v-if="form.zichtbaar">zichtbaar</span
          ><span v-else>onzichtbaar</span> voor bestellers</b-switch
        >
      </b-field>

      <hr />

      <span v-for="product in form.products.length" :key="product">
        <div class="columns">
          <div class="column">
            <formSelect
              class="customInput"
              modelKey="naam"
              label="on-border"
              :model="form.products[product - 1]"
              placeholder="Selecteer product"
              :selectOptions="
                products.filter((a) => a.status === 'beschikbaar')
              "
              selectKey="naam"
              :validationKey="'products.' + (product - 1) + '.naam'"
              :extraValidationKey="'products.' + (product - 1) + '.naam.id'"
              selectValue="id"
            />
          </div>
          <div class="column">
            <numberInput
              :model="form.products[product - 1]"
              modelKey="hoeveelheid"
              title="Totaal beschikbaar"
              label="on-border"
              placeholder="30"
              :validationKey="'products.' + (product - 1) + '.hoeveelheid'"
            />
          </div>

          <div class="column">
            <div
              @click="removeProduct(form.products[product - 1])"
              class="button is-danger is-outlined is-fullwidth"
            >
              Verwijderen
            </div>
          </div>
        </div>
      </span>

      <hr />

      <div
        @click="form.products.push({ id: form.products.length + 1 })"
        class="button is-primary is-fullwidth"
      >
        Product toevoegen
      </div>

      <br />

      <b-button
        v-if="form.products.length"
        native-type="submit"
        :loading="ajaxloading.create_orderday"
        type="is-primary is-rounded"
        >Toevoegen</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    const today = new Date();

    return {
      today: new Date(),
      minBestellingsDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),
      form: {
        id: 0,
        status: true,
        maximale_producten_per_klant: 1,
        products: [],
        zichtbaar: false,
        inschrijving_geopend: false,
        bestelling_openings_tijdstip: null,
      },
    };
  },
  computed: {
    products() {
      return this.$store.getters["soep/index"];
    },
  },
  methods: {
    toevoegen() {
      this.$store.dispatch("bestellingsdagen/create", this.form).then(() => {
        this.$buefy.toast.open("Bestelling aangemaakt");
        this.$router.push({ name: "bestellingsdagen-index" });
      });
    },
    removeProduct(product) {
      const array = this.form.products;
      const id = product.id;
      const index = array.findIndex((a) => a.id === id);
      array.splice(index, 1);
    },
  },
  mounted() {
    this.$store.dispatch("soep/index");
  },
};
</script>
