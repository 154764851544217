<template>
  <div>
    <quote
      quote="Je leeft niet alleen voor jezelf, alles wat je doet heeft invloed op het
      grotere geheel. Iedereen heeft iets te doen in de wereld, iedereen heeft
      verantwoordelijkheid voor zijn omgeving. Ieder mens heeft zijn eigen
      levens verhaal, samen schrijven wij ons verhaal."
    ></quote>
    <br />
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Organisatiestructuur</div>
          Alle betrokkenen bij Het Dagelijks Bestaan zijn onderling verbonden
          door het maatschappelijke doel van de stichting. Zingeving staat voor
          iedereen centraal: jongeren, medewerkers, vrijwilligers en vrienden.
          Het sociale netwerk van Het Dagelijks Bestaan staat bekend om zijn
          openheid en gastvrijheid. Iedereen die wil bijdragen is welkom! Het
          Dagelijks Bestaan kent een platte organisatiestructuur waarin
          belangrijke beslissingen gezamenlijk worden voorbereid, waarna het
          bestuur het besluit formaliseert. Operationele beslissingen worden zo
          laag mogelijk in de organisatie genomen. Gelijkwaardigheid en
          autonomie van medewerkers zijn belangrijke waarden. Doel is een
          effectieve, flexibele organisatie waarin medewerkers zich kunnen
          ontplooien. Een deel van het werk bij Het Dagelijks Bestaan berust op
          vrijwillige inzet. Dit geldt uiteraard voor de vrijwilligers, maar
          deels ook voor de vaste groep van medewerkers. De beloningsstructuur
          is gelijkwaardig van opzet: de honorering kent geen grote verschillen.
          <br />
          <br />
          De Stichting Het Dagelijks Bestaan positioneert zich op het snijvlak
          van jeugdhulpverlening, sociale activering, zingeving, onderwijs en
          arbeid. Het Dagelijks Bestaan stelt zich ten doel om jongeren tussen
          14 en 27 jaar een kleinschalig leer- en ontwikkeltraject te bieden en
          hen te begeleiden op de weg terug naar onderwijs en/of werk en/of
          (langdurige) hulpverlening. <br />
          Het Dagelijks Bestaan heeft als stichting de status van een algemeen
          nut beogende instelling (ANBI)
          <br />
          <br />
          Voor meer informatie zie:
          <a
            href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/zakelijk/bijzondere_regelingen/goede_doelen/algemeen_nut_beogende_instellingen/welke_belastingvoordelen_heeft_een_anbi"
            >www.belastingdienst/anbi belastingvoordelen</a
          >
          <br />
          <br />
          <div class="bold">Klachtenprocedure</div>
          Stichting Het Dagelijks Bestaan streeft ernaar de begeleiding zo goed
          mogelijk te laten verlopen. Toch kan het voorkomen dat je niet
          tevreden bent over de gang van zaken. Mocht dit zich voordoen dan
          hebben wij een klachtenprocedure.<br />
          <a @click="$router.push({ name: 'klachtenprocedure' })"
            >Klik hier voor meer informatie.</a
          >
        </div>
      </div>
      <div class="column">
        <carrousel :page="'overons_organisatie'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
