<template>
  <div>
    <div class="has-text-centered">
      <div class="box">
        <div class="bold">Grondsteenspreuk</div>
        <div class="italic">
          We komen samen rondom een initiatief <br />waarin we de noodzaak
          erkennen
          <br />
          om heel te maken wat verdeeld is geraakt. <br />Dit noemen we Het
          Dagelijks Bestaan.

          <br />
          <br />
          Ieder mens beschikt over onuitputtelijke talenten <br />
          en draagt een uniek levensmotief met zich mee.<br />
          Liefdevol wordt men hier uitgedaagd<br />
          om dit aan te raken.
          <br />
          <br />
          In de omgang met de kunsten wordt de verwondering gewekt (het gevoel).
          <br />
          Door het onderzoek wordt de kennis verbreed (het denken). <br />Door
          een inspirerend contact met de samenleving wekken we de
          initiatiefkracht (de wil).
          <br />
          <br />
          Op deze heilzame werk- en ontmoetingsplek <br />
          mogen jong en oud neerstrijken <br />om van hieruit weer gevoed verder
          op weg te gaan.
          <br />
          <br />
          We vragen een hoger principe <br />om ons te gidsen en bij te staan<br />
          en de gemeenschapszin in Het Dagelijks Bestaan<br />
          van geestelijke leiding te voorzien.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
