<style scoped>
.table-icons {
  float: right;
}

.no-margin {
  margin: 0px;
  padding: 0px;
}
</style>

<template>
  <div>
    <div class="block table-icons"></div>
    <br />
    <b-table
      :data="index"
      :bordered="true"
      :striped="true"
      :hoverable="true"
      :loading="ajaxloading.get_index"
      :focusable="false"
      :mobile-cards="false"
      :selected.sync="selected"
      detailed
      detail-key="id"
    >
      <b-table-column field="id" label="Order" numeric v-slot="props">
        <a
          @click="
            $router.push({
              name: 'bestelling-aanpassen',
              params: { bestelling: props.row.order.id },
            })
          "
        >
          {{ props.row.order.id }}
        </a>
      </b-table-column>

      <b-table-column field="name" label="Naam" v-slot="props">
        {{ props.row.order.voornaam }} {{ props.row.order.achternaam }}
      </b-table-column>

      <b-table-column field="name" label="Adres" v-slot="props">
        <a @click="showAddresInGoogleMaps(props.row.order)">
          {{ props.row.order.straat }} {{ props.row.order.huisnummer
          }}{{ props.row.order.huisnummer_extension }}<br />
          {{ props.row.order.postcode }} {{ props.row.order.plaats }}
        </a>
      </b-table-column>

      <b-table-column field="name" label="Bron" v-slot="props">
        {{ props.row.order.bron }}
      </b-table-column>

      <b-table-column field="name" label="Bron" v-slot="props">
        {{ props.row.sort_key }}
      </b-table-column>

      <b-table-column field="name" label="Producten" v-slot="props">
        <div
          class="columns no-margin"
          v-for="product in props.row.products"
          :key="product.id"
        >
          <div class="column no-margin">
            {{ product.orderday_product.naam }}:
          </div>
          <div class="column no-margin is-2">{{ product.hoeveelheid }}</div>
          <br />
        </div>
      </b-table-column>

      <b-table-column field="name" label="Opmerking" v-slot="props">
        <span v-if="props.row.order.opmerking"> ja </span>
      </b-table-column>

      <b-table-column field="name" label="Status" v-slot="props">
        {{ props.row.status }}
      </b-table-column>

      <template #detail="props">
        <div class="columns">
          <div class="column">Telefoonnnummer:</div>
          <div class="column">{{ props.row.order.telefoonnummer }}</div>
        </div>
        <div class="columns">
          <div class="column">Email:</div>
          <div class="column">{{ props.row.order.email }}</div>
        </div>

        <br />

        Opmerking: <br />
        {{ props.row.order.opmerking }}
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    index: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {},
  methods: {
    showAddresInGoogleMaps(order) {
      const street = order.straat.replace(" ", "+");
      var housenumber = order.huisnummer;
      if (order.huisnummer_extension) {
        housenumber += order.huisnummer_extension;
      }

      window.open(
        "https://maps.google.com/?q=" +
          street +
          "," +
          order.plaats +
          "," +
          housenumber
      );
    },
  },
  mounted() {},
};
</script>
