<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Blokkade aanpassen</div>
    <form @submit.prevent="aanpassen()">
      <br />
      <br />
      <formInput
        class="customInput"
        :model="form"
        modelKey="postcode"
        label="on-border"
        placeholder="1234AA"
        @blur="findAddressFromPostalCode(form.postcode, form.huisnummer, form)"
      />

      <formInput
        class="customInput"
        :model="form"
        modelKey="huisnummer"
        label="on-border"
        placeholder="4"
        @blur="findAddressFromPostalCode(form.postcode, form.huisnummer, form)"
      />

      <div v-if="form.straat">
        <p class="help">{{ form.straat }}, {{ form.plaats }}</p>
        <br />
      </div>

      <formInput
        class="customInput"
        :model="form"
        modelKey="email"
        label="on-border"
        placeholder="xxxx@yyyyy.nl"
      />

      <formInput
        class="customInput"
        :model="form"
        modelKey="omschrijving"
        label="on-border"
        placeholder="Waarom blokkade?"
      />

      <b-button
        native-type="submit"
        :loading="ajaxloading.edit_blacklist_item"
        type="is-primary is-rounded"
        >Aanpassen</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="cancel()">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    blacklist_index() {
      return this.$store.getters["blacklist/blacklist"];
    },
    blacklist() {
      return this.blacklist_index.find(
        (a) => a.id == this.$route.params.blacklist
      );
    },
  },
  methods: {
    cancel() {
      this.$router.go(-1);
      this.$store.dispatch("blacklist/refresh", this.form);
    },
    aanpassen() {
      this.$store.dispatch("blacklist/edit", this.form).then(() => {
        this.$buefy.toast.open("Blokkade aangepast");
        this.$router.push({ name: "blacklist-index" });
      });
    },
  },
  mounted() {
    if (this.blacklist) {
      this.form = this.blacklist;
    } else {
      this.$router.go(-1);
    }
  },
};
</script>
