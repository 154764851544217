<style scoped>
.table-icons {
  float: right;
}

.no-margin {
  margin: 0px;
  padding: 0px;
}
</style>

<template>
  <div>
    <b-table
      :data="index"
      :bordered="true"
      :striped="true"
      :hoverable="true"
      :loading="ajaxloading.get_index"
      :focusable="false"
      :mobile-cards="false"
      :selected.sync="selected"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column field="name" label="Adressen" v-slot="props">
        {{ props.row.addressen }}
      </b-table-column>

      <b-table-column field="name" label="Route link" v-slot="props">
        {{ props.row.route_link }}
      </b-table-column>

      <b-table-column field="name" label="Adressen" v-slot="props">
        {{ props.row.adressen }}
      </b-table-column>

      <b-table-column field="name" label="Producten" v-slot="props">
        {{ props.row.producten }}
      </b-table-column>

      <b-table-column field="name" label="Bezorger" v-slot="props">
        {{ props.row.user }}
      </b-table-column>

      <b-table-column field="name" label="Status" v-slot="props">
        {{ props.row.status }}
      </b-table-column>

      <template #detail="props">
        <div class="columns">
          <div class="column">Telefoonnnummer:</div>
          <div class="column">{{ props.row.order.telefoonnummer }}</div>
        </div>
        <div class="columns">
          <div class="column">Email:</div>
          <div class="column">{{ props.row.order.email }}</div>
        </div>

        <br />

        Opmerking: <br />
        {{ props.row.order.opmerking }}
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    index: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {},
  methods: {
    showAddresInGoogleMaps(order) {
      const street = order.straat.replace(" ", "+");
      var housenumber = order.huisnummer;
      if (order.huisnummer_extension) {
        housenumber += order.huisnummer_extension;
      }

      window.open(
        "https://maps.google.com/?q=" +
          street +
          "," +
          order.plaats +
          "," +
          housenumber
      );
    },
  },
  mounted() {},
};
</script>
