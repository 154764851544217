<style>
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}
</style>

<template>
  <div v-if="pictures.length">
    <b-carousel
      :autoplay="false"
      :indicator-inside="false"
      v-if="pictures.length !== 1"
    >
      <b-carousel-item
        v-for="(item, i) in pictures"
        :key="i"
        class="has-text-centered"
      >
        <img
          :src="env.VUE_APP_API_URL + '/web/site-pictures/' + pictures[i].uuid"
          :alt="pictures[i].naam"
        />
      </b-carousel-item>
      <template #indicators="props">
        <b-image
          class="al image"
          :src="
            env.VUE_APP_API_URL + '/web/site-pictures/' + pictures[props.i].uuid
          "
          :title="pictures[props.i].naam"
        ></b-image>
      </template>
    </b-carousel>
    <img
      v-else
      :src="env.VUE_APP_API_URL + '/web/site-pictures/' + pictures[0].uuid"
      :alt="pictures[0].naam"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    page: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      env: process.env
    };
  },
  computed: {
    allPictures() {
      return this.$store.getters["pictures"];
    },
    pictures() {
      const pictures = this.allPictures.filter(a => a.category === this.page);

      if (pictures.length) {
        return pictures;
      } else {
        return [];
      }
    }
  },
  methods: {
    getImgUrl(value) {
      return `https://picsum.photos/id/43${value}/1230/500`;
    }
  },
  mounted() {}
};
</script>
