<template>
  <div>
    <div
      class="columns"
      v-for="outerLoop in Math.ceil(products.length / maxItemsPerRow)"
      :key="outerLoop"
    >
      <div
        class="column is-3"
        v-for="item in calculatedMax(outerLoop)"
        :key="item"
      >
        <div
          class="card pointer"
          @click="
            productClicked(
              products[item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1]
                .id
            )
          "
        >
          <div
            class="card-image"
            @mouseover="
              mouseoverImage(
                products[item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1]
              )
            "
            @mouseleave="mouseleaveImage"
          >
            <figure class="image is-1by1">
              <div
                v-if="
                  products[
                    item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                  ].pictures[0]
                "
              >
                <img
                  :class="{
                    greyImage:
                      products[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].status === 'sold',
                    hiddenImage:
                      products[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].visible === false,
                  }"
                  v-if="
                    hoveredProduct ===
                    products[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ].id
                  "
                  :src="
                    calcPictureLength(
                      products[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ]
                    )
                      ? env.VUE_APP_API_URL +
                        '/web/picture/' +
                        products[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ].pictures[1].uuid
                      : env.VUE_APP_API_URL +
                        '/web/picture/' +
                        products[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ].pictures[0].uuid
                  "
                  alt="Product Image"
                />
                <img
                  :class="{
                    greyImage:
                      products[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].status === 'sold',
                    hiddenImage:
                      products[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].visible === false,
                  }"
                  v-else
                  :src="
                    env.VUE_APP_API_URL +
                    '/web/picture/' +
                    products[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ].pictures[0].uuid
                  "
                  alt="Product Image"
                />
              </div>
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media"></div>
              <div class="media-content">
                <div class="content has-text-centered">
                  {{
                    hoofdletter(
                      products[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].naam
                    )
                  }}
                  <br />

                  <p
                    style="color: red"
                    v-if="
                      products[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].status === 'sold'
                    "
                  >
                    Sold
                  </p>
                  <p
                    v-else-if="
                      products[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].status === 'preview'
                    "
                  >
                    Preview
                  </p>

                  <p v-else>
                    {{
                      euro(
                        products[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ].prijs -
                          products[
                            item +
                              outerLoop * maxItemsPerRow -
                              maxItemsPerRow -
                              1
                          ].korting
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    products: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      maxItemsPerRow: 4,
      hoveredProduct: null,
      env: process.env,
    };
  },
  computed: {},
  methods: {
    calculatedMax(loop) {
      const max = this.products.length;
      const rows = this.maxItemsPerRow * loop;
      if (max < rows) {
        return max - this.maxItemsPerRow * (loop - 1);
      } else {
        return this.maxItemsPerRow;
      }
    },
    mouseoverImage(product) {
      this.hoveredProduct = product.id;
    },
    mouseleaveImage() {
      this.hoveredProduct = false;
    },
    productClicked(product) {
      this.$router.push({
        name: "admin-product",
        params: { product: product },
      });
    },
    calcPictureLength(product) {
      if (product.pictures.length < 2) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {},
};
</script>
