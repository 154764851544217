<style scoped>
.customTitle {
  font-weight: bold;
}

.customBox {
  border-style: solid;
}
</style>

<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="bold">Vrienden van Het Dagelijks Bestaan</div>
        Het Dagelijks Bestaan zoekt actief contact met verwante organisaties en
        instellingen. Is uw organisatie geïnteresseerd in een
        samenwerkingsverband, schroom dan niet contact op te nemen! Met
        onderstaande organisaties hebben wij een warme band:

        <br />
        <div class="content">
          <ul>
            <br />
            <li>Beschermd Wonen Deventer</li>
            <li>CJG</li>
            <li>De Driekant, Zutphen</li>
            <li>De diaconie</li>
            <li>De Kaardebol</li>
            <li>De Zutphense HAND</li>
            <li>De Zutphense Uitdaging</li>
            <li>Het onderwijszorgcentrum Zutphen (OZC)</li>
            <li>Stichting Emerpark</li>
            <li>Waterkracht</li>
            <li>Woonbedrijf Ieder1</li>
            <!-- We hebben raamovereenkomsten met de gemeenten Zutphen, Apeldoorn,
        Deventer, Lochem, Bronkhorst, Brummen en Voorst. -->
          </ul>
        </div>
      </div>
      <div class="column">
        <carrousel :page="'meedoen_vriendenvan'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
