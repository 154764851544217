<template>
  <b-field
    :label="hoofdletter(computedTitle)"
    :label-position="label"
    :type="{ 'is-danger': validation[modelKey] }"
    :message="validation[modelKey]"
  >
    <b-checkbox v-model="model[modelKey]" :size="size"
      >{{ computedCheckboxText
      }}<b-icon v-if="tooltipQuestionMark" icon="question-circle"></b-icon
    ></b-checkbox>
  </b-field>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    model: {
      required: true,
      type: Object,
    },
    modelKey: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
    },
    size: {
      default: "is-normal",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    type: {
      default: "input",
      type: String,
    },
    labelHidden: {
      default: false,
      type: Boolean,
    },
    checkBoxText: {
      type: String,
    },
    tooltipQuestionMark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    computedTitle() {
      if (this.labelHidden) {
        return "";
      }
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedCheckbox() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedCheckboxText() {
      if (this.checkBoxText) {
        return this.checkBoxText;
      } else {
        return this.computedCheckbox;
      }
    },
  },
  methods: {
    clearValidationError() {
      this.$store.dispatch("clearValidationError", this.modelKey);
    },
  },
  mounted() {},
};
</script>
