<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="bold">Vacatures</div>
        <br v-if="findVariable('vacature_zichtbaar_1') == 1" />
        <button
          v-if="findVariable('vacature_zichtbaar_1') == 1"
          @click="pushToVacature(findVariable('link_voor_vacature_1'))"
          class="button is-primary is-fullwidth"
        >
          {{ findVariable("titel_voor_vacature_1") }}
        </button>

        <br v-if="findVariable('vacature_zichtbaar_2') == 1" />
        <button
          v-if="
            findVariable('vacature_zichtbaar_2') == 1 &&
            findVariable('vacature_zichtbaar_2') == 1
          "
          @click="pushToVacature(findVariable('link_voor_vacature_2'))"
          class="button is-primary is-fullwidth"
        >
          {{ findVariable("titel_voor_vacature_2") }}
        </button>

        <br v-if="findVariable('vacature_zichtbaar_3') == 1" />
        <button
          v-if="findVariable('vacature_zichtbaar_3') == 1"
          @click="pushToVacature(findVariable('link_voor_vacature_3'))"
          class="button is-primary is-fullwidth"
        >
          {{ findVariable("titel_voor_vacature_3") }}
        </button>

        <br v-if="findVariable('vacature_zichtbaar_4') == 1" />

        <button
          v-if="findVariable('vacature_zichtbaar_4') == 1"
          @click="pushToVacature(findVariable('link_voor_vacature_4'))"
          class="button is-primary is-fullwidth"
        >
          {{ findVariable("titel_voor_vacature_4") }}
        </button>

        <br v-if="findVariable('vacature_zichtbaar_5') == 1" />

        <button
          v-if="findVariable('vacature_zichtbaar_5') == 1"
          @click="pushToVacature(findVariable('link_voor_vacature_5'))"
          class="button is-primary is-fullwidth"
        >
          {{ findVariable("titel_voor_vacature_5") }}
        </button>

        <br v-if="findVariable('vacature_zichtbaar_6') == 1" />

        <button
          v-if="findVariable('vacature_zichtbaar_6') == 1"
          @click="pushToVacature(findVariable('link_voor_vacature_6'))"
          class="button is-primary is-fullwidth"
        >
          {{ findVariable("titel_voor_vacature_6") }}
        </button>

        <br v-if="findVariable('vacature_zichtbaar_7') == 1" />

        <button
          v-if="findVariable('vacature_zichtbaar_7') == 1"
          @click="pushToVacature(findVariable('link_voor_vacature_7'))"
          class="button is-primary is-fullwidth"
        >
          {{ findVariable("titel_voor_vacature_7") }}
        </button>

        <br v-if="findVariable('vacature_zichtbaar_8') == 1" />

        <button
          v-if="findVariable('vacature_zichtbaar_8') == 1"
          @click="pushToVacature(findVariable('link_voor_vacature_8'))"
          class="button is-primary is-fullwidth"
        >
          {{ findVariable("titel_voor_vacature_8") }}
        </button>

        <br v-if="findVariable('vacature_zichtbaar_9') == 1" />

        <button
          v-if="findVariable('vacature_zichtbaar_9') == 1"
          @click="pushToVacature(findVariable('link_voor_vacature_9'))"
          class="button is-primary is-fullwidth"
        >
          {{ findVariable("titel_voor_vacature_9") }}
        </button>

        <button
          v-if="findVariable('vacature_zichtbaar_10') == 1"
          @click="pushToVacature(findVariable('link_voor_vacature_10'))"
          class="button is-primary is-fullwidth"
        >
          {{ findVariable("titel_voor_vacature_10") }}
        </button>

        <span
          v-if="
            !findVariable('vacature_zichtbaar_1') &&
            !findVariable('vacature_zichtbaar_2') &&
            !findVariable('vacature_zichtbaar_3') &&
            !findVariable('vacature_zichtbaar_4') &&
            !findVariable('vacature_zichtbaar_5') &&
            !findVariable('vacature_zichtbaar_6') &&
            !findVariable('vacature_zichtbaar_7') &&
            !findVariable('vacature_zichtbaar_8') &&
            !findVariable('vacature_zichtbaar_9') &&
            !findVariable('vacature_zichtbaar_10')
          "
        >
          <span
            v-if="
              !findVariable('vacature_zichtbaar_1') &&
              !findVariable('vacature_zichtbaar_2') &&
              !findVariable('vacature_zichtbaar_3') &&
              !findVariable('vacature_zichtbaar_4') &&
              !findVariable('vacature_zichtbaar_5') &&
              !findVariable('vacature_zichtbaar_6') &&
              !findVariable('vacature_zichtbaar_7') &&
              !findVariable('vacature_zichtbaar_8') &&
              !findVariable('vacature_zichtbaar_9') &&
              !findVariable('vacature_zichtbaar_10')
            "
          >
            Op dit moment staan er geen vacatures open.
          </span>
        </span>
      </div>
      <div class="column">
        <carrousel :page="'meedoen_werkenbij'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {
    pushToVacature(link) {
      this.$router.push({ name: "vacature", params: { vacature: link } });
    },
  },
  mounted() {},
};
</script>
