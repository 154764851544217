<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Geschiedenis</div>
          Stichting Het Dagelijks Bestaan te Zutphen is opgericht in 2008 vanuit
          een particulier initiatief van kunstenaar Karel Overbeek en pedagoge
          An de Cock. In de loop van de jaren is rondom de jongeren een groot
          sociaal netwerk ontstaan. Op de Mars werd in 2015 het Beschermd Wonen
          gestart, waarin een jongeren met een ontwikkelvraag en buren samen
          community-based wonen. Alle betrokkenen bij Het Dagelijks Bestaan zijn
          onderling verbonden door het maatschappelijke doel van de stichting.
          Zingeving staat voor iedereen centraal: jongeren, medewerkers, buren,
          vrijwilligers en vrienden.
          <br />
          <br />
          In de periode tussen 2017 en 2020 zijn er diverse verhuizingen
          geweest, van de Mars naar de Proeflokalen en gebouw de Mate op het
          GGnet terrein. Sinds mei 2020 zitten wij op een prachtige, eigen
          locatie op het Emerpark. We hebben 26 circulaire, duurzame woningen
          waar jongeren en buren samenwonen. Fabrieksgebouw de Ontharding wordt
          momenteel omgetoverd tot het hart van ons terrein; een grote,
          gezamenlijke woonkamer, een professionele horeca keuken en in de
          toekomst ook plek voor de uitbreiding van werkplaatsen zullen daar
          plaats vinden. Het Dagprogramma vindt momenteel plaats in de
          Ontharding en de buitenruimte.
        </div>
      </div>
      <div class="column">
        <carrousel :page="'overons_geschiedenis'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
