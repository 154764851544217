<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Postcode aanpassen</div>
    <form @submit.prevent="aanpassen()">
      <br />
      <br />
      <div class="title is-5">Geauthoriseerde postcodes</div>

      <div class="columns">
        <div class="column is-6">
          <formInput
            class="customInput"
            :model="form"
            title="begin"
            modelKey="postcode_begin"
            label="on-border"
            placeholder="1234AB"
          />
        </div>
        <div class="column">
          <formInput
            class="customInput"
            :model="form"
            title="eind"
            modelKey="postcode_eind"
            label="on-border"
            placeholder="9999YZ"
          />
        </div>
      </div>

      <b-field>
        <b-switch v-model="all_house_numbers"
          >Alle huisnummers in postcode range</b-switch
        >
      </b-field>

      <span v-if="!all_house_numbers">
        <div class="title is-5">Geauthoriseerde huisnummers</div>

        <div class="columns">
          <div class="column is-6">
            <formInput
              class="customInput"
              :model="form"
              title="begin"
              modelKey="huisnummer_begin"
              label="on-border"
              placeholder="1"
            />
          </div>
          <div class="column">
            <formInput
              class="customInput"
              :model="form"
              title="eind"
              modelKey="huisnummer_eind"
              label="on-border"
              placeholder="200"
            />
          </div>
        </div>
        <br />
      </span>

      <formInput
        class="customInput"
        :model="form"
        modelKey="omschrijving"
        label="on-border"
      />

      <b-field v-if="form.status">
        <b-switch
          v-model="form.status"
          true-value="actief"
          false-value="niet-actief"
          >{{ hoofdletter(form.status) }}</b-switch
        >
      </b-field>

      <b-button
        native-type="submit"
        :loading="ajaxloading.edit_postal_code_range"
        type="is-primary is-rounded"
        >Aanpassen</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="cancel()">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      all_house_numbers: true,
      form: {
        id: 0,
        status: true,
      },
    };
  },
  computed: {
    postal_codes() {
      return this.$store.getters["postalCodes/postal_codes"];
    },
    postcode() {
      return this.postal_codes.find((a) => a.id == this.$route.params.postcode);
    },
  },
  methods: {
    cancel() {
      this.$router.go(-1);
      this.$store.dispatch("postalCodes/refresh", this.postcode);
    },
    aanpassen() {
      if (this.all_house_numbers) {
        this.form.huisnummer_begin = null;
        this.form.huisnummer_eind = null;
      }
      this.$store.dispatch("postalCodes/edit", this.form).then(() => {
        this.$buefy.toast.open("Postcode aangepast");
        this.$router.push({ name: "postcode-index" });
      });
    },
  },
  mounted() {
    if (this.postcode) {
      this.form = this.postcode;
      if (this.form.huisnummer_begin || this.form.huisnummer_eind) {
        this.all_house_numbers = false;
      }
    } else {
      this.$router.go(-1);
    }
  },
};
</script>
