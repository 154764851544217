<template>
  <div
    :class="['navbar-item has-dropdown', { 'is-active': showDropdown }]"
    @mouseover="mouseOverDropdown('showDropDown')"
    @mouseleave="mouseLeaveDropdown('showDropDown')"
  >
    <router-link
      :class="['navbar-link']"
      :to="{ name: link.routerLink }"
      @click.native="mainLinkClicked()"
    >
      {{ link.naam }}
    </router-link>
    <span class="is-hidden-desktop" v-if="showMobile">
      <div class="navbar-dropdown is-boxed">
        <router-link
          @click.native="navigateToLink()"
          v-for="child of link.children"
          :key="child.naam"
          class="navbar-item"
          :to="{ name: child.routerLink }"
          :exact="child.exact"
        >
          {{ child.naam }}
        </router-link>
      </div>
    </span>

    <span class="is-hidden-touch" v-if="showDropdown">
      <div class="navbar-dropdown is-boxed">
        <router-link
          @click.native="navigateToLink()"
          v-for="child of link.children"
          :key="child.naam"
          class="navbar-item"
          :to="{ name: child.routerLink }"
          :exact="child.exact"
        >
          {{ child.naam }}
        </router-link>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    link: {
      required: true,
      type: Object
    },
    showMobileParent: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      showDropdown: false,
      showMobile: false
    };
  },
  computed: {},
  methods: {
    mainLinkClicked() {
      if (this.showMobile) {
        this.showMobile = false;
      } else {
        this.showMobile = true;
      }
    },
    navigateToLink() {
      this.$emit("closedMobileMenu", true);
      this.showMobile = false;
      this.closeDropDown();
    },
    mouseOverDropdown() {
      this.showDropdown = true;
    },
    mouseLeaveDropdown() {
      this.showDropdown = false;
    },
    closeDropDown() {
      this.showDropdown = false;
    }
  },
  watch: {
    showMobileParent() {
      if (!this.showMobileParent) {
        this.navigateToLink();
      }
    }
  },
  mounted() {}
};
</script>
