<template>
  <div>
    <quote
      quote="Op Het Dagelijks Bestaan springen de levensvragen zo op je schouder"
    ></quote>

    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Over ons</div>
          Kenmerkend voor Het Dagelijks Bestaan is dat wij samen oplopen in het
          leven van alledag. We hechten veel waarde aan het samen beleven van
          alledaagse dingen zoals koken, eten, werken, leren of koffie en thee
          drinken. Door deze ontmoetingen is er ruimte om van elkaar te
          leren en elkaar te inspireren.
          <br />
          <br />
          Wij bieden een
          <a @click="$router.push({ name: 'dagbesteding' })">dagprogramma</a>
          aan waarin wij jongeren begeleiden richting onderwijs, werk en/of
          (langdurige) hulpverlening. Er zijn ook dertien plekken voor
          <a @click="$router.push({ name: 'dagbesteding' })">beschermd wonen</a
          >, waarbij we jongeren ondersteunen in hun groei naar
          zelfstandigheid.. Na een (aantal) jaar stromen zij door naar een
          zelfstandige woonplek (in combinatie met ambulante zorg) of een
          langdurig traject in woonbegeleiding. We geloven in de verbinding
          tussen hoofd, hart en handen. Door met je handen bezig te zijn in onze
          <a @click="$router.push({ name: 'werkplaatsen' })">werkplaatsen</a>,
          maak je verbinding tussen hart en hoofd.
        </div>
      </div>
      <div class="column is-6">
        <carrousel :page="'overons_algemeen'" />
      </div>
    </div>
  </div>
</template>

<script>
import quote from "@/views/Components/Quote.vue";

export default {
  components: {
    quote: quote
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
