<template>
  <div>
    <div class="columns container is-fluid">
      <div class="column is-2">
        <adminMenu />
      </div>
      <div class="column is-10">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import adminMenu from "./AdminMenu.vue";

export default {
  components: {
    adminMenu: adminMenu,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch("auth/getLoggedInUser");
    this.$store.dispatch("auth/getAdminSettings");
  },
};
</script>
