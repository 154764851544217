<style>
/* .editor {
  background-color: #fff;
  border: 3px solid #0d0d0d;
  border-radius: 0.75rem;
  color: #0d0d0d;
  display: flex;
  flex-direction: column;
  max-height: 26rem;
}

*/

.editor-menubar {
  border: 1px solid transparent;
  border-color: #dbdbdb;
  border-radius: 4px;
  color: #363636 !important;
  margin-bottom: 2px;
  padding: 3px;
}

.ProseMirror {
  /* word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; */
  min-height: 250px !important;
  padding: 8px;
  /* border-style: solid; */

  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  box-sizing: inherit;
  margin: 0;
  /* font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif; */
  -webkit-appearance: none;
  border: 1px solid transparent;
  /* display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;
  background-color: white; */
  border-color: #dbdbdb;

  border-radius: 4px;
  color: #363636 !important;
  /* box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05); */
  /* max-width: 100%; */
  /* width: 100%; */
}
/* 
.ProseMirror ol,
ul {
  padding-left: 1rem;
} */

.ProseMirror:focus {
  outline: none;
  border-color: #4a9e83 !important;
  box-shadow: 0 0 0 0.125em rgba(74, 158, 131, 0.25) !important;
}

.selected-menu-button-option {
  /* color: red; */
}

.editor-menu-button {
  color: grey;
  /* color: grey;
  background-color: lightgray;
  border-color: lightgrey; */
}

.selected-menu-button-option {
  color: black;
}
</style>

<template>
  <div>
    <b-field
      :label="hoofdletter(computedTitle)"
      :label-position="label"
      :type="{ 'is-danger': validation[modelKey] }"
      :message="validation[modelKey]"
    >
    </b-field>
    <div class="">
      <div class="editor-menubar has-text-centered">
        <button
          class="editor-menu-button"
          :class="{ 'selected-menu-button-option': editor.isActive('bold') }"
          @click.prevent="editor.chain().focus().toggleBold().run()"
        >
          <b-icon icon="bold" size="is-normal"> </b-icon>
        </button>
        <button
          class="editor-menu-button"
          :class="{ 'selected-menu-button-option': editor.isActive('italic') }"
          @click.prevent="editor.chain().focus().toggleItalic().run()"
        >
          <b-icon icon="italic" size="is-normal"> </b-icon>
        </button>
        <button
          class="editor-menu-button"
          :class="{ 'selected-menu-button-option': editor.isActive('strike') }"
          @click.prevent="editor.chain().focus().toggleStrike().run()"
        >
          <b-icon icon="strikethrough" size="is-normal"> </b-icon>
        </button>

        <button
          class="editor-menu-button"
          :class="{
            'selected-menu-button-option': editor.isActive('underline'),
          }"
          @click.prevent="editor.chain().focus().toggleUnderline().run()"
        >
          <b-icon icon="underline" size="is-normal"> </b-icon>
        </button>

        &nbsp;

        <button
          class="editor-menu-button"
          :class="{
            'selected-menu-button-option': editor.isActive({
              textAlign: 'left',
            }),
          }"
          @click.prevent="editor.chain().focus().setTextAlign('left').run()"
        >
          <b-icon icon="align-left" size="is-normal"> </b-icon>
        </button>
        <button
          class="editor-menu-button"
          :class="{
            'selected-menu-button-option': editor.isActive({
              textAlign: 'center',
            }),
          }"
          @click.prevent="editor.chain().focus().setTextAlign('center').run()"
        >
          <b-icon icon="align-center" size="is-normal"> </b-icon>
        </button>
        <button
          class="editor-menu-button"
          :class="{
            'selected-menu-button-option': editor.isActive({
              textAlign: 'right',
            }),
          }"
          @click.prevent="editor.chain().focus().setTextAlign('right').run()"
        >
          <b-icon icon="align-right" size="is-normal"> </b-icon>
        </button>
        &nbsp;
        <button
          class="editor-menu-button"
          :class="{
            'selected-menu-button-option': editor.isActive('heading', {
              level: 1,
            }),
          }"
          @click.prevent="
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          "
        >
          <b-icon icon="heading" size="is-normal"> </b-icon>1
        </button>
        <button
          class="editor-menu-button"
          :class="{
            'selected-menu-button-option': editor.isActive('heading', {
              level: 2,
            }),
          }"
          @click.prevent="
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          "
        >
          <b-icon icon="heading" size="is-normal"> </b-icon>2
        </button>
        <button
          class="editor-menu-button"
          :class="{
            'selected-menu-button-option': editor.isActive('heading', {
              level: 3,
            }),
          }"
          @click.prevent="
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          "
        >
          <b-icon icon="heading" size="is-normal"> </b-icon>3
        </button>
        &nbsp;
        <button
          class="editor-menu-button"
          :class="{
            'selected-menu-button-option': editor.isActive('bulletList'),
          }"
          @click.prevent="editor.chain().focus().toggleBulletList().run()"
        >
          <b-icon icon="list-ul" size="is-normal"> </b-icon>
        </button>
        <button
          class="editor-menu-button"
          :class="{
            'selected-menu-button-option': editor.isActive('orderedList'),
          }"
          @click.prevent="editor.chain().focus().toggleOrderedList().run()"
        >
          <b-icon icon="list-ol" size="is-normal"> </b-icon>
        </button>
        &nbsp;
        <button
          class="editor-menu-button"
          :class="{
            'selected-menu-button-option': editor.isActive('blockquote'),
          }"
          @click.prevent="editor.chain().focus().toggleBlockquote().run()"
        >
          <b-icon icon="quote-left" size="is-normal"> </b-icon>
        </button>

        &nbsp;

        <!-- links -->
        <b-tooltip label="Add link to selected text">
          <button
            class="editor-menu-button"
            :class="{
              'selected-menu-button-option': editor.isActive('bulletList'),
            }"
            @click.prevent="askForHyperLink()"
          >
            <b-icon icon="link" size="is-normal"> </b-icon>
          </button>
        </b-tooltip>
        <b-tooltip label="Remove link from selected text">
          <button
            class="editor-menu-button"
            :class="{
              'selected-menu-button-option': editor.isActive('orderedList'),
            }"
            @click.prevent="editor.commands.unsetLink()"
          >
            <b-icon icon="unlink" size="is-normal"> </b-icon>
          </button>
        </b-tooltip>
        &nbsp;

        <button
          class="editor-menu-button"
          @click.prevent="editor.chain().focus().setHorizontalRule().run()"
        >
          <b-icon icon="minus" size="is-normal"> </b-icon>
        </button>
        &nbsp;
        <button
          class="editor-menu-button"
          @click.prevent="editor.chain().focus().undo().run()"
        >
          <b-icon icon="undo" size="is-normal"> </b-icon>
        </button>
        <button
          class="editor-menu-button"
          @click.prevent="editor.chain().focus().redo().run()"
        >
          <b-icon icon="redo" size="is-normal"> </b-icon>
        </button>
      </div>
      <editor-content class="content" :editor="editor" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Link from "@tiptap/extension-link";

export default {
  components: {
    EditorContent,
  },
  props: {
    title: {
      type: String,
    },
    model: {
      required: true,
      type: Object,
    },
    modelKey: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
    },
    size: {
      default: "is-normal",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    type: {
      default: "input",
      type: String,
    },
    value: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      clearContent: false,
      editor: null,
      valueUpdate: true,
    };
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
  },

  created() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        Link.configure({
          protocols: ["mailto", "https", "http", "tel"],
        }),
      ],
      onUpdate: () => {
        // HTML
        this.model[this.modelKey] = this.editor.getHTML();
        // this.$emit("input", this.editor.getHTML());
        // JSON
        // this.$emit("input", this.editor.getJSON());
      },
    });
  },
  methods: {
    askForHyperLink() {
      this.$buefy.dialog.prompt({
        message: `Wat is de gewenste link?`,
        inputAttrs: {
          placeholder: "https://www.google.com",
        },
        trapFocus: true,
        onConfirm: (value) => this.setLink(value),
      });
    },
    setLink(value) {
      this.editor.commands.toggleLink({ href: value });
      this.$buefy.toast.open("Link added");
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  mounted() {
    this.model[this.modelKey] = this.editor.getHTML();
  },
  watch: {},
};
</script>
