<style>
.text-align-buefy-right {
  text-align: right;
}

.text-align-buefy-center {
  text-align: center;
}
</style>

<template>
  <b-field
    :label="hoofdletter(computedTitle)"
    :label-position="label"
    :type="{ 'is-danger': validation[computedValidation] }"
    :message="validation[computedValidation]"
  >
    <b-input
      :loading="loading"
      :custom-class="getCustomClassString()"
      @focus="$event.target.select()"
      :ref="modelKey"
      :type="type"
      :value="value"
      @blur="blur()"
      @input="clearValidationError()"
      v-model="model[modelKey]"
      :size="size"
      :placeholder="computedPlaceholder"
      :password-reveal="type === 'password'"
      :disabled="disabled"
    >
    </b-input>
  </b-field>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    model: {
      required: true,
      type: Object,
    },
    modelKey: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
    },
    validationKey: {
      type: String,
    },
    size: {
      default: "is-normal",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    value: {
      required: false,
    },
    type: {
      default: "input",
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    alignCenter: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      syncValue: true,
    };
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedValidation() {
      if (this.validationKey) {
        return this.validationKey;
      } else {
        return this.modelKey;
      }
    },
    computedPlaceholder() {
      if (this.placeholder) {
        return this.placeholder;
      }

      return this.hoofdletter(this.computedTitle);
    },
  },
  methods: {
    blur() {
      this.$emit("blur");
      this.$emit("change");
    },
    clearValidationError() {
      this.$emit("input");
      this.syncValue = false;
      this.$store.dispatch("clearValidationError", this.modelKey);
    },

    setValue() {
      if (this.value !== undefined && this.syncValue) {
        Vue.set(this.model, this.modelKey, this.value);
      }
    },
    getCustomClassString() {
      var classString = "";
      if (this.alignRight) {
        classString = classString + "text-align-buefy-right" + " ";
      }

      if (this.alignCenter) {
        classString = classString + "text-align-buefy-center" + " ";
      }

      return classString;
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.setValue();
      },
    },
    model: {
      deep: true,
      handler() {
        this.setValue();
      },
    },
  },
  mounted() {
    this.setValue();
  },
};
</script>
