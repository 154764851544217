<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.name_columns {
  margin: 0px;
  padding: 0px;
}

.name_column {
  margin: 0px;
  padding: 0px;
}

.customInput {
  margin-bottom: 10px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Klant aanpassen</div>
    <br />
    <form @submit.prevent="aanpassen()">
      <hr />

      <div class="title is-5">Klant gegevens</div>
      <span class="columns name_columns">
        <span class="column name_column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="voornaam"
            label="on-border"
            placeholder="Julia"
        /></span>
        <span class="column name_column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="achternaam"
            label="on-border"
            placeholder="de Jong"
        /></span>
      </span>

      <formInput
        class="customInput"
        :model="form"
        modelKey="email"
        label="on-border"
        placeholder="julia@gmail.com"
      />

      <formInput
        class="customInput"
        :model="form"
        modelKey="telefoonnummer"
        label="on-border"
        placeholder="0575529222"
      />

      <formInput
        class="customInput"
        :model="form"
        modelKey="postcode"
        label="on-border"
        placeholder="7204LA"
        @blur="findAddress()"
      />

      <formInput
        class="customInput"
        :model="form"
        modelKey="huisnummer"
        label="on-border"
        placeholder="47"
        @blur="findAddress()"
      />

      <formInput
        :loading="addressLoading"
        class="customInput"
        :model="form"
        modelKey="straat"
        label="on-border"
        placeholder="Weg naar Vierakker"
      />

      <formInput
        :loading="addressLoading"
        class="customInput"
        :model="form"
        modelKey="plaats"
        label="on-border"
        placeholder="Zutphen"
      />

      <hr />

      <div class="title is-5">Vaste klant opties</div>

      <b-switch class="customInput" v-model="form.vaste_klant">
        <span v-if="form.vaste_klant">Vaste klant</span>
        <span v-else>Geen vaste klant</span>
      </b-switch>

      <br />

      <span v-if="form.vaste_klant">
        <b-switch class="customInput" v-model="form.vaste_klant_mailen">
          <span v-if="form.vaste_klant_mailen"
            >Bezorgbevesting standaard mailen</span
          >
          <span v-else>Bezorgbevesting standaard niet mailen</span>
        </b-switch>

        <br />
        <br />

        <b-field
          label="Standaard hoeveelheid producten"
          label-position="on-border"
          :type="{ 'is-danger': validation['vaste_klant_producten'] }"
          :message="validation['vaste_klant_producten']"
        >
          <b-numberinput
            :editable="false"
            controls-rounded
            :min="1"
            size="is-small"
            v-model="form.vaste_klant_producten"
            controls-position="compact"
          >
          </b-numberinput>
        </b-field>

        <div class="bold">Standaard bezorgkeuze</div>
        <b-field v-if="findVariable('order_pickup_enabled')">
          <b-radio
            v-model="form.vaste_klant_bezorgkeuze"
            native-value="ophalen"
          >
            Ophalen
          </b-radio>
        </b-field>
        <b-field
          v-if="findVariable('order_shipping_enabled')"
          :type="{ 'is-danger': validation['vaste_klant_bezorgkeuze'] }"
          :message="validation['vaste_klant_bezorgkeuze']"
        >
          <b-radio
            v-model="form.vaste_klant_bezorgkeuze"
            native-value="bezorgen"
          >
            Bezorgen
          </b-radio>
        </b-field>
      </span>
      <br />

      <b-button
        native-type="submit"
        :loading="ajaxloading.edit"
        type="is-primary is-rounded"
        >Aanpassen</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="cancelUpdate()">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      addressLoading: false,
      form: {},
    };
  },
  computed: {
    index() {
      return this.$store.getters["klanten/index"];
    },
    klant() {
      if (this.index.data) {
        return this.index.data.find((a) => a.id == this.$route.params.klant);
      } else {
        return null;
      }
    },
  },
  methods: {
    aanpassen() {
      if (!this.form.vaste_klant) {
        this.form.vaste_klant_mailen = false;
        this.form.vaste_klant_producten = null;
        this.form.vaste_klant_bezorgkeuze = null;
      }
      this.$store.dispatch("klanten/edit", this.form).then(() => {
        this.$buefy.toast.open("Klant aangepast");
        this.$router.push({ name: "klanten-index" });
      });
    },
    cancelUpdate() {
      this.$store.dispatch("klanten/refresh", this.form);
      this.$router.go(-1);
    },
    findAddress() {
      if (!this.form.postcode) {
        return;
      }

      if (!this.form.huisnummer) {
        return;
      }
      this.addressLoading = true;
      return this.findAddressFromPostalCode(
        this.form.postcode,
        this.form.huisnummer,
        this.form
      ).then(() => {
        this.addressLoading = false;
      });
    },
  },
  mounted() {
    if (this.klant) {
      this.form = this.klant;
    } else {
      this.$router.replace({ name: "klanten-index" });
    }
  },
};
</script>
