export const index = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get_blacklist";
  data.method = "GET";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setBlacklist", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const create = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create_blacklist";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("setNewItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const edit = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "edit_blacklist_item/" + payload.id;
  data.loading = "edit_blacklist_item";
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const destroy = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "delete_blacklist_item/" + payload.id;
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("deleteItem", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const refresh = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "refresh_blacklist_item/" + payload.id;
  data.method = "POST";
  data.data = payload;
  return dispatch("axiosAdmin", data, { root: true })
    .then((response) => {
      commit("updateItem", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
