var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('quote',{attrs:{"quote":"Eet smakelijk, allemaal!"}}),_c('div',{staticClass:"columns is-desktop"},[_c('div',{staticClass:"column"},[(_vm.settings.variables)?_c('div',{staticClass:"box",domProps:{"innerHTML":_vm._s(_vm.findSetting('soep_startpagina'))}}):_vm._e()]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"box"},_vm._l((Math.ceil(_vm.producten.length / _vm.maxItemsPerRow)),function(outerLoop){return _c('div',{key:outerLoop,staticClass:"columns"},_vm._l((_vm.calculatedMax(outerLoop)),function(item){return _c('div',{key:item,staticClass:"column is-4"},[_c('div',{staticClass:"card pointer",staticStyle:{"height":"100%"},on:{"click":function($event){return _vm.navigateToProduct(
                  _vm.producten[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ]
                )}}},[_c('div',{staticClass:"card-image"},[_c('figure',{staticClass:"image is-1by1"},[_c('div',{on:{"mouseover":function($event){return _vm.mouseoverImage(
                        _vm.producten[
                          item +
                            outerLoop * _vm.maxItemsPerRow -
                            _vm.maxItemsPerRow -
                            1
                        ]
                      )},"mouseleave":_vm.mouseleaveImage}},[(
                        _vm.hoveredPoduct ===
                        _vm.producten[
                          item +
                            outerLoop * _vm.maxItemsPerRow -
                            _vm.maxItemsPerRow -
                            1
                        ]
                      )?_c('img',{attrs:{"src":_vm.getHoveredPictureUrl(
                          _vm.producten[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ]
                        ),"alt":"Product foto"}}):_c('img',{attrs:{"src":_vm.getFirstPictureUrl(
                          _vm.producten[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ]
                        ),"alt":"Product foto"}})])])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media"}),_c('div',{staticClass:"media-content"},[_c('p',{staticClass:"title is-4 has-text-centered"},[_vm._v(" "+_vm._s(_vm.hoofdletter( _vm.producten[ item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1 ].naam ))+" ")])])])])])])}),0)}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }