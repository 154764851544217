<template>
  <div>
    <body>
      <logo />
      <navBar />
      <flashBanner />
      <div class="container is-fluid">
        <br />
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </body>
  </div>
</template>

<script>
import flashBanner from "@/components/headers/flashBanner.vue";
import NavBar from "@/views/Navbar.vue";
import Logo from "@/views/Logo.vue";

export default {
  components: {
    flashBanner,
    NavBar,
    Logo,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch("auth/getLoggedInUserWithoutError");
  },
};
</script>
