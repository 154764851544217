<style scoped>
.column {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>

<template>
  <div class="columns is-mobile">
    <div class="column">{{ computedTitle }}:</div>
    <div class="column">
      <span v-if="model[modelKey]">
        {{ computedChain }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      required: false
    },
    model: {
      required: true,
      type: Object
    },
    modelKey: {
      required: true,
      type: String
    },
    type: {
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.hoofdletter(this.modelKey);
      }
    },
    computedChain() {
      if (this.type === "date") {
        if (this.model[this.modelKey]) {
          return this.formatDatePickerDate(this.model[this.modelKey]);
        } else {
          return "";
        }
      } else {
        return this.hoofdletter(this.model[this.modelKey]);
      }
    }
  },
  methods: {},
  mounted() {}
};
</script>
