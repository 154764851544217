<template>
  <div>
    <quote
      quote="Je leeft niet alleen voor jezelf, alles wat je doet heeft invloed op het
      grotere geheel. Iedereen heeft iets te doen in de wereld, iedereen heeft
      verantwoordelijkheid voor zijn omgeving. Ieder mens heeft zijn eigen
      levens verhaal, samen schrijven wij ons verhaal"
    ></quote>
    <br />
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Visie</div>
          Wij geloven dat iedere mens, zijn eigen, uniek levensmotief met zich
          mee brengt. Liefdevol dagen wij je uit om dit stuk in jezelf aan te
          raken en te bekijken. Dit gebeurt middels levensvragen en thema’s.
          Door samen te leven, doen, vieren, rouwen, spelen en spreken mag je
          hier steeds meer jezelf worden. Dit is niet alleen zo voor de jongeren
          bij Het Dagelijks Bestaan, maar ook voor medewerkers, buren,
          vrijwilligers, stagiaires. Verbinding, samen, doen, chaos,
          creativiteit en ruimte, dit zijn een aantal kernwaarden die het meest
          gebruikt worden om Het Dagelijks Bestaan te omschrijven.
          <br />
          <br />
          De jongeren die net aankomen bij Het Dagelijks Bestaan, zijn nog bezig
          met landen. Ze leren de groep en gewoontes kennen en mogen snuffelen
          aan de verschillende activiteiten. Na een tijdje zie je dat jongeren
          de verbinding aangaan, zo komen ze in de tweede fase terecht. De
          verbinding. In deze periode staat ontwikkeling centraal. Het
          ervaringsleren. Door veel verschillende activiteiten uit te proberen,
          ontdek je je eigen (on)mogelijkheden. Door jezelf beter te leren
          kennen, kun je beter voorbereiden op je plek in de maatschappij.
          Tijdens de derde fase, het uitvliegen, richten de blikken zich op
          buiten. Binnen het programma krijgen jongeren meer
          verantwoordelijkheden. In deze periode wordt er gekeken naar
          opleidingen, baantjes, stages, vrijwilligerswerk of een andere vorm
          van verbinding met de maatschappij.
          <br />
          <br />
          De medewerkers en bewoners op Het Dagelijks Bestaan komen uit veel
          verschillende stromingen. Sommigen zullen de sfeer van de Vrije School
          herkennen. In de manier van werken bij Het Dagelijks Bestaan zijn we
          geïnspireerd door de Camphill Community, de antroposofie, het
          humanisme, de presentietheorie en door de noodzaak die we voelen om
          onszelf te leren kennen om zo tot verdere persoonlijke ontwikkeling te
          komen. We wonen, werken en leven samen met oog voor meer dan onszelf:
          voor mens, dier, milieu, omgeving.
        </div>
      </div>
      <div class="column">
        <carrousel :page="'overons_visie'" />
      </div>
    </div>
  </div>
</template>

<script>
import quote from "@/views/Components/Quote.vue";
export default {
  components: {
    quote
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
