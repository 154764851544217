export const setIndex = function (state, payload) {
  for (let product of payload) {
    product.slug =
      product.id +
      "-" +
      product.naam
        .trim()
        .toLowerCase()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "");
    for (let dag of product.besteldagen) {
      for (let dag_product of dag.products) {
        dag_product.gekozen_hoeveelheid = 0;
      }
    }
  }
  state.index = payload;
};

export const setNewItem = function (state, payload) {
  state.index.push(payload);
};

export const updateItem = function (state, payload) {
  const array = state.index;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};

export const deleteItem = function (state, payload) {
  const array = state.index;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};
