<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Klachtenprocedure</div>
          Stichting Het Dagelijks Bestaan streeft ernaar de begeleiding zo goed
          mogelijk te laten verlopen. Toch kan het voorkomen dat je niet
          tevreden bent over de gang van zaken. Een klacht indien kan op twee
          manieren, een interne route met (een van de) medewerkers van HDB of
          een externe route.<br />
          <br />
          <div class="bold">Klachtenregistratie</div>
          Alle klachten worden geregistreerd en worden per kwartaal besproken
          tijdens de evaluatie. Op die manier gaan we na of alle klachten tijdig
          worden afgehandeld, kijken welke onderwerpen onze aandacht vragen en
          waar we onze dienstverlening kunnen verbeteren.
          <br />
          <br />
          <div class="bold">Interne procedure</div>
          De samenwerking verloopt niet goed en je hebt een klacht. In eerste
          instantie lossen wij klachten graag samen op. Je kunt de klacht
          bespreken met een van de groepsleiders of met je coach. Wanneer jullie
          er samen niet uit komen, kun je de zorg coördinator betrekken. Het is
          ook mogelijk om rechtstreeks contact te maken met onze bestuurder en
          daar je klacht mee te bespreken. Wanneer je een klacht indient, is het
          belangrijk dit duidelijk te vermelden. Wij maken een verslag van het
          gesprek, zodat de afspraken die we maken duidelijk op papier staan.
          <br />
          <br />
          <div class="bold">Externe procedure voor deelnemers onder de 18</div>
          Wanneer het gesprek met de medewerkers van HDB geen oplossing heeft
          geboden, of wanneer het niet prettig voelt om de klacht met een van de
          medewerkers te bespreken, kun je ook contact maken met een extern
          vertrouwenspersoon:
          <br />
          <br />
          Jeugdstem/Adviespunt Zorgbelang is de organisatie van
          vertrouwenspersonen in de jeugdzorg.<br />
          Iedereen die te maken heeft met jeugdzorg en daar vragen over heeft,
          kan bij Jeugdstem/Adviespunt Zorgbelang terecht. Voor informatie, advies of ondersteuning.
          Kinderen, jongeren en volwassenen kunnen met vragen of klachten naar
          een vertrouwenspersoon. Een vertrouwenspersoon kan meegaan naar
          gesprekken met de gemeente of een hulpverlener. Ook helpt de
          vertrouwenspersoon met het indienen van een klacht bij een
          klachtencommissie of bij het bezwaar maken bij de gemeente.<br />
          <br />
          Ondersteuning door de vertrouwenspersoon van Jeugdstem / Adviespunt
          Zorgbelang is gratis. Jeugdstem is een zelfstandige stichting, de
          vertrouwenspersonen zijn niet in dienst van Het Dagelijks Bestaan.
          <br /><br />
          <div class="bold">
            Een Jeugdstem of Adviespunt Zorgbelang vertrouwenspersoon:
          </div>
          <div class="content">
            <ul>
              <li>
                geeft informatie en advies aan jongeren, ouders en verzorgers en
                legt uit hoe de jeugdzorg werkt;
              </li>
              <li>ondersteunt cliënten bij klachten over de jeugdzorg;</li>
              <li>
                geeft informatie over rechten en rechtspositie in de jeugdzorg;
              </li>
              <li>
                draagt bij aan verbetering van de kwaliteit van de jeugdzorg;
              </li>
            </ul>
          </div>

          <div class="bold">Zo bereik je Jeugdstem:</div>
          <div class="content">
            <ul>
              <li>Bel gratis naar 088 – 555 1000;</li>
              <li>
                Chat met een vertrouwenspersoon via www.jeugdstem.nl De chat is
                open van maandag t/m donderdag van 16.00-20.00 uur en op vrijdag
                van 15.00-17.00 uur.;
              </li>
              <li>
                Via Jeugdstem kan u zich aanmelden voor ondersteuning vanuit
                Adviespunt Zorgbelang;
              </li>
            </ul>
          </div>
          <img src="@/assets/logo/jeugdstem-logo.png" width="50%" />
          <img src="@/assets/logo/advies-punt-logo.png" width="50%" />

          <!-- wmo -->
          <hr />
          <div class="bold">Externe procedure voor deelnemers 18+</div>
          Heb je een vraag op het gebied van zorg, onderwijs, welzijn, wonen,
          werk of inkomen? Wil je graag dat iemand vanuit jouw belang kijkt
          welke mogelijkheden er zijn in jouw situatie of heb je een klacht?
          Voor deze vragen kun je terecht bij MEE Samen. Gratis, zonder
          verwijzing en onafhankelijk. De cliëntondersteuners van MEE weten veel
          over leven met beperkingen. (Licht) verstandelijke, zintuiglijke of
          lichamelijke beperkingen. Maar ook chronische ziekten, niet-aangeboren
          hersenletsel of bijvoorbeeld autisme. Voor mee informatie kijk op:
          <a href="https://www.meesamen.nl/mee-in-jouw-gemeente/zutphen">
            https://www.meesamen.nl/mee-in-jouw-gemeente/zutphen</a
          >
          <br /><br />

          <div class="bold">Zo bereik je stichting MEE:</div>
          <div class="content">
            <ul>
              <li>Bel gratis naar 088 – 633 0633;</li>
              <li>
                of mail naar
                <a href="mailto:info@meesamen.nl">info@meesamen.nl</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="column">
        <carrousel :page="'overons_klachtenprocedure'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
