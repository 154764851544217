<template>
  <div v-if="deleteCustomer">
    <div class="modal is-active">
      <div class="modal-background" @click="closeModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <span class="modal-card-title">Klant verwijderen</span>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal()"
          ></button>
        </header>
        <section class="modal-card-body">
          <span class="content" style="color: black"> </span>
          Weet je zeker dat je deze klant uit de bestelling wil verwijderen?
          <br />
          <br />
          <formCheckbox
            :labelHidden="true"
            title="Klant ook verwijderen als vaste klant"
            :model="deleteCustomer"
            modelKey="vaste_klant_verwijderen"
          />
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-rounded is-danger"
            @click="deleteCustomerEvent()"
          >
            Verwijderen
          </button>
          <button class="button is-rounded" @click="closeModal()">
            Annuleren
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    deleteCustomer: {
      required: true,
    },
  },
  data() {
    return {
      closedmessage:
        "Helaas is het op dit moment niet mogelijk om nieuwe bestellingen te plaatsen",
    };
  },
  computed: {
    closedModalActive() {
      const open = this.findSetting("website_open");
      if (open && open == 1) {
        return false;
      } else {
        return true;
      }
    },
    closed_message() {
      if (
        this.settings &&
        this.settings.variables &&
        this.settings.variables.length
      ) {
        const closedmessage = this.settings.variables.find(
          (a) => a.key === "website_closed_message"
        );
        if (closedmessage) {
          return closedmessage.value;
        } else {
          return this.closedmessage;
        }
      } else {
        return this.closedmessage;
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    deleteCustomerEvent() {
      this.$emit("deleteCustomer");
      this.closeModal();
    },
  },
  mounted() {},
};
</script>
