var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.product && _vm.product.id)?_c('div',{staticClass:"container is-fluid"},[_c('br'),_c('div',{staticClass:"button is-outlined is-primary is-fullwidth",on:{"click":function($event){return _vm.$router.push({ name: 'dagelijkse-soep-landing' })}}},[_c('b-icon',{attrs:{"icon":"arrow-left"}}),_vm._v("  Terugkeren ")],1),_c('br'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-5"},[(_vm.product.product && _vm.product.product.pictures.length > 1)?_c('b-carousel',{attrs:{"indicator-custom":"","autoplay":false,"indicator-inside":false},scopedSlots:_vm._u([{key:"indicators",fn:function(props){return [_c('b-image',{staticClass:"al image",attrs:{"ratio":"1by1","src":_vm.env.VUE_APP_API_URL +
                '/web/picture/' +
                _vm.product.product.pictures[props.i].uuid,"title":props.i}})]}}],null,false,3854024255),model:{value:(_vm.shownPicture),callback:function ($$v) {_vm.shownPicture=$$v},expression:"shownPicture"}},_vm._l((_vm.product.product.pictures),function(item,i){return _c('b-carousel-item',{key:i,staticClass:"has-text-centered"},[_c('b-image',{staticClass:"image",attrs:{"ratio":"1by1","alt":"Product Image","src":_vm.env.VUE_APP_API_URL +
                '/web/picture/' +
                _vm.product.product.pictures[i].uuid}})],1)}),1):_c('b-image',{staticClass:"image",attrs:{"ratio":"1by1","alt":"Product Image","src":_vm.env.VUE_APP_API_URL +
            '/web/picture/' +
            _vm.product.product.pictures[0].uuid}})],1),_c('div',{staticClass:"column"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.naam))]),_c('hr'),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.omschrijving)}}),_c('hr'),(_vm.product.korting > 0)?_c('span',[_c('div',{staticClass:"strikethrough"},[_vm._v(_vm._s(_vm.euro(_vm.product.prijs)))]),_c('div',[_vm._v(_vm._s(_vm.euro(_vm.product.prijs - _vm.product.korting)))])]):_c('span',[_vm._v(" "+_vm._s(_vm.euro(_vm.product.prijs))+" ")]),_c('hr'),_c('div',{staticClass:"title is-5"},[_vm._v("Beschikbare besteldagen:")]),_c('span',{staticClass:"content"},[_c('ul',_vm._l((_vm.product.besteldagen),function(dag){return _c('li',{key:dag.id},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.formatDatabaseDateHuman(dag.bestellingsdag))+" tussen "+_vm._s(_vm.formatTimePickerEditDateFromDataBase( _vm.findVariable("soep_bezorgtijden_begin") ))+" en "+_vm._s(_vm.formatTimePickerEditDateFromDataBase( _vm.findVariable("soep_bezorgtijden_eind") ))+" ")]),_c('div',{staticClass:"column"},[(!dag.inschrijving_geopend)?_c('span',[_vm._v(" "+_vm._s(_vm.getClosedOrderDayMessage(dag))+" ")]):(
                      dag.totale_producten - dag.bestelde_producten < 1
                    )?_c('span',[_vm._v(" Uitverkocht ")]):_c('b-field',{attrs:{"message":_vm.calculateAvailable(dag) + ' beschikbaar.'}},[_c('b-numberinput',{attrs:{"editable":false,"controls-rounded":"","min":0,"size":"is-small","controls-position":"compact","max":_vm.calculateMaxToOrder(dag)},model:{value:(
                        dag.products[_vm.getProductDayKey(dag)]
                          .gekozen_hoeveelheid
                      ),callback:function ($$v) {_vm.$set(dag.products[_vm.getProductDayKey(dag)]
                          , "gekozen_hoeveelheid", $$v)},expression:"\n                        dag.products[getProductDayKey(dag)]\n                          .gekozen_hoeveelheid\n                      "}})],1)],1)])])}),0)]),_c('hr'),_c('p',[_c('b-button',{attrs:{"disabled":_vm.checkButtonDisabled(),"type":"is-fullwidth is-primary is-outlined","icon-left":"shopping-cart"},on:{"click":function($event){return _vm.$router.push({ name: 'dagelijkse-soep-checkout' })}}},[_vm._v(" Bestellen ")]),_c('br'),_c('b-button',{attrs:{"type":"is-fullwidth is-outlined","icon-left":"arrow-left"},on:{"click":function($event){return _vm.$router.push({ name: 'dagelijkse-soep-landing' })}}},[_vm._v(" Andere soepen bekijken ")])],1)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }