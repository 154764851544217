<template>
  <div>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <closedModal />
  </div>
</template>

<script>
import closedModal from "./SoepGeslotenModal.vue";

export default {
  components: {
    closedModal,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch("checkout/index");
  },
};
</script>
