var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((Math.ceil(_vm.products.length / _vm.maxItemsPerRow)),function(outerLoop){return _c('div',{key:outerLoop,staticClass:"columns"},_vm._l((_vm.calculatedMax(outerLoop)),function(item){return _c('div',{key:item,staticClass:"column is-3"},[_c('div',{staticClass:"card pointer",on:{"click":function($event){return _vm.productClicked(
            _vm.products[item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1]
              .id
          )}}},[_c('div',{staticClass:"card-image",on:{"mouseover":function($event){return _vm.mouseoverImage(
              _vm.products[item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1]
            )},"mouseleave":_vm.mouseleaveImage}},[_c('figure',{staticClass:"image is-1by1"},[(
                _vm.products[
                  item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                ].pictures[0]
              )?_c('div',[(
                  _vm.hoveredProduct ===
                  _vm.products[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ].id
                )?_c('img',{class:{
                  greyImage:
                    _vm.products[
                      item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                    ].status === 'sold',
                  hiddenImage:
                    _vm.products[
                      item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                    ].visible === false,
                },attrs:{"src":_vm.calcPictureLength(
                    _vm.products[
                      item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                    ]
                  )
                    ? _vm.env.VUE_APP_API_URL +
                      '/web/picture/' +
                      _vm.products[
                        item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                      ].pictures[1].uuid
                    : _vm.env.VUE_APP_API_URL +
                      '/web/picture/' +
                      _vm.products[
                        item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                      ].pictures[0].uuid,"alt":"Product Image"}}):_c('img',{class:{
                  greyImage:
                    _vm.products[
                      item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                    ].status === 'sold',
                  hiddenImage:
                    _vm.products[
                      item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                    ].visible === false,
                },attrs:{"src":_vm.env.VUE_APP_API_URL +
                  '/web/picture/' +
                  _vm.products[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ].pictures[0].uuid,"alt":"Product Image"}})]):_vm._e()])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media"}),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content has-text-centered"},[_vm._v(" "+_vm._s(_vm.hoofdletter( _vm.products[ item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1 ].naam ))+" "),_c('br'),(
                    _vm.products[
                      item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                    ].status === 'sold'
                  )?_c('p',{staticStyle:{"color":"red"}},[_vm._v(" Sold ")]):(
                    _vm.products[
                      item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                    ].status === 'preview'
                  )?_c('p',[_vm._v(" Preview ")]):_c('p',[_vm._v(" "+_vm._s(_vm.euro( _vm.products[ item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1 ].prijs - _vm.products[ item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1 ].korting ))+" ")])])])])])])])}),0)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }