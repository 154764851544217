<template>
  <div>
    <div class="title is-5">Fotos</div>
    <ul>
      <li v-for="picture of pictures" :key="picture.id">
        <div class="columns">
          <div class="column">
            {{ picture.slug }}
          </div>
          <div class="column">
            <img
              style="width:100px;"
              :src="env.VUE_APP_API_URL + '/web/site-pictures/' + picture.uuid"
            />
          </div>
          <div class="column">
            <formSelect
              title=" "
              :selectOptions="pictureCategories"
              :model="picture"
              modelKey="category"
              v-on:change="selectChanged(picture)"
            />
          </div>
          <div class="column is-1">
            <a @click="deletePicture(picture)">delete</a>
          </div>
        </div>
        <br />
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";
import formSelect from "@/components/form/formSelect.vue";

export default {
  components: {
    formSelect
  },
  props: [],
  data() {
    return {
      droppedFiles: [],
      previewImages: [],
      env: process.env
    };
  },
  computed: {
    pictures() {
      return this.$store.getters["pictures"];
    },
    pictureCategories() {
      const categories = this.$store.getters["settings"];

      if (categories.pictures) {
        return categories.pictures;
      } else {
        return [];
      }
    }
  },
  methods: {
    deletePicture(picture) {
      this.$store.dispatch("deletePicture", picture);
    },
    uploadPictures() {
      this.droppedFiles.forEach(
        function(file, index) {
          let formdata = new FormData();
          formdata.append("image", file, file.name);
          this.$store.dispatch("uploadPicture", formdata).then(() => {
            this.droppedFiles.splice(index, 1);
            this.previewImages.splice(index, 1);
          });
        }.bind(this)
      );
    },
    filesChanged() {
      this.previewImages = [];
      this.droppedFiles.forEach(
        function(file, index) {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            () => {
              let filepreview = {
                name: file.name,
                url: reader.result
              };
              this.previewImages.push(filepreview);
            },
            false
          );
          reader.readAsDataURL(file);
        }.bind(this)
      );
    },
    selectChanged(picture) {
      this.$store.dispatch("savePictureCategory", picture);
    },
    deleteDroppedFile(index) {
      const array = this.droppedFiles;
      array.splice(index, 1);

      this.previewImages.splice(index, 1);
    }
  },

  mounted() {
    this.$store.dispatch("getAllPictures");
    this.$store.dispatch("getSettings");
  }
};
</script>
