import { render, staticRenderFns } from "./BestellingenBase.vue?vue&type=template&id=75372969&scoped=true&"
import script from "./BestellingenBase.vue?vue&type=script&lang=js&"
export * from "./BestellingenBase.vue?vue&type=script&lang=js&"
import style0 from "./BestellingenBase.vue?vue&type=style&index=0&id=75372969&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75372969",
  null
  
)

export default component.exports