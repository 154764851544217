export const setPostalCodes = function (state, payload) {
  state.postal_codes = payload;
};

export const setNewPostalCode = function (state, payload) {
  state.postal_codes.push(payload);
};

export const deletePostalCode = function (state, payload) {
  const array = state.postal_codes;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};

export const updatePostalCode = function (state, payload) {
  const array = state.postal_codes;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};
