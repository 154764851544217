<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Gebruiker aanpassen</div>
    <form @submit.prevent="gebruikerAanpassen()">
      <br />
      <br />

      <formInput
        class="customInput"
        :model="form"
        title="naam"
        modelKey="name"
        label="on-border"
      />

      <formInput
        class="customInput"
        :model="form"
        modelKey="email"
        label="on-border"
      />

      <formInput
        class="customInput"
        :model="form"
        modelKey="telefoonnummer"
        label="on-border"
      />

      <formSelect
        v-if="adminSettings.rollen"
        label="on-border"
        class="customInput"
        :model="form"
        placeholder="Kies rol"
        modelKey="rol"
        :selectOptions="adminSettings.rollen"
      />

      <b-button
        native-type="submit"
        :loading="ajaxloading.create_user"
        type="is-primary is-rounded"
        >Gebruiker aanpassen</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="cancelUpdate()">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected: null,
      form: {},
    };
  },
  computed: {
    gebruikers() {
      return this.$store.getters["adminUsers/users"];
    },
    user() {
      return this.gebruikers.find((a) => a.id == this.$route.params.user);
    },
  },
  methods: {
    cancelUpdate() {
      this.$router.go(-1);
      this.$store.dispatch("adminUsers/refreshUser", this.user);
    },
    gebruikerAanpassen() {
      this.$store
        .dispatch("adminUsers/gebruikerAanpassen", this.form)
        .then(() => {
          this.$buefy.toast.open("Gebruiker aangepast");
          this.$router.push({ name: "admin-gebruikers" });
        });
    },
  },
  mounted() {
    if (this.user) {
      this.form = this.user;
    } else {
      this.$router.go(-1);
    }
  },
};
</script>
